import { gql } from "@apollo/client";
import { client } from "..";

const GET_SUBJECT_BY_ID = gql`
  query GetSubject($id: Int!) {
    subject(subjectId: $id) {
      icon
      id
      name
      description
    }
  }
`;
export async function GetSubject(subjectID: Number): Promise<ISubjectInput> {
    try {
        const response = await client.query({
            query: GET_SUBJECT_BY_ID,
            variables: { id:subjectID },
        });
        
        console.log("get Subject info successfully");
        console.log("Response:", response);
        return response.data.subject as ISubjectInput;
    } catch (error) {
        console.error('Error occurred while creating subject:', error);
        throw error;
    }
}


export async function createSubject(createSubjectInput: ICreateSubjectInput): Promise<ISubjectInput> {
    try {
        const mutation = gql`
        mutation createSubject($name: String!, $icon: String!, $description: String!) {
            createSubject(createSubjectInput: {name: $name, icon: $icon, description: $description}) {
                description
                icon
                id
                name
            }
        }
      `;

        const response = await client.mutate({
            mutation,
            variables: {
                name: createSubjectInput.name,
                icon: createSubjectInput.icon,
                description: createSubjectInput.description,
            },
        });

        console.log("Subject created successfully");
        console.log("Response:", response);
        return response.data.createSubject as ISubjectInput;
    } catch (error) {
        console.error('Error occurred while creating subject:', error);
        throw error;
    }
}

export async function updateSubject(updateSubjectInput: ISubjectInput): Promise<void> {
    try {
        const mutation = gql`
        mutation UpdateClass($updateSubjectInput: UpdateSubjectInput!) {
            updateSubject(updateSubjectInput: $updateSubjectInput) {
                description
                icon
                id
                name
            }
        }
      `;

        const response = await client.mutate({
            mutation,
            variables: { updateSubjectInput },
        });

        console.log("Subject updated successfully");
        console.log("Response:", response);
    } catch (error) {
        console.error('Error occurred while updating subject:', error);
        throw error;
    }
}

export async function deleteSubject(deleteSubjectID: number): Promise<void> {
    try {
        const mutation = gql`
          mutation DeleteSubject($subjectId: Int!) {
            deleteSubject(subjectId: $subjectId) {
                description
                icon
                id
                name
              }
          }
        `;

        const response = await client.mutate({
            mutation,
            variables: { subjectId: deleteSubjectID },
        });
        console.log("Subject deleted successfully");
        console.log("Response:", response);
    } catch (error) {
        console.error("Error occurred while deleting subject:", error);
        throw error;
    }
}

export async function assignTeachersToSubject(inputData: IAssignTeachersToSubjectInput): Promise<void> {
    try {
        const mutation = gql`
            mutation assignTeachersToSubject($subjectId: Int!, $teacherCodes: [String!]!) {
            assignTeachersToSubject(subjectId: $subjectId, teacherCodes: $teacherCodes) {
              codes
            }
          }
        `;
        const response = await client.mutate({
            mutation,
            variables: {
                subjectId: inputData.subjectId,
                teacherCodes: inputData.teacherCodes,
            },
        });
        console.log("Teacher assign to subject successfully");
        console.log("Response:", response);
    } catch (error) {
        console.error("Error occurred while assigning teacher to subject:", error);
        throw error;
    }
}