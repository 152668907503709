import React, { useState } from 'react';
import { Modal, Box, Button, Theme, Paper, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { t } from '../../features/i18n';
const color = "red"
const useStyles = makeStyles<Theme>((theme) => ({
    paper: {
        borderTop: `8px solid red`,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: "40px",
    },
    tableRow: {
        "&:not(:last-child)": {
            borderBottom: "none",
        },
    },
}));

interface IApproveModal {
    message: string;
    open: boolean;
    color?: string;
    Approve: () => void;
    Denied: () => void;
}

const ApproveModal: React.FC<IApproveModal> = ({ message, Approve, Denied, open}) => {

    const classes = useStyles();

    return (
        <Modal open={open} onClose={Denied}>
            <Paper className={classes.paper}>
                <Box>
                    <Typography
                        sx={{
                            fontSize: "24px",
                        }}
                    >
                        {message}
                    </Typography>
                    <Button
                        variant="outlined"
                        onClick={Denied}
                        sx={{
                            width: 140,
                            height: 40,
                            color: color,
                            textTransform: "none",
                            fontWeight: "bold",
                            borderColor: color,
                        }}
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={Approve}
                        sx={{
                            width: 140,
                            height: 40,
                            bgcolor: color,
                            "&:hover": {
                                backgroundColor: color,
                            },
                            fontWeight: "bold",
                            position: "absolute",
                            right: "40px",
                        }}
                    >
                        {t("confirm")}
                    </Button>
                </Box>
            </Paper>
        </Modal>
    );
};

export default ApproveModal;
