import React from "react";
import { SvgIcon } from '@mui/material';
interface IconAttributes {
    className?: string,
}

export const JPGIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.63 23.0952C22.63 23.6032 22.214 24.0172 21.703 24.0172H7.86399C7.35299 24.0172 6.93799 23.6032 6.93799 23.0942V18.9402H14.785C15.548 18.9402 16.168 18.3192 16.168 17.5562V10.6312C16.168 9.86819 15.548 9.24719 14.785 9.24719H6.93799V2.78819C6.93799 2.27819 7.35299 1.86319 7.86399 1.86319H16.168V7.86319C16.168 8.11819 16.375 8.32419 16.63 8.32419H22.63V23.0952ZM2.32099 18.0172C2.06699 18.0172 1.86099 17.8102 1.86099 17.5562V10.6312C1.86099 10.3772 2.06699 10.1702 2.32099 10.1702H14.785C15.039 10.1702 15.245 10.3772 15.245 10.6312V17.5562C15.245 17.8102 15.039 18.0172 14.785 18.0172H2.32099ZM17.092 2.18619L22.111 7.40119H17.092V2.18619ZM23.424 7.43419L17.31 1.08119C17.223 0.991186 17.103 0.940186 16.977 0.940186H7.86399C6.84399 0.940186 6.01499 1.76919 6.01499 2.78819V9.24719H2.32099C1.55799 9.24719 0.937988 9.86819 0.937988 10.6312V17.5562C0.937988 18.3192 1.55799 18.9402 2.32099 18.9402H6.01499V23.0942C6.01499 24.1122 6.84399 24.9402 7.86399 24.9402H21.703C22.723 24.9402 23.553 24.1122 23.553 23.0952V7.75519C23.553 7.63519 23.507 7.52019 23.424 7.43419Z"/>
            <path d="M11.8101 14.0449V14.7129H12.7271V15.2159C12.6061 15.3099 12.4611 15.3889 12.2931 15.4549C12.1241 15.5209 11.9561 15.5539 11.7881 15.5539C11.4491 15.5539 11.1751 15.4359 10.9661 15.2009C10.7571 14.9659 10.6531 14.6129 10.6531 14.1419C10.6531 13.7059 10.7561 13.3769 10.9621 13.1549C11.1691 12.9339 11.4471 12.8229 11.7971 12.8229C12.0271 12.8229 12.2201 12.8789 12.3741 12.9919C12.5281 13.1039 12.6331 13.2579 12.6891 13.4529L13.4841 13.3039C13.4051 12.9399 13.2271 12.6539 12.9501 12.4479C12.6731 12.2419 12.2891 12.1379 11.7971 12.1379C11.4181 12.1379 11.1021 12.2039 10.8471 12.3359C10.5141 12.5069 10.2601 12.7529 10.0871 13.0739C9.91412 13.3949 9.82812 13.7619 9.82812 14.1749C9.82812 14.5549 9.90512 14.9069 10.0601 15.2309C10.2151 15.5539 10.4471 15.8029 10.7551 15.9769C11.0641 16.1509 11.4251 16.2379 11.8401 16.2379C12.1661 16.2379 12.4881 16.1759 12.8071 16.0529C13.1251 15.9289 13.3681 15.7869 13.5351 15.6239V14.0449H11.8101Z"/>
            <path d="M8.39007 13.7451C8.33007 13.8331 8.24607 13.8981 8.14007 13.9401C8.03307 13.9811 7.82207 14.0021 7.50707 14.0021H7.06907V12.8771H7.45607C7.74407 12.8771 7.93607 12.8861 8.03207 12.9041C8.16107 12.9271 8.26907 12.9861 8.35307 13.0801C8.43807 13.1731 8.48107 13.2921 8.48107 13.4371C8.48107 13.5541 8.45007 13.6571 8.39007 13.7451ZM8.50507 12.2661C8.35707 12.2261 8.04007 12.2061 7.55307 12.2061H6.26807V16.1711H7.06907V14.6751H7.59107C7.95307 14.6751 8.23007 14.6561 8.42107 14.6181C8.56207 14.5881 8.70007 14.5251 8.83607 14.4301C8.97207 14.3361 9.08507 14.2061 9.17307 14.0401C9.26107 13.8741 9.30507 13.6691 9.30507 13.4261C9.30507 13.1101 9.22907 12.8531 9.07607 12.6541C8.92207 12.4551 8.73207 12.3251 8.50507 12.2661Z"/>
            <path d="M4.61986 14.7671C4.61986 15.0881 4.58286 15.2991 4.50886 15.4011C4.43486 15.5031 4.30886 15.5541 4.13086 15.5541C3.95386 15.5541 3.82186 15.4831 3.73586 15.3401C3.67786 15.2471 3.64486 15.0861 3.63586 14.8591L2.88086 14.9451C2.88286 15.3691 2.98886 15.6911 3.19986 15.9101C3.41086 16.1291 3.71186 16.2381 4.10386 16.2381C4.43686 16.2381 4.70586 16.1681 4.90886 16.0291C5.11286 15.8891 5.25386 15.7041 5.33086 15.4731C5.38886 15.2961 5.41786 15.0441 5.41786 14.7161V12.2061H4.61986V14.7671Z"/>
        </SvgIcon>
    );
}