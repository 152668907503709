import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  Alert,
  TextField,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { t } from "../../features/i18n";
import { z } from 'zod';
import MessageComponent from "../MessageComponent";
import { GetAllClasses } from "../../apiRequest/class";
import { updateUser } from "../../apiRequest/user";

let color: string = "#f18733";

const schema = z.object({
  userName: z.string().min(1),
  userEmail: z.string().min(1),
  class: z.string().min(1),
});
type FormData = z.infer<typeof schema>;

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));

interface IUpdateStudentUserModal {
  open: boolean;
  onClose: () => void;
  resetTable: () => void;
  user?: IUpdateUserInput;
};

interface IClassTaget {
  TargetClassId?: string;
  className?: string;
};

const UpdateStudentUserModal: React.FC<IUpdateStudentUserModal> = ({ open, onClose, resetTable, user }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [classesList, setClassesList] = useState<IUpdateClassResponse[]>([]);
  const classes = useStyles();
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [classId, setClassId] = useState<number | undefined>(undefined);
  const [classObject, setClassObject] = useState<IClassTaget>();

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleClassIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClassId(parseInt(event.target.value));
  };

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
    }
    if (open && user) {
      setCode(user.code)
      setName(user.name)
      setEmail(user.email)
      setClassId(user.class_id)
      setClassObject({
        TargetClassId: user.class_id?.toString(),
        className: user.class_name,
      })
    }
  }, [open]);

  useEffect(() => {
    async function loadData(): Promise<void> {
      setClassesList(await GetAllClasses())
    }
    loadData();
  }, []);

  const handleCancel = () => {
    onClose();
  };

  const onSubmit = async (data: FormData) => {
    console.log(data);
    try {
      if (data) {
        const objectUpdate: IUpdateUserInput = {
          code: code,
          name: name,
          email: email,
          class_id:classId,
        };
        setLoading(true)
        await updateUser(objectUpdate)
        setTimeout(() => {
          setSuccessAlertOpen(true);
          setTimeout(() => {
            resetTable();
            onClose();
          }, 2000);
        }, 2000);
      } else {
        throw "chyba pri updatovani";
      }
    } catch (error) {
      console.error('Chyba při odesílání:', error);
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {successAlertOpen ? (
          <MessageComponent
            errorChange={errorAlertOpen}
            errorMessage={t("update_user_error")}
            successMessage={t("update_user_success")}
          />
        ) : (
          <Box>
            <Box
              sx={{
                maxHeight: "50px",
                alignText: "left",
                color: color,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "24px",
                    }}
                  >
                    {t("update_student")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField sx={{ mb: 2 }}
                {...register('userName')}
                required
                label={t("user_name")}
                value={name}
                onChange={handleNameChange}
                error={!!errors.userName}
                helperText={errors.userName?.message}
                fullWidth />

              <TextField sx={{ mb: 2 }}
                {...register('userEmail')}
                required
                label={t("email")}
                value={email}
                onChange={handleEmailChange}
                error={!!errors.userEmail}
                helperText={errors.userEmail?.message}
                fullWidth />

              <TextField
                sx={{ mb: 2 }}
                {...register('class')}
                select
                label={t("class")}
                value={classObject?.TargetClassId}
                onChange={handleClassIdChange}
                error={!!errors.class}
                helperText={errors.class?.message}
                fullWidth
              >
                <MenuItem>{classObject?.className}</MenuItem>
                {classesList.map((item =>
                  <MenuItem value={item.id}>{item.class_name}</MenuItem>
                ))}
              </TextField>

              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{
                  width: 140,
                  height: 40,
                  color: color,
                  textTransform: "none",
                  fontWeight: "bold",
                  borderColor: color,
                }}
              >
                {t("cancel")}
              </Button>

              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{
                  width: 140,
                  height: 40,
                  bgcolor: color,
                  "&:hover": {
                    backgroundColor: color,
                  },
                  fontWeight: "bold",
                  position: "absolute",
                  right: "40px",
                }}
              >
                {loading ? t("processing") : t("confirm")}

              </Button>
            </form>
          </Box>
        )}

      </Paper>
    </Modal>
  );
};

export default UpdateStudentUserModal;
