import React from "react";

interface IconAttributes {
    className?: string,
}

export const MathematicsIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44.1739 49.655C45.7659 49.655 47.0559 50.945 47.0559 52.537C47.0559 54.129 45.7659 55.419 44.1739 55.419C42.5819 55.419 41.2919 54.129 41.2919 52.537C41.2919 50.945 42.5819 49.655 44.1739 49.655Z" fill="#FFCDBE"/>
            <path d="M44.1739 30.064C45.7659 30.064 47.0559 31.354 47.0559 32.946C47.0559 34.538 45.7659 35.828 44.1739 35.828C42.5819 35.828 41.2919 34.538 41.2919 32.946C41.2919 31.354 42.5819 30.064 44.1739 30.064Z" fill="#FFCDBE"/>
            <path d="M34.1091 10.1421H54.1791C55.0361 10.1421 55.7301 10.8371 55.7301 11.6931V15.4731C55.7301 16.3301 55.0361 17.0251 54.1791 17.0251H34.1091C33.2521 17.0251 32.5581 16.3301 32.5581 15.4731V11.6931C32.5581 10.8371 33.2521 10.1421 34.1091 10.1421Z" fill="#76C1F4"/>
            <path d="M34.1091 39.345H54.1791C55.0361 39.345 55.7301 40.04 55.7301 40.897V44.676C55.7301 45.533 55.0361 46.228 54.1791 46.228H34.1091C33.2521 46.228 32.5581 45.533 32.5581 44.676V40.897C32.5581 40.04 33.2521 39.345 34.1091 39.345Z" fill="#FFCDBE"/>
            <path d="M23.4179 10.1421H18.3759C17.5189 10.1421 16.8239 9.44707 16.8239 8.59007V3.54807C16.8239 2.69107 16.1299 1.99707 15.2729 1.99707H11.4929C10.6369 1.99707 9.94188 2.69107 9.94188 3.54807V8.59007C9.94188 9.44707 9.24688 10.1421 8.38988 10.1421H3.34788C2.49088 10.1421 1.79688 10.8371 1.79688 11.6931V15.4731C1.79688 16.3301 2.49088 17.0241 3.34788 17.0241H8.38988C9.24688 17.0241 9.94188 17.7191 9.94188 18.5761V23.6181C9.94188 24.4751 10.6369 25.1701 11.4929 25.1701H15.2729C16.1299 25.1701 16.8239 24.4751 16.8239 23.6181V18.5761C16.8239 17.7191 17.5189 17.0241 18.3759 17.0241H23.4179C24.2749 17.0241 24.9689 16.3301 24.9689 15.4731V11.6931C24.9689 10.8371 24.2749 10.1421 23.4179 10.1421Z" fill="#FF5F76"/>
            <path d="M22.9121 36.241L20.2391 33.568C19.6331 32.962 18.6511 32.962 18.0451 33.568L14.4801 37.134C13.8741 37.739 12.8921 37.739 12.2861 37.134L8.71809 33.568C8.11209 32.963 7.13009 32.963 6.52409 33.568L3.85209 36.241C3.24609 36.847 3.24609 37.829 3.85109 38.435H3.85209L7.41709 42.001C8.02309 42.606 8.02309 43.589 7.41709 44.194L3.85209 47.76C3.24609 48.366 3.24609 49.348 3.85109 49.954H3.85209L6.52409 52.627C7.13009 53.232 8.11209 53.232 8.71809 52.627L12.2861 49.061C12.8921 48.456 13.8741 48.456 14.4801 49.061L18.0461 52.627C18.6511 53.233 19.6341 53.233 20.2401 52.627L22.9131 49.954C23.5181 49.348 23.5191 48.366 22.9131 47.76L19.3471 44.194C18.7421 43.589 18.7421 42.606 19.3471 42.001L22.9131 38.435C23.5181 37.829 23.5181 36.847 22.9121 36.241Z" fill="#F7CA72"/>
            <path d="M44.1751 54.3851C43.1541 54.3841 42.3281 53.5561 42.3291 52.5351C42.3301 51.5161 43.1561 50.6901 44.1751 50.6891C45.1961 50.6901 46.0221 51.5191 46.0211 52.5391C46.0201 53.5581 45.1941 54.3841 44.1751 54.3851ZM44.1751 48.6211C42.0131 48.6231 40.2611 50.3751 40.2581 52.5371C40.4421 57.7201 47.9081 57.7191 48.0911 52.5371C48.0891 50.3751 46.3371 48.6231 44.1751 48.6211Z" fill="#3B4456"/>
            <path d="M42.3259 32.947C42.3269 31.926 43.1549 31.1 44.1759 31.101C45.1949 31.102 46.0209 31.928 46.0219 32.947C46.0209 33.968 45.1919 34.794 44.1719 34.793C43.1529 34.792 42.3269 33.966 42.3259 32.947ZM48.0899 32.947C48.0879 30.785 46.3359 29.034 44.1739 29.031C38.9899 29.215 38.9919 36.68 44.1739 36.863C46.3359 36.861 48.0879 35.109 48.0899 32.947Z" fill="#3B4456"/>
            <path d="M54.6939 15.4729C54.6939 15.7589 54.4619 15.9899 54.1769 15.9899H34.1109C33.8249 15.9899 33.5939 15.7589 33.5939 15.4729V11.6929C33.5939 11.4069 33.8249 11.1759 34.1109 11.1759H54.1769C54.4619 11.1759 54.6939 11.4069 54.6939 11.6929V15.4729ZM54.1769 9.10693H34.1109C32.6839 9.10893 31.5269 10.2659 31.5249 11.6929V15.4729C31.5269 16.8999 32.6839 18.0569 34.1109 18.0589H54.1769C55.6039 18.0569 56.7609 16.8999 56.7629 15.4729V11.6929C56.7609 10.2659 55.6039 9.10893 54.1769 9.10693Z" fill="#3B4456"/>
            <path d="M54.6939 44.676C54.6939 44.962 54.4619 45.193 54.1769 45.193H34.1109C33.8249 45.193 33.5939 44.962 33.5939 44.676V40.897C33.5939 40.611 33.8249 40.379 34.1109 40.379H54.1769C54.4619 40.379 54.6939 40.611 54.6939 40.897V44.676ZM54.1769 38.311H34.1109C32.6839 38.313 31.5269 39.469 31.5249 40.897V44.676C31.5269 46.103 32.6839 47.26 34.1109 47.262H54.1769C55.6039 47.26 56.7609 46.103 56.7629 44.676V40.897C56.7609 39.469 55.6039 38.313 54.1769 38.311Z" fill="#3B4456"/>
            <path d="M23.935 15.4729C23.935 15.7589 23.703 15.9899 23.418 15.9899H18.376C16.948 15.9919 15.791 17.1489 15.79 18.5759V23.6179C15.79 23.9039 15.558 24.1349 15.273 24.1349H11.493C11.207 24.1349 10.975 23.9039 10.975 23.6179V18.5759C10.974 17.1489 9.81696 15.9919 8.38996 15.9899H3.34796C3.06196 15.9899 2.83096 15.7589 2.83096 15.4729V11.6929C2.83096 11.4069 3.06196 11.1759 3.34796 11.1759H8.38996C9.81696 11.1739 10.974 10.0169 10.975 8.58989V3.54789C10.975 3.26289 11.207 3.03089 11.493 3.03089H15.273C15.558 3.03089 15.79 3.26289 15.79 3.54789V8.58989C15.791 10.0169 16.948 11.1739 18.376 11.1759H23.418C23.703 11.1759 23.935 11.4069 23.935 11.6929V15.4729ZM23.418 9.10689H18.376C18.09 9.10689 17.858 8.87589 17.858 8.58989V3.54789C17.857 2.12089 16.7 0.963891 15.273 0.962891H11.493C10.065 0.963891 8.90896 2.12089 8.90696 3.54789V8.58989C8.90696 8.87589 8.67496 9.10689 8.38996 9.10689H3.34796C1.92096 9.10889 0.763963 10.2659 0.761963 11.6929V15.4729C0.763963 16.8999 1.92096 18.0569 3.34796 18.0589H8.38996C8.67496 18.0589 8.90696 18.2899 8.90696 18.5759V23.6179C8.90896 25.0459 10.065 26.2019 11.493 26.2039H15.273C16.7 26.2019 17.857 25.0459 17.858 23.6179V18.5759C17.858 18.2899 18.09 18.0589 18.376 18.0589H23.418C24.845 18.0569 26.002 16.8999 26.004 15.4729V11.6929C26.002 10.2659 24.845 9.10889 23.418 9.10689Z" fill="#3B4456"/>
            <path d="M18.6161 44.9251L22.1811 48.4931C22.3831 48.6951 22.3831 49.0231 22.1811 49.2251L19.5091 51.8971C19.3071 52.0991 18.9791 52.1001 18.7771 51.8981L15.2121 48.3321C14.2011 47.3241 12.5661 47.3241 11.5551 48.3321L7.9901 51.8971C7.7881 52.0991 7.4611 52.0991 7.2591 51.8971L4.5861 49.2241C4.3841 49.0221 4.3841 48.6951 4.5861 48.4931L8.1511 44.9251C9.1601 43.9151 9.1601 42.2781 8.1511 41.2681L4.5861 37.7001C4.3841 37.4981 4.3841 37.1701 4.5861 36.9691L7.2581 34.2961C7.4601 34.0941 7.7871 34.0941 7.9901 34.2951L11.5551 37.8621C12.5661 38.8701 14.2011 38.8701 15.2121 37.8621L18.7771 34.2961C18.9791 34.0941 19.3061 34.0941 19.5081 34.2961L22.1811 36.9691C22.3831 37.1701 22.3831 37.4981 22.1811 37.7001L18.6151 41.2661C17.6101 42.2781 17.6111 43.9121 18.6161 44.9251ZM23.6441 39.1661C24.6521 38.1561 24.6521 36.5211 23.6441 35.5101L20.9711 32.8371C19.9611 31.8291 18.3251 31.8291 17.3141 32.8371L13.7501 36.4021C13.5481 36.6041 13.2201 36.6051 13.0181 36.4031L9.4521 32.8361C8.4421 31.8281 6.8061 31.8281 5.7961 32.8361L3.1231 35.5091C2.1201 36.5211 2.1201 38.1531 3.1231 39.1651L6.6881 42.7341C6.8901 42.9361 6.8901 43.2631 6.6881 43.4651L3.1231 47.0271C2.1201 48.0401 2.1201 49.6721 3.1231 50.6841L5.7961 53.3571C6.8061 54.3651 8.4421 54.3651 9.4521 53.3571L13.0171 49.7921C13.2191 49.5901 13.5461 49.5891 13.7491 49.7911L13.7501 49.7921L17.3141 53.3581C18.3251 54.3661 19.9611 54.3661 20.9711 53.3581L23.6441 50.6851C24.6521 49.6751 24.6521 48.0391 23.6441 47.0291L20.0791 43.4601C19.8771 43.2581 19.8771 42.9311 20.0791 42.7291L23.6441 39.1661Z" fill="#3B4456"/>
        </svg>
    );
}