import React from "react";

interface IconAttributes {
    className?: string,
}

export const Hygienics: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <svg width="52" height="64" viewBox="0 0 52 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M45.9799 59.7979C45.1949 59.0729 44.7109 58.0809 44.7109 57.0119V39.0039H25.9979L40.9149 63.0439H44.6579C45.6649 63.0439 46.5319 62.2699 46.5799 61.2629C46.6069 60.6839 46.3709 60.1589 45.9799 59.7979Z" fill="#FFBF52"/>
            <path d="M42.8399 61.2629C42.8669 60.6829 42.6309 60.1579 42.2399 59.7979C41.4549 59.0729 40.9709 58.0799 40.9709 57.0119V53.9999L26.0009 39.0029H7.28894V57.0119C7.28894 58.0799 6.80394 59.0729 6.01894 59.7979C5.62894 60.1589 5.39294 60.6839 5.41994 61.2629C5.46694 62.2699 6.33394 63.0439 7.34194 63.0439H40.9179C41.9259 63.0439 42.7929 62.2689 42.8399 61.2629Z" fill="#FFD75E"/>
            <path d="M33.48 50.2571L37.222 53.9991C37.222 55.0321 38.061 55.8711 39.094 55.8711C40.127 55.8711 40.965 55.0321 40.965 53.9991V50.2571H33.48Z" fill="#82828F"/>
            <path d="M33.48 40.2769V50.2569H37.222C37.222 51.2899 38.061 52.1279 39.094 52.1279C40.127 52.1279 40.965 51.2899 40.965 50.2569V40.2769H33.48Z" fill="#9595A3"/>
            <path d="M18.52 50.2571L14.778 53.9991C14.778 55.0321 13.939 55.8711 12.906 55.8711C11.873 55.8711 11.035 55.0321 11.035 53.9991V50.2571H18.52Z" fill="#82828F"/>
            <path d="M18.52 40.2769V50.2569H14.778C14.778 51.2899 13.939 52.1279 12.906 52.1279C11.873 52.1279 11.035 51.2899 11.035 50.2569V40.2769H18.52Z" fill="#9595A3"/>
            <path d="M10.07 6.98511C4.92695 11.4451 1.67395 18.0281 1.67395 25.3701V31.9101C1.67395 35.3141 3.54495 35.6521 3.54495 35.6521H12.816V6.98511H10.07Z" fill="#FFD75E"/>
            <path d="M41.9301 6.98511C47.0731 11.4451 50.3261 18.0281 50.3261 25.3701V31.9101C50.3261 35.3141 48.4551 35.6521 48.4551 35.6521H39.1841V6.98511H41.9301Z" fill="#FFBF52"/>
            <path d="M48.45 33.7809C47.417 33.7809 46.579 32.9429 46.579 31.9099L40.965 42.1479H44.708C47.808 42.1479 50.321 39.6349 50.321 36.5349V31.9099C50.321 32.9429 49.483 33.7809 48.45 33.7809Z" fill="#FFD75E"/>
            <path d="M46.5799 36.5349V26.8149C46.4549 23.6839 44.9239 20.9089 42.6029 19.1049C41.2839 18.0789 40.8319 16.2849 41.4709 14.7409L42.9219 11.2389C43.5369 9.75494 43.1399 8.03895 41.9259 6.98695C37.6579 3.28395 32.0889 1.04395 25.9969 1.04395C19.9039 1.04395 14.3349 3.28395 10.0669 6.98495C8.8529 8.03895 8.4559 9.75494 9.0709 11.2389L10.5219 14.7409C11.1609 16.2849 10.7099 18.0789 9.3899 19.1049C6.9659 20.9889 5.4039 23.9309 5.4039 27.2319L5.4129 31.9099C5.4129 32.9429 4.5749 33.7809 3.5419 33.7809C2.5079 33.7809 1.6709 32.9429 1.6709 31.9099V36.5349C1.6709 39.6349 4.1839 42.1489 7.2839 42.1489H11.0269C13.0939 42.1489 14.7689 43.8239 14.7689 45.8909V48.3859H37.2239V45.8909C37.2239 43.8239 38.8989 42.1489 40.9659 42.1489C44.0669 42.1489 46.5799 39.6349 46.5799 36.5349Z" fill="#FFEF73"/>
            <path d="M37.23 53.999V51.192L31.616 48.385L33.488 53.999C33.488 55.032 34.326 55.87 35.359 55.87C36.392 55.87 37.23 55.032 37.23 53.999Z" fill="#FFEF73"/>
            <path d="M33.48 53.999V51.192L27.866 48.385L29.738 53.999C29.738 55.032 30.576 55.87 31.609 55.87C32.642 55.87 33.48 55.032 33.48 53.999Z" fill="#FFF9CC"/>
            <path d="M29.74 53.999V51.192L24.126 48.385L25.998 53.999C25.998 55.032 26.836 55.87 27.869 55.87C28.902 55.87 29.74 55.032 29.74 53.999Z" fill="#FFF9CC"/>
            <path d="M26 53.999V51.192L20.386 48.385L22.258 53.999C22.258 55.032 23.096 55.87 24.129 55.87C25.162 55.87 26 55.032 26 53.999Z" fill="#FFF9CC"/>
            <path d="M22.26 53.999V51.192L16.646 48.385L18.518 53.999C18.518 55.032 19.356 55.87 20.389 55.87C21.422 55.87 22.26 55.032 22.26 53.999Z" fill="#FFF9CC"/>
            <path d="M18.5201 53.999V48.385L14.7781 51.192V53.999C14.7781 55.032 15.6161 55.87 16.6491 55.87C17.6821 55.87 18.5201 55.032 18.5201 53.999Z" fill="#FFF9CC"/>
            <path d="M37.23 51.1919V48.3849C37.23 47.3519 36.392 46.5139 35.359 46.5139C34.326 46.5139 33.488 47.3519 33.488 48.3849L31.616 49.7889L33.488 51.1919H37.23Z" fill="#FFF9CC"/>
            <path d="M33.48 51.1919V48.3849C33.48 47.3519 32.642 46.5139 31.609 46.5139C30.576 46.5139 29.738 47.3519 29.738 48.3849L27.866 49.7889L29.738 51.1919H33.48Z" fill="white"/>
            <path d="M29.74 51.1919V48.3849C29.74 47.3519 28.902 46.5139 27.869 46.5139C26.836 46.5139 25.998 47.3519 25.998 48.3849L24.126 49.7889L25.998 51.1919H29.74Z" fill="white"/>
            <path d="M26 51.1919V48.3849C26 47.3519 25.162 46.5139 24.129 46.5139C23.096 46.5139 22.258 47.3519 22.258 48.3849L20.386 49.7889L22.258 51.1919H26Z" fill="white"/>
            <path d="M22.26 51.1919V48.3849C22.26 47.3519 21.422 46.5139 20.389 46.5139C19.356 46.5139 18.518 47.3519 18.518 48.3849L16.646 49.7889L18.518 51.1919H22.26Z" fill="white"/>
            <path d="M18.5201 51.1919V48.3849C18.5201 47.3519 17.6821 46.5139 16.6491 46.5139C15.6161 46.5139 14.7781 47.3519 14.7781 48.3849V51.1919H18.5201Z" fill="white"/>
            <path d="M36.28 20.6821V33.7811C39.897 33.7811 42.829 30.8481 42.829 27.2311C42.829 23.6141 39.897 20.6821 36.28 20.6821Z" fill="#82828F"/>
            <path d="M39.088 27.231C39.088 23.614 37.831 20.682 36.281 20.682C32.664 20.682 29.732 23.614 29.732 27.231C29.732 30.848 32.664 33.78 36.281 33.78C37.831 33.78 39.088 30.848 39.088 27.231Z" fill="#9595A3"/>
            <path d="M15.7 20.6821V33.7811C19.317 33.7811 22.249 30.8481 22.249 27.2311C22.249 23.6141 19.317 20.6821 15.7 20.6821Z" fill="#82828F"/>
            <path d="M18.5079 27.231C18.5079 23.614 17.2509 20.682 15.7009 20.682C12.0839 20.682 9.15193 23.614 9.15193 27.231C9.15193 30.848 12.0839 33.78 15.7009 33.78C17.2509 33.78 18.5079 30.848 18.5079 27.231Z" fill="#9595A3"/>
            <path d="M27.6221 32.7799C26.9019 31.533 25.1013 31.5328 24.3809 32.7799L21.1402 38.3938L30.8624 38.3939L27.6221 32.7799Z" fill="#9595A3"/>
            <path d="M26 35.5859C23.994 35.5859 22.141 36.6559 21.138 38.3929L20.771 39.0289C20.055 40.2689 20.941 41.8179 22.368 41.8359C22.886 41.8419 23.381 41.6209 23.748 41.2549L26 39.0029L28.252 41.2549C28.618 41.6209 29.114 41.8419 29.632 41.8359C31.059 41.8189 31.945 40.2699 31.229 39.0289L30.862 38.3929C29.859 36.6559 28.006 35.5859 26 35.5859Z" fill="#82828F"/>
            <path d="M14.77 51.1919H37.225" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M26.0001 48.3861C26.0001 47.3531 25.1621 46.5151 24.1291 46.5151C23.0951 46.5151 22.2581 47.3531 22.2581 48.3861V54.0001C22.2581 55.0331 23.0951 55.8711 24.1291 55.8711C25.1621 55.8711 26.0001 55.0331 26.0001 54.0001" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M37.23 48.3861C37.23 47.3531 36.392 46.5151 35.359 46.5151C34.325 46.5151 33.488 47.3531 33.488 48.3861V54.0001C33.488 55.0331 34.325 55.8711 35.359 55.8711C36.392 55.8711 37.23 55.0331 37.23 54.0001" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M33.48 48.3861C33.48 47.3531 32.642 46.5151 31.609 46.5151C30.575 46.5151 29.738 47.3531 29.738 48.3861V54.0001C29.738 55.0331 30.575 55.8711 31.609 55.8711C32.642 55.8711 33.48 55.0331 33.48 54.0001" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M29.74 48.3861C29.74 47.3531 28.902 46.5151 27.869 46.5151C26.835 46.5151 25.998 47.3531 25.998 48.3861V54.0001C25.998 55.0331 26.835 55.8711 27.869 55.8711C28.902 55.8711 29.74 55.0331 29.74 54.0001" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.2601 48.3861C22.2601 47.3531 21.4221 46.5151 20.3891 46.5151C19.3551 46.5151 18.5181 47.3531 18.5181 48.3861V54.0001C18.5181 55.0331 19.3551 55.8711 20.3891 55.8711C21.4221 55.8711 22.2601 55.0331 22.2601 54.0001" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.77 53.999C14.77 55.032 15.608 55.87 16.641 55.87C17.675 55.87 18.512 55.032 18.512 53.999" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.5201 48.3861C18.5201 47.3531 17.6821 46.5151 16.6491 46.5151C15.6151 46.5151 14.7781 47.3531 14.7781 48.3861" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M36.28 33.7809C32.6631 33.7809 29.731 30.8484 29.731 27.2309C29.731 23.6134 32.6631 20.6809 36.28 20.6809C39.8969 20.6809 42.829 23.6134 42.829 27.2309C42.829 30.8484 39.8969 33.7809 36.28 33.7809Z" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.6999 33.7809C12.083 33.7809 9.1509 30.8484 9.1509 27.2309C9.1509 23.6134 12.083 20.6809 15.6999 20.6809C19.3168 20.6809 22.2489 23.6134 22.2489 27.2309C22.2489 30.8484 19.3168 33.7809 15.6999 33.7809Z" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.28296 42.1479V49.3219" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M44.7099 53.064V57.012C44.7099 58.08 45.1939 59.073 45.9789 59.798C46.3699 60.159 46.6059 60.684 46.5789 61.263C46.5319 62.27 45.6649 63.044 44.6569 63.044H7.33791C6.33091 63.044 5.46391 62.27 5.41691 61.263C5.38891 60.684 5.62591 60.159 6.01591 59.798C6.80091 59.073 7.28591 58.08 7.28591 57.012V53.064" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M44.713 42.1479V49.3219" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.0301 42.148V53.999C11.0301 55.033 11.8681 55.87 12.9011 55.87C13.9351 55.87 14.7721 55.033 14.7721 53.999V45.89C14.7721 43.823 13.0971 42.148 11.0301 42.148ZM11.0301 42.148H7.28707C4.18707 42.148 1.67407 39.635 1.67407 36.534V25.369C1.67407 11.935 12.5651 1.04297 26.0001 1.04297C39.4351 1.04297 50.3261 11.934 50.3261 25.369V36.534C50.3261 39.635 47.8121 42.148 44.7121 42.148H40.9701M40.9701 42.148C38.9031 42.148 37.2271 43.823 37.2271 45.89V53.999C37.2271 55.033 38.0651 55.87 39.0981 55.87C40.1321 55.87 40.9701 55.033 40.9701 53.999V42.148Z" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M31.23 39.029L27.622 32.779C26.901 31.532 25.101 31.532 24.381 32.779L20.798 38.985C20.095 40.203 20.629 41.506 22.005 41.795C22.634 41.928 23.274 41.73 23.716 41.288L26.001 39.003L28.253 41.255C28.62 41.621 29.115 41.842 29.633 41.836C31.06 41.818 31.946 40.269 31.23 39.029Z" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.07 6.98608C8.85595 8.03908 8.45895 9.75408 9.07395 11.2381L10.525 14.7411C11.164 16.2851 10.712 18.0781 9.39295 19.1041C6.96895 20.9881 5.40695 23.9311 5.40695 27.2311L5.41595 31.9091C5.41595 32.9431 4.57795 33.7811 3.54495 33.7811C2.51095 33.7811 1.67395 32.9431 1.67395 31.9091" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M41.9301 6.98608C43.1441 8.03908 43.5411 9.75408 42.9261 11.2381L41.4751 14.7411C40.8361 16.2851 41.2881 18.0781 42.6071 19.1041C45.0311 20.9881 46.5931 23.9311 46.5931 27.2311L46.5841 31.9091C46.5841 32.9431 47.4221 33.7811 48.4551 33.7811C49.4891 33.7811 50.3261 32.9431 50.3261 31.9091" stroke="black" stroke-width="1.87" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}