import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import useStyles from "../../configs/styles";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { assignTeacherToClass, unassignTeacherFromClass } from "../../apiRequest/class";
import ApproveModal from "../modals/ApproveModal";
import AssignClassToTeacherModal from "../modals/AssignClassToTeacherModal";
import CreateNewClassModal from "../modals/CreateNewClassModal";
import { t } from "../../features/i18n";

interface IClassesExpanderTable {
  color: string;
  classInfo?: IClassOverview[];
  teacherCode?: string;
  resetTable: () => void;
}
// const handleAssign = (classObject: IClassOverview) => {
//   assignTeacherToClass({
//     classID: 0,
//     TeacherCode: ""
//   })
// };
const ClassesExpanderTable: React.FC<IClassesExpanderTable> = ({
  color,
  classInfo,
  teacherCode,
  resetTable
}) => {
  const classes = useStyles({});
  const [openDetail, setOpenDetail] = useState(true);
  const [approveModal, setApproveModal] = useState(false);
  const [openAssignClassToTeacherModal, setOpenAssignClassToTeacherModal] = useState(false);
  const [openAddClassModal, setOpenAddClassModal] = React.useState(false);
  const [targetRow, setTargetRow] = useState<IClassOverview>();


  const handleUnassign = (classObject: IClassOverview) => {
    setTargetRow(classObject)
    setApproveModal(true)
  };

  const handleUnassignApproved = () => {
    if (teacherCode && targetRow)
      unassignTeacherFromClass({
        class_id: targetRow?.id,
        teacher_code: teacherCode
      })
  };

  useEffect(() => {

  }, []);

  return (
    <>
      {!classInfo ? (<>---</>) : (
        <>
          {openDetail ? (
            <>{t("unzip")}
              <IconButton
                onClick={() => setOpenDetail(false)}
                sx={{
                  height: "11px",
                  padding: "0px",
                  color: color,
                }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </>
          ) : (
            <Card
              // className={classes.tableCard}
              elevation={3}
            // style={{ borderTop: "8px solid" + color }}
            >

              <Typography
                className={classes.tableTitle}
                sx={{
                  color: color,
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item sx={{ mr: "20px" }}>
                    <IconButton
                      onClick={() => setOpenDetail(true)}
                      sx={{
                        height: "11px",
                        padding: "0px",
                        color: color,
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => setOpenAssignClassToTeacherModal(true)}
                      sx={{
                        width: 120,
                        height: 20,
                        color: color,
                        fontSize: "10px",
                        textTransform: "none",
                        fontWeight: "bold",
                        borderColor: color,
                      }}
                    >
                      {t("assign_class")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => setOpenAddClassModal(true)}
                      sx={{
                        width: 120,
                        height: 20,
                        color: color,
                        fontSize: "10px",
                        textTransform: "none",
                        fontWeight: "bold",
                        borderColor: color,
                      }}
                    >
                      {t("create_class")}
                    </Button>
                  </Grid>

                </Grid>
              </Typography>
              <TableContainer
                sx={{
                  paddingBottom: "20px",
                }}
              >
                <Table size="small" aria-label="a dense table">

                  <TableBody>
                    {classInfo?.map((row, index) => (
                      <TableRow key={row.id} style={{ height: '50px' }}>
                        <TableCell>{row.class_name}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleUnassign(row)}
                            sx={{
                              height: "11px",
                              padding: "0px",
                              color: color,
                            }}
                          >
                            <LinkOffIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow >
                    ))}

                  </TableBody>
                </Table>
              </TableContainer>

              <ApproveModal
                message={t("remove_class_from_teacher")}
                open={approveModal}
                Approve={() => {
                  handleUnassignApproved();
                  setApproveModal(false);
                }}
                Denied={() => setApproveModal(false)}
              />
              {teacherCode ? (
                <AssignClassToTeacherModal
                  open={openAssignClassToTeacherModal}
                  teacherCode={teacherCode}
                  onClose={() => setOpenAssignClassToTeacherModal(false)}
                  resetTable={resetTable} />
              ) : (null)}

              <CreateNewClassModal
                open={openAddClassModal}
                onClose={() => {
                  setOpenAddClassModal(false);
                }}
                skipAssignToTeacher={true}
                resetTable={resetTable}
              />
            </Card>
          )}
        </>
      )}
    </>
  );
};

export default ClassesExpanderTable;
