import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { t } from "../i18n";
import { secondsToMinutesAndSeconds } from "../../helpers/timeFormat.helper";
import { useDispatch } from "react-redux";
import { logout } from "../redux/userSlice";
import { useNavigate } from "react-router-dom";
import { useSessionExpiration } from "./SessionExpiration.hook";

const EXPIRATION_COUNTDOWN = 180; // countdown in seconds

export const SessionExpirationChecker: React.FC = () => {
  const [openWarning, setOpenWarning] = useState(false);
  const [countdownActive, setCountdownActive] = useState(false);
  const [logoutIn, setLogoutIn] = useState(EXPIRATION_COUNTDOWN);
  const { isExpired } = useSessionExpiration();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (countdownActive) {
      if (logoutIn >= 0) {
        const intervalId = setInterval(() => {
          setLogoutIn((prev) => prev - 1);
        }, 1000);

        return () => {
          clearInterval(intervalId);
        };
      } else {
        console.log("Logout user");
        dispatch(logout());
        navigate("/login");
      }
    }
  }, [logoutIn, countdownActive, dispatch, navigate]);

  useEffect(() => {
    setLogoutIn(EXPIRATION_COUNTDOWN);
    setCountdownActive(isExpired);
  }, [isExpired]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenWarning(false);
  };

  return (
    <Snackbar open={openWarning} onClose={handleClose} anchorOrigin={{ horizontal: "center", vertical: "top" }} key={"session_expiration_snackbar"}>
      <Alert severity="warning" variant="filled" sx={{ width: "100%" }}>
        <div>
          {t("user_will_be_logged_out")} {secondsToMinutesAndSeconds(logoutIn)}
        </div>
      </Alert>
    </Snackbar>
  );
};
