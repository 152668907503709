import { Box, Grid } from "@mui/material";
import EventsTableDetail from "../components/tables/EventsTableDetail";
import { t } from "../features/i18n";

interface ICalendar { }

const Calendar: React.FC<ICalendar> = () => {

  return (
    <Box>
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="left" spacing={4}>
            <Grid item sm={12}>
                <EventsTableDetail
                  color="#0058B4"
                  title={t("name_calendar")}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Calendar;
