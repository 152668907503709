import React, { useState } from 'react';
import { Modal, Box, Button, Theme, Paper, TextField, Typography } from '@mui/material';
import { z } from 'zod';
import api from '../../api';
import { makeStyles } from "@mui/styles";
import { t } from '../../features/i18n';
const useStyles = makeStyles<Theme, { borderColor: string }>((theme) => ({
    paper: {
        borderTop: (props) => `8px solid ${props.borderColor}`,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: "40px",
    },
    tableRow: {
        "&:not(:last-child)": {
            borderBottom: "none",
        },
    },
}));

// Zod schema pro validaci souboru
const FileSchema = z.object({
    file: z.any().refine((val) => val instanceof File, {
        message: t("pick_file"),
        path: ['file'],
    }),
});

interface IUploadFileModal {
    resource_id: number;
    resource_type: string;
    expected_document_id?: number;
    open: boolean;
    color?: string;
    onClose: () => void;
    handleReload: () => void;
}

const UploadFileModal: React.FC<IUploadFileModal> = ({ resource_id, open, onClose, resource_type, color, handleReload, expected_document_id }) => {
    const [file, setFile] = useState<File | null>(null);
    const [fileError, setFileError] = useState<string | null>(null);
    const [description, setDescription] = useState('');

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedFile = event.target.files[0];
            const validationResult = FileSchema.safeParse({ file: selectedFile });

            if (validationResult.success) {
                setFile(selectedFile);
                setFileError(null);
            } else {
                setFile(null);
                setFileError(validationResult.error.errors[0].message);
            }
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const selectedFile = event.dataTransfer.files[0];
            const validationResult = FileSchema.safeParse({ file: selectedFile });

            if (validationResult.success) {
                setFile(selectedFile);
                setFileError(null);
            } else {
                setFile(null);
                setFileError(validationResult.error.errors[0].message);
            }
        }
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };


    const handleSubmit = () => {
        if (file) {
            const formData = new FormData();
            formData.append('resource_type', resource_type);
            formData.append('resource_id', resource_id.toString());
            if (expected_document_id){
                formData.append('expected_document_id', expected_document_id.toString());
            }
            formData.append('description', description);
            formData.append('file', file);
            try {
                api.post('/documents/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                    .then((response) => {
                        console.log("response.data");
                        console.log(response.data);
                        handleReload();
                        onClose();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } catch (error) {
                console.error(error);
            }
        }
    };

    const classes = useStyles({ borderColor: color || "red" });

    return (
        <Modal open={open} onClose={onClose}>
            <Paper className={classes.paper}>
                <Typography
                    sx={{
                        fontSize: "24px",
                    }}
                >
                    {t("upload_file")}
                </Typography>
                <input type="file" onChange={handleFileUpload} />
                {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                {/* <Box
                    sx={{ border: '1px dashed grey', p: 2, mt: 2 }}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <p>{t("drag-drop")}</p>
                </Box> */}
                <TextField
                    label={t("note")}
                    variant="outlined"
                    value={description}
                    onChange={handleDescriptionChange}
                    fullWidth
                    margin="normal"
                />

                <Button
                    variant="outlined"
                    onClick={onClose}

                    sx={{
                        width: 140,
                        height: 40,
                        color: color,
                        textTransform: "none",
                        fontWeight: "bold",
                        borderColor: color,
                    }}
                >
                    {t("cancel")}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!file || !description}
                    sx={{
                        width: 140,
                        height: 40,
                        bgcolor: color,
                        "&:hover": {
                            backgroundColor: color,
                        },
                        fontWeight: "bold",
                        position: "absolute",
                        right: "40px",
                    }}
                >
                    {t("upload")}
                </Button>
            </Paper>
        </Modal>
    );
};

export default UploadFileModal;