import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { t } from "../features/i18n";
import TS360Viewer from "../features/ThreeSixtyViewer/TS360Viewer";

export interface IModels {}

const Models: React.FC<IModels> = (props) => {
  const [images, setImages] = useState<IImageParameters[]>([]);
  const [dataCheck, setDataCheck] = useState<boolean>(false);

  useEffect(() => {
    setDataCheck(false);
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await fetch("/360Images/360GalleryData.json");
      const data = await response.json();
      await addImageData(data);
    } catch (error) {
      console.error("Something went wrong", error);
    }
  };

  const addImageData = async (data: any) => {
    let imagesData: IImageParameters[] = [];
    data.map((img: any, index: any) => {
      if (
        img.amount !== undefined &&
        img.path !== undefined &&
        img.fileName !== undefined
      ) {
        const filepath2 = img.path.replace("{index}", "01");
        const fileName2 = img.fileName.replace("{index}", "01");
        const firstImagePath = filepath2 + "/" + fileName2
        let rotation = "left"
        if (img.rotation !== undefined){
          rotation = img.rotation
        } 

        let description = t("missing_description")
        if (img.description !== undefined){
          description = img.description
        } 

        const image: IImageParameters = {
          id: index,
          amount: img.amount,
          path: img.path,
          fileName: img.fileName,
          firstImagePath: firstImagePath,
          rotation:rotation,
          description: description
        };
        imagesData.push(image);
      }
      if (imagesData.length !== 0) {
        setImages(imagesData);
        setDataCheck(true);
      }
    });
  };

  return (
    <div>
      <Typography variant="h4" sx={{ mb: 5 }}>
      {t("models")}
      </Typography>

      {dataCheck ? (
        <TS360Viewer images={images} />
      ) : (
        <Typography sx={{}}>
          {t("not_find_any_models")}
        </Typography>
      )}
    </div>
  );
};

export default Models;
