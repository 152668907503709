import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  Alert,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SubjectIcon } from "../icons/menu/SubjectIcon";
import { t } from "../../features/i18n";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { RootState } from "../../features/redux/store";
import { queries } from "../../features/graphql/queries";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { z, ZodError } from "zod";
import MyTextField from "./MyTextField";
import MyIconField from "./MyIconField";

import { assignTeachersToSubject, createSubject } from "../../apiRequest/subject";
import { assignClassesToSubject } from "../../apiRequest/class";
import MessageComponent from "../MessageComponent";

const schema = z.object({
  subjectName: z
    .string()
    .min(1, { message: t("name_subject_min_one_letter") })
    .max(25, { message: t("name_subject_too_long") }),
  values: z
    .number()
    .min(1, { message: t("name_subject_too_long") }),
  iconName: z
    .string()
    .min(1, { message: t("message_icon_missing") }),
});

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: "8px solid #71BD55",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  root: {
    flexDirection: "column",
    display: "flex",
    width: "20%",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#d4e9cc",
    },
    backgroundColor: "#D9D9D9",
    textDecoration: "none",
    color: "inherit",
  },
}));

interface ICreateSubjectModal {
  open: boolean;
  onClose: () => void;
  resetSubjects: () => void;
};

const CreateSubjectModal: React.FC<ICreateSubjectModal> = ({ open, onClose, resetSubjects }) => {
  const { data } = useQuery<IClassesResponse>(queries.GET_CLASSES);
  const [loading, setLoading] = useState<boolean>(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);

  const classes = useStyles();
  const userCode = useSelector((state: RootState) => state.user.userCode);
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });
  const {
    setValue,
    handleSubmit,
  } = methods;

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const validatedData = schema.parse(data);
      console.log('Validation passed:', data);

      const CreateSubjectResponse = await createSubject({
        name: validatedData.subjectName,
        icon: validatedData.iconName,
        description: " - class description"
      })
      console.log("CreateSubjectResponse", CreateSubjectResponse)
      setLoading(true)
      await AssignClassesAndTeacherToSubject(CreateSubjectResponse.id, validatedData.values)
      setTimeout(() => {
        setSuccessAlertOpen(true);
        setTimeout(() => {
          resetSubjects();
          onClose();
        }, 2000);
      }, 2000);

    } catch (error) {
      if (error instanceof ZodError) {
        console.error('Validation failed:', error.errors);
      }
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }
  };

  async function AssignClassesAndTeacherToSubject(subjectId: number, classId: number) {
    try {
      await assignClassesToSubject({
        subject_id: subjectId,
        class_ids: [classId]
      })

      if (userCode)
        await assignTeachersToSubject({
          subjectId: subjectId,
          teacherCodes: [userCode.toString()]
        })
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {successAlertOpen ? (
          <MessageComponent
            errorChange={errorAlertOpen}
            errorMessage={t("create_subject_error")}
            successMessage={t("new_subject_successful_created")}
          />
        ) : (
          <Box>
            <Box
              sx={{
                maxHeight: "50px",
                alignText: "left",
                color: '#71BD55',
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item sx={{ mr: "20px" }}>
                  <SubjectIcon />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "24px",
                    }}
                  >
                    {t("add_subject")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <FormProvider {...methods}>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ mt: "30px " }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <MyTextField
                  name="subjectName"
                  schema={schema}
                  setValue={setValue}
                  type="text"
                  required
                  fullWidth
                  label={t("name_subject")}
                  sx={{
                    mb: 2,
                    width: 200,
                    height: 40,
                  }}
                />
                <Select
                  label={t("select_class")}
                  variant="filled"
                  sx={{
                    width: 200,
                    height: 40,
                    position: "absolute",
                    right: "40px",
                  }}
                  {...methods.register("values")}
                >
                  {data?.classes.map((classItem, index) => (
                    <MenuItem key={index} value={classItem.id}>
                      {classItem.className}
                    </MenuItem>
                  ))}
                </Select>
                <Box sx={{ mt: "50px ", background: "#D9D9D9", height: "200px" }}>
                  <Box sx={{ margin: "10px" }}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#4D4D4D",
                      }}>
                      {t("select_subject_icon")}
                    </Typography>
                  </Box>
                  <Box sx={{ margin: "10px" }}>
                    <MyIconField
                      name="iconName"
                      schema={schema}
                      setValue={setValue}
                      type="text"
                      required
                      fullWidth
                      label={t("name_subject")}

                    />
                  </Box>
                </Box>


                <Box sx={{ mt: "25px " }}>
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{
                      width: 140,
                      height: 40,
                      color: "#71BD55",
                      textTransform: "none",
                      fontWeight: "bold",
                      borderColor: "#71BD55",
                    }}
                  >
                    {t("cancel")}
                  </Button>

                  <Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    sx={{
                      width: 140,
                      height: 40,
                      bgcolor: "#71BD55",
                      "&:hover": {
                        backgroundColor: "#71BD55",
                      },
                      fontWeight: "bold",
                      position: "absolute",
                      right: "40px",
                    }}
                  >
                    {loading ? t("processing") : t("confirm")}

                  </Button>

                </Box>
              </Box>

            </FormProvider>
          </Box>
        )}

      </Paper>
    </Modal>
  );
};

export default CreateSubjectModal;
