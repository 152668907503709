import React from "react";

interface IconAttributes {
    className?: string,
}

export const CzechIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <svg width="60" height="61" viewBox="0 0 60 61"  fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 60.0429C46.568 60.0429 60 46.6119 60 30.0429C60 13.4749 46.568 0.0439453 30 0.0439453C13.432 0.0439453 0 13.4749 0 30.0429C0 46.6119 13.432 60.0429 30 60.0429Z" fill="#F0F0F0"/>
            <path d="M27.392 30.0443L8.78694 51.2574C14.2169 56.6848 21.7165 60.0435 29.9997 60.0445C46.5665 60.0443 60.0001 46.6098 60 30.0443L27.392 30.0443Z" fill="#D80027"/>
            <path d="M8.78702 8.83105C-2.92998 20.5481 -2.92998 39.5411 8.78702 51.2571L30 30.0441L8.78702 8.83105Z" fill="#0052B4"/>
        </svg>
    );
}