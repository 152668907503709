import { Theme, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles<Theme>(theme =>
    createStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            bgcolor: "#302c2c",
        },
        tableCard: {
            borderRadius: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
        },
        tableCardContent: {
            minWidth: "400px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 16,
        },
        tableButton: {
            width: 216,
            height: 40,
            fontSize: "14px",
            textTransform: 'none',
            fontWeight: 'bold',
        },
        tableTitle: {
            marginTop: "10px",
            maxHeight: "50px",
            alignText: "left",
            fontSize: "24px",
        },
        tableLegendWraper: {
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "25px",
            marginRight: "20px",
        },
        tableLegendContent: {
            width: "350px",
            display: "flex",
            justifyContent: "space-between",
        },
        classCard: {
            width: "220px",
            height: "180px",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            transition: "background-color 0.3s ease",
            "&:hover": {
                backgroundColor: "#FFF0D4",
            },
            backgroundColor: "#ffffff",
            textDecoration: "none",
            color: "inherit",
        },
        chapter: {
            borderRadius: "10px",
            // paddingLeft: "20px",
            // paddingRight: "20px",
        },
    }));

export default useStyles;
