import { SvgIcon } from "@mui/material";
import React from "react";

interface IconAttributes {
    className?: string,
}

export const TaskIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <SvgIcon width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.18302 5.59807H6.54614V9.96117H2.18302V5.59807ZM1.09248 12.1432H7.63764C8.23955 12.1432 8.72818 11.6546 8.72818 11.0527V4.50754C8.72818 3.90467 8.23955 3.41602 7.63764 3.41602H1.09248C0.489609 3.41602 0.000976562 3.90467 0.000976562 4.50754V11.0527C0.000976562 11.6546 0.489609 12.1432 1.09248 12.1432Z"/>
            <path d="M12.0012 8.87467H22.9095C23.5123 8.87467 24.001 8.38604 24.001 7.78413C24.001 7.18126 23.5123 6.69263 22.9095 6.69263H12.0012C11.3983 6.69263 10.9106 7.18126 10.9106 7.78413C10.9106 8.38604 11.3983 8.87467 12.0012 8.87467Z"/>
            <path d="M22.9099 17.6006H12.0017C11.3988 17.6006 10.9102 18.0892 10.9102 18.6911C10.9102 19.294 11.3988 19.7826 12.0017 19.7826H22.9099C23.5128 19.7826 24.0005 19.294 24.0005 18.6911C24.0005 18.0892 23.5128 17.6006 22.9099 17.6006Z"/>
            <path d="M6.97817 15.6332C6.94169 15.6649 6.90617 15.6994 6.87449 15.7359L3.24287 19.3233L1.87682 17.9055C1.45826 17.4725 0.767077 17.461 0.334125 17.8795C-0.0997877 18.299 -0.111307 18.9893 0.308205 19.4232L2.43937 21.6379C2.64288 21.8452 2.92032 21.9633 3.21119 21.9652H3.22367C3.50975 21.9643 3.7843 21.851 3.98782 21.6503L8.40086 17.2872C8.85781 16.8936 8.90965 16.2053 8.51702 15.7484C8.12343 15.2914 7.43512 15.2405 6.97817 15.6332Z"/>
        </SvgIcon>
    );
}