import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { t } from "../../features/i18n";

type Props = {
  src: string;
  scale: number;
  percent: number;
  isLoad: boolean;
};

const ImageView: React.FC<Props> = ({ src, scale, percent, isLoad }) => {
  const [imageSize, setImageSize] = useState({ width: "auto", height: "auto" });

  useEffect(() => {
    try {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const { naturalWidth, naturalHeight } = img;
        if (naturalWidth > naturalHeight) {
          setImageSize({ width: "100%", height: "auto" });
        } else {
          setImageSize({ width: "auto", height: "100%" });
        }
      };
    } catch (error) {
      console.log(error)
    }
   
  }, [src]);

  const containerStyle: React.CSSProperties = {
    width: "800px",
    height: "600px",
    border: "5px solid white",
    background: "white",
    borderRadius: "10px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const imageStyle: React.CSSProperties = {
    width: imageSize.width,
    height: imageSize.height,
    objectFit: "contain",
    transform: `scale(${scale})`,
  };
  return (
    <div style={containerStyle}>
      {isLoad ? (
        <img src={src} alt={`Obrázek + ${src}`} style={imageStyle} />
      ) : (
        <Typography sx={{}}>{t("loading_images")}{percent} %</Typography>
      )}
    </div>
  );
};

export default ImageView;
