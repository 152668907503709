import React from "react";
import { Card, Typography } from "@mui/material";
import useStyles from "../configs/styles";

interface IAddSubjectItem {
  className?: string;
  onOpen: () => void;
}

const AddSubjectItem: React.FC<IAddSubjectItem> = ({ className, onOpen }) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.classCard}
      elevation={3}
      onClick={() => {
        onOpen();
      }}
    >
      <Typography
        sx={{
          fontSize: "50px",
          fontWeight: "bold",
        }}
      >
        +
      </Typography>
    </Card>
  );
}

export default AddSubjectItem;
