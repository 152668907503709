import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { Box, Grid, Link, Typography } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../features/redux/store";
import { normalizeUrl } from "../configs/globalConfigs";
interface IFooter {}

const Footer: React.FC<IFooter> = () => {
  const appName = useSelector((state: RootState) => state.footer.appName);
  const schoolEmail = useSelector((state: RootState) => state.footer.schoolEmail);
  const schoolName = useSelector((state: RootState) => state.footer.schoolName);
  const schoolWebsite = useSelector((state: RootState) => state.footer.schoolWebsite);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        margin: 0,
        backgroundColor: "#f5f5f5",
        padding: "10px",
        fontSize: "14px",
        borderTop: "1px solid #ddd",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div>
          <Typography
            sx={{
              fontSize: "12",
              fontWeight: "bold",
              ml: "15px",
            }}
          >
            {/* {t("footer_zm_group")}{" "}
            <Link color="inherit" href="https://www.omnneo.cz/">
              {t("footer_zm_website")}
            </Link>{" "} */}
            {appName}
          </Typography>
        </div>
        <div style={{ whiteSpace: "nowrap" }}>
          <Typography
            variant="body1"
            component="span"
            style={{
              display: "inline-block",
              marginRight: "30px",
              verticalAlign: "middle",
            }}
            sx={{
              fontSize: "12",
              fontWeight: "bold",
            }}
          >
            <Grid>
              <Grid item>{schoolName}</Grid>
            </Grid>
          </Typography>
          <Typography
            variant="body1"
            component="span"
            style={{
              display: "inline-block",
              marginRight: "44px",
              verticalAlign: "middle",
            }}
            sx={{
              fontSize: "11",
            }}
          >
            <Link underline="none" color="inherit" href={`mailto:${schoolEmail}?Subject=Hello&body=links:%0D http://link1.com  %0D http://link1.com`} target="_top">
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item sx={{ mr: "15px" }}>
                  <MailOutlinedIcon />
                </Grid>
                <Grid item>{schoolEmail}</Grid>
              </Grid>
            </Link>
          </Typography>
          <Typography
            variant="body1"
            component="span"
            style={{
              display: "inline-block",
              marginRight: "47px",
              verticalAlign: "middle",
            }}
            sx={{
              fontSize: "11",
            }}
          >
            <Link underline="none" color="inherit" href={normalizeUrl(schoolWebsite || "")}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item sx={{ mr: "15px" }}>
                  <LanguageOutlinedIcon />
                </Grid>
                <Grid item>{schoolWebsite}</Grid>
              </Grid>
            </Link>
          </Typography>
        </div>
      </Box>
    </Box>
  );
};

export default Footer;
