import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  styled,
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { CurriculumIcon } from "../icons/menu/CurriculumIcon";
import { useSelector } from "react-redux";
import { RootState } from "../../features/redux/store";
import useStyles from "../../configs/styles";
import { t } from "../../features/i18n";
import { gql, useQuery } from "@apollo/client";
import { client } from "../..";
import { GetListAvailableAssigneesTable, GetListOfStudents } from "../../apiRequest/task";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const GET_SUBJECT_BY_ID = gql`
query GetStudents($id: Int!) {
  classStudents(id: $id) {
    classId
    id
    user {
      code
      email
      name
      role
    }
  }
}
`;


const StudentsTable: React.FC<IStudentTable> = ({
  color,
  title,
  buttonName,
  assignmentID,
  classID,
  modalButton,
}) => {
  const classes = useStyles({});
  const role = useSelector((state: RootState) => state.user.role);
  const [tableData, setTableData] = useState<IStudentTableData[]>()

  useEffect(() => {

    async function loadData(): Promise<void> {
      if (classID) {
        setTableData(await GetListOfStudents(classID))
      } else {
        const dataResponse: IStudentTableData[] = [];
        dataResponse.push({
          id: 0,
          classId: 0,
          userCode: "",
          userEmail: "",
          userName: t("student_is_not_there"),
          userRole: "",
        })

        setTableData(dataResponse)
      }
    }
    loadData();
  }, []);

  return (
    <Card
      className={classes.tableCard}
      elevation={3}
      style={{ borderTop: "8px solid" + color }}
    >
      <Box className={classes.tableCardContent}>
        <CardContent
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "0px",
          }}
        >
          <Typography
            className={classes.tableTitle}
            sx={{
              color: color,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item sx={{ mr: "20px" }}>
                <CurriculumIcon />
              </Grid>
              <Grid item>{title}</Grid>
            </Grid>
          </Typography>
        </CardContent>
      </Box>
      <TableContainer
        sx={{
          paddingBottom: "20px",
        }}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: "auto" }} align="left">
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#808080",
                  }}
                >
                  {t("name_student")}
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell style={{ width: "auto" }} align="left">
                  <Typography
                    sx={{
                      fontSize: "15px",
                      color: "#4D4D4D",
                    }}
                  >
                    {row.userName}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default StudentsTable;
