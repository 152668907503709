import React from "react";

interface IconAttributes {
    className?: string,
}

export const ScienceIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <svg width="60" height="62" viewBox="0 0 60 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.4521 34.2641L40.1761 14.0401C40.7861 13.1411 42.0091 12.9061 42.9081 13.5161C43.1141 13.6561 43.2921 13.8341 43.4321 14.0401L57.1551 34.2641H26.4521Z" fill="#5BBD45"/>
            <path d="M26.4521 34.2638H48.7121L37.5831 17.8608L26.4521 34.2638Z" fill="#50A32F"/>
            <path d="M41.8039 20.6489L45.0789 23.1059L47.5619 20.1269L43.4319 14.0399C42.8219 13.1409 41.5979 12.9069 40.6989 13.5169C40.4929 13.6569 40.3159 13.8339 40.1759 14.0399L36.0459 20.1269L38.5289 23.1049L41.8039 20.6489Z" fill="#E3F5FF"/>
            <path d="M40.2593 21.8061L37.5833 17.8611L36.0449 20.125L38.5288 23.1045L40.2593 21.8061Z" fill="#CFEBF5"/>
            <path d="M59.8809 9.415C58.7859 8.055 58.7779 6.118 59.8609 4.748C60.0459 4.527 60.0159 4.198 59.7949 4.014C59.7169 3.949 59.6219 3.908 59.5209 3.896C57.8499 3.65 56.5409 2.334 56.3029 0.660998C56.2709 0.375998 56.0129 0.170998 55.7279 0.202998C55.6269 0.214998 55.5319 0.254998 55.4549 0.319998C54.0899 1.41 52.1519 1.411 50.7869 0.320998C50.5649 0.137998 50.2359 0.169998 50.0529 0.391998C49.9889 0.469998 49.9489 0.563998 49.9379 0.663998C49.6969 2.345 48.3769 3.665 46.6959 3.905C46.4109 3.938 46.2049 4.196 46.2379 4.481C46.2489 4.582 46.2899 4.676 46.3539 4.754C47.4429 6.119 47.4419 8.057 46.3509 9.421C46.1679 9.642 46.1979 9.97 46.4189 10.153C46.4969 10.218 46.5919 10.258 46.6929 10.27C48.3729 10.508 49.6929 11.828 49.9319 13.508C49.9639 13.793 50.2219 13.998 50.5079 13.966C50.6079 13.954 50.7029 13.914 50.7809 13.85C52.1449 12.759 54.0829 12.759 55.4479 13.848C55.6709 14.031 55.9989 13.999 56.1819 13.777C56.2459 13.699 56.2859 13.605 56.2969 13.506C56.5379 11.825 57.8579 10.505 59.5379 10.264C59.8239 10.232 60.0289 9.974 59.9969 9.688C59.9859 9.588 59.9449 9.493 59.8809 9.415Z" fill="#FFC839"/>
            <path d="M53.1081 4.13794C54.7381 4.13794 56.0591 5.45894 56.0591 7.08894C56.0591 8.71894 54.7381 10.0399 53.1081 10.0399C51.4781 10.0399 50.1571 8.71894 50.1571 7.08894C50.1571 5.45894 51.4781 4.13794 53.1081 4.13794Z" fill="#FFF05B"/>
            <path d="M6.53394 34.626L24.9299 7.51595C25.5399 6.61695 26.7629 6.38295 27.6619 6.99295C27.8679 7.13295 28.0459 7.30995 28.1859 7.51595L46.5809 34.626H6.53394Z" fill="#6BCA5A"/>
            <path d="M46.5811 34.6252L28.9701 8.67232C28.1784 8.48688 27.3528 8.809 26.8968 9.48339L9.83594 34.6258L46.5811 34.6252Z" fill="#5BBD45"/>
            <path d="M26.558 14.1259L29.833 16.5829L32.316 13.6039L28.186 7.51693C27.576 6.61793 26.352 6.38393 25.453 6.99393C25.247 7.13293 25.07 7.31093 24.93 7.51693L20.8 13.6039L23.283 16.5829L26.558 14.1259Z" fill="#F4FBFF"/>
            <path d="M23.2821 16.5821L26.5571 14.1251L29.8331 16.5821L32.3151 13.6031L28.9701 8.67308C28.1781 8.48708 27.3531 8.81008 26.8971 9.48408L22.6201 15.7871L23.2821 16.5821Z" fill="#E3F5FF"/>
            <path d="M54.466 32.265C52.843 33.542 50.559 33.551 48.927 32.285L48.905 32.268C47.298 30.936 44.969 30.938 43.363 32.272L43.336 32.294C41.735 33.624 39.412 33.615 37.821 32.273L37.819 32.271C36.228 30.929 33.905 30.92 32.304 32.249L32.26 32.286C30.657 33.617 28.332 33.612 26.734 32.275L26.726 32.268C25.137 30.938 22.827 30.926 21.224 32.239L21.191 32.266C19.578 33.587 17.258 33.59 15.643 32.272L15.593 32.231C13.989 30.923 11.681 30.94 10.097 32.273C8.54 33.632 6.227 33.66 4.637 32.34C3.345 31.249 1.544 30.994 0 31.682C0.002 47.979 13.214 61.189 29.512 61.187C45.809 61.185 59.019 47.972 59.017 31.675C57.509 31.006 55.754 31.234 54.466 32.265Z" fill="#8796FF"/>
            <path d="M11.803 6.10097C11.26 6.10097 10.819 5.66097 10.819 5.11697C10.818 3.48797 9.49801 2.16797 7.86901 2.16697C7.32501 2.16697 6.88501 1.72597 6.88501 1.18297C6.88501 0.639975 7.32501 0.198975 7.86901 0.198975C9.41701 0.199975 10.875 0.929975 11.803 2.16897C12.731 0.929975 14.189 0.199975 15.738 0.198975C16.281 0.198975 16.721 0.639975 16.721 1.18297C16.721 1.72597 16.281 2.16697 15.738 2.16697C14.109 2.16797 12.788 3.48797 12.787 5.11697C12.787 5.66097 12.346 6.10097 11.803 6.10097Z" fill="#455378"/>
            <path d="M3.934 10.0359C3.391 10.0359 2.95 9.59592 2.95 9.05192C2.949 7.96592 2.069 7.08592 0.983 7.08492C0.44 7.08492 0 6.64492 0 6.10092C0 5.55792 0.44 5.11792 0.983 5.11792C2.113 5.11692 3.188 5.60392 3.934 6.45292C4.68 5.60392 5.755 5.11692 6.885 5.11792C7.428 5.11792 7.869 5.55792 7.869 6.10092C7.869 6.64492 7.428 7.08492 6.885 7.08492C5.799 7.08592 4.919 7.96592 4.918 9.05192C4.918 9.59592 4.477 10.0359 3.934 10.0359Z" fill="#455378"/>
            <path d="M14.754 17.905C14.76 15.738 13.008 13.977 10.841 13.971C9.59296 13.967 8.41796 14.558 7.67596 15.561C6.38896 14.573 4.54496 14.816 3.55696 16.104C3.16096 16.62 2.94796 17.254 2.95096 17.905C2.95096 17.965 2.96496 18.021 2.96796 18.08C1.43096 18.61 0.613961 20.285 1.14396 21.822C1.55396 23.012 2.67496 23.81 3.93396 23.807H14.754C16.384 23.807 17.705 22.486 17.705 20.856C17.705 19.226 16.384 17.905 14.754 17.905Z" fill="#F4FBFF"/>
            <path d="M14.7541 17.9049C14.7501 17.2689 14.5901 16.6439 14.2881 16.0849C12.7381 15.6419 11.0761 16.2189 10.1351 17.5279C8.84815 16.5409 7.00415 16.7839 6.01615 18.0709C5.62015 18.5869 5.40715 19.2209 5.41015 19.8719C5.41015 19.9319 5.42415 19.9879 5.42715 20.0479C3.91015 20.5489 3.08715 22.1859 3.58915 23.7039C3.59615 23.7269 3.60515 23.7509 3.61315 23.7739C3.71915 23.7909 3.82715 23.8019 3.93415 23.8069H14.7541C16.3841 23.8069 17.7051 22.4859 17.7051 20.8559C17.7051 19.2259 16.3841 17.9049 14.7541 17.9049Z" fill="#E3F5FF"/>
            <path d="M25.5741 56.266C17.7131 56.276 10.1741 53.138 4.64209 47.553C13.4081 61.289 31.6501 65.317 45.3861 56.55C53.8791 51.13 59.0181 41.75 59.0171 31.675C57.5501 31.029 55.8481 31.227 54.5681 32.193C51.9531 46.148 39.7721 56.262 25.5741 56.266Z" fill="#7F8CF0"/>
            <path d="M8.85303 42.0031C8.85303 42.0031 11.714 38.5601 15.246 38.5601C18.778 38.5601 21.639 42.0031 21.639 42.0031C21.639 42.0031 18.778 45.4461 15.246 45.4461C11.714 45.4461 8.85303 42.0031 8.85303 42.0031Z" fill="#B9ECFB"/>
            <path d="M25.6481 45.0091L21.6401 42.0031L25.6481 38.9971C26.8621 40.8171 26.8621 43.1891 25.6481 45.0091Z" fill="#B9ECFB"/>
            <path d="M28.5249 49.872C28.5249 49.872 30.9469 46.959 33.9349 46.959C36.9229 46.959 39.3449 49.872 39.3449 49.872C39.3449 49.872 36.9229 52.785 33.9349 52.785C30.9469 52.785 28.5249 49.872 28.5249 49.872Z" fill="#B9ECFB"/>
            <path d="M43.353 52.878L39.345 49.872L43.353 46.866C44.567 48.686 44.567 51.058 43.353 52.878Z" fill="#B9ECFB"/>
            <path d="M43.3531 52.8779C43.9951 51.9079 44.3081 50.7569 44.2431 49.5959C43.3921 50.2949 42.5021 50.9459 41.5771 51.5459L43.3531 52.8779Z" fill="#A8E4F1"/>
        </svg>
    );
}