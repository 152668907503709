import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import appStateSlice from "./appStateSlice";
import userReducer from "./userSlice";
import footerSlice from "./footerSlice";


const reduxPersistActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  appState: appStateSlice,
  user: userReducer,
  footer: footerSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // Ignore redux-persist actions serialization error
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: { ignoredActions: reduxPersistActions } }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
