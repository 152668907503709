import { gql } from "@apollo/client";
import { client } from "..";
import api from "../api";

export async function assignClassesToSubject(inputData: IAssignClassesToSubjectInput): Promise<void> {
  try {
    await api.post('/class_subject/assign_classes_to_subject/', inputData);
    console.log("Classes assigned to subject successfully");
  } catch (error) {
    console.error("Error occurred while assigning classes to subject:", error);
    throw error;
  }
}

export async function assignTeacherToClassGQL(assignTeacherToClassInput: IAssignTeacherToClassInput): Promise<String> {
  try {
    const mutation = gql`
      mutation assignTeacher($classId: Int!, $teacherCode: String!!) {
        assignTeacher(classId: $classId, teacherCode: $teacherCode) {
          className
        }
      }
    `;
    const response = await client.mutate({
      mutation,
      variables: {
        classId: assignTeacherToClassInput.class_id,
        teacherCode: assignTeacherToClassInput.teacher_code
      },
    });

    return response.data.className as String;
  } catch (error) {
    console.error('Error occurred while assign teacher to class:', error);
    throw error;
  }
}

export async function assignTeacherToClass(inputData: IAssignTeacherToClassInput): Promise<void> {
  try {
    await api.post('/class/assign_teacher/', inputData);
    console.log("Teacher assigned to class successfully");
  } catch (error) {
    console.error("Error occurred while assigning teacher to class:", error);
    throw error;
  }
}

export async function unassignTeacherFromClass(inputData: IUNAssignTeacherToClassInput): Promise<void> {
  try {
    await api.post('/class/unassign_teacher/', inputData);
    console.log("Teacher unassigned from class successfully");
  } catch (error) {
    console.error("Error occurred while unassigning teacher from class:", error);
    throw error;
  }
}


export async function updateClass(updateClassInput: IUpdateClassInput): Promise<IUpdateClassResponse> {
  try {
    const mutation = gql`
      mutation UpdateClass($updateClassInput: UpdateClassInput!) {
        updateClass(updateClassInput: $updateClassInput) {
          className
          description
          graduationYear
          id
          startYear
          symbol
          teacher {
            code
            email
            name
            role
          }
          teacherCode
          teacherId
        }
      }
    `;

    const response = await client.mutate({
      mutation,
      variables: { updateClassInput },
    });

    return response.data.updateClass as IUpdateClassResponse;
  } catch (error) {
    console.error('Chyba při aktualizaci třídy:', error);
    throw error;
  }
}

export async function deleteClass(deleteClassID: number): Promise<void> {
  try {
    const mutation = gql`
        mutation DeleteClass($classId: Int!) {
          deleteClass(classId: $classId) {
            className
          }
        }
      `;

    const response = await client.mutate({
      mutation,
      variables: { classId: deleteClassID },
    });
    console.log("Class deleted successfully");
    console.log("Response:", response);
  } catch (error) {
    console.error("Error occurred while deleting class:", error);
    throw error;
  }
}

export async function GetAllClasses(): Promise<IUpdateClassResponse[]> {
  try {
    const response = await api.get(`/class/list/`);
    return response.data;
  } catch (error) {
    console.error("Error occurred while fetching user:", error);
    throw error;
  }
};

