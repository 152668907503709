import { Grid, Typography } from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { t } from "../../features/i18n";

type Props = {};

const TableLegend = (props: Props) => {
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
      <Grid item xs={6}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item sx={{ mr: "6px" }}>
            <CircleOutlinedIcon
              sx={{
                fontSize: "10px",
                color: "#FCBB41",
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: "12px",
                color: "#808080",
              }}
            >
              {t("table_legend_class_announcements")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item sx={{ mr: "6px" }}>
            <CircleOutlinedIcon
              sx={{
                fontSize: "10px",
                color: "#008CFB",
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: "12px",
                color: "#808080",
              }}
            >
              {t("table_legend_school-wide_announcements")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item sx={{ mr: "6px" }}>
            <CircleOutlinedIcon
              sx={{
                fontSize: "10px",
                color: "#71BD55",
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: "12px",
                color: "#808080",
              }}
            >
              {t("table_legend_notices_to_subjects")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item sx={{ mr: "6px" }}>
            <CircleOutlinedIcon
              sx={{
                fontSize: "10px",
                color: "red",
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: "12px",
                color: "#808080",
              }}
            >
              {t("table_legend_cancelled")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TableLegend;
