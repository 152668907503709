import React from "react";
import { Outlet } from "react-router-dom";

type Props = {};

const SimpleLayout = (props: Props) => {
  return (
    <>
      <div>Logo</div>
      <Outlet />
    </>
  );
};

export default SimpleLayout;
