import { SvgIcon } from "@mui/material";
import React from "react";

interface IconAttributes {
    className?: string,
}

export const CurriculumIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <SvgIcon width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.7161 21.9965C19.7152 22.6417 19.19 23.167 18.5457 23.167H5.43427C5.12135 23.167 4.82689 23.0451 4.60627 22.8236C4.38474 22.602 4.26289 22.3076 4.26381 21.9937L4.2832 4.55681C4.2832 3.91065 4.80843 3.38635 5.45366 3.38635H6.62043V3.61988C6.62043 4.26604 7.14658 4.79219 7.79273 4.79219H16.2297C16.8758 4.79219 17.402 4.26604 17.402 3.61988V3.38635H18.565C18.878 3.38635 19.1715 3.50819 19.393 3.72973C19.6146 3.95127 19.7355 4.24573 19.7355 4.55865L19.7161 21.9965ZM8.0272 1.97958H15.9961V3.38542H8.0272V1.97958ZM20.3872 2.73558C19.8998 2.24819 19.2537 1.97958 18.565 1.97958H17.402V1.74512C17.402 1.09896 16.8758 0.57373 16.2297 0.57373H7.79273C7.14658 0.57373 6.62043 1.09896 6.62043 1.74512V1.97958H5.45366C4.03489 1.97958 2.88012 3.13527 2.87827 4.55496L2.85889 21.9928C2.85797 22.6814 3.12566 23.3294 3.61212 23.8168C4.09858 24.3051 4.74566 24.5737 5.43427 24.5737H18.5457C19.9644 24.5737 21.1192 23.418 21.121 21.9983L21.1404 4.5605C21.1414 3.87188 20.8737 3.22296 20.3872 2.73558Z"/>
            <path d="M12.239 9.94686H17.2347C17.6224 9.94686 17.9381 9.63209 17.9381 9.24348C17.9381 8.85578 17.6224 8.54102 17.2347 8.54102H12.239C11.8504 8.54102 11.5356 8.85578 11.5356 9.24348C11.5356 9.63209 11.8504 9.94686 12.239 9.94686Z"/>
            <path d="M12.239 14.6368H17.2347C17.6224 14.6368 17.9381 14.322 17.9381 13.9334C17.9381 13.5457 17.6224 13.231 17.2347 13.231H12.239C11.8504 13.231 11.5356 13.5457 11.5356 13.9334C11.5356 14.322 11.8504 14.6368 12.239 14.6368Z"/>
            <path d="M17.2541 17.915H12.2381C11.8504 17.915 11.5356 18.2298 11.5356 18.6184C11.5356 19.0061 11.8504 19.3209 12.2381 19.3209H17.2541C17.6427 19.3209 17.9575 19.0061 17.9575 18.6184C17.9575 18.2298 17.6427 17.915 17.2541 17.915Z"/>
            <path d="M9.24576 7.32573L7.62392 8.94758L7.24176 8.5645C6.96761 8.29035 6.52176 8.29035 6.24761 8.5645C5.97345 8.83958 5.97345 9.2845 6.24761 9.55958L7.1273 10.4383C7.2593 10.5703 7.43745 10.6451 7.62392 10.6451C7.81038 10.6451 7.98945 10.5703 8.12145 10.4383L10.2399 8.31989C10.515 8.04573 10.515 7.59989 10.2399 7.32573C9.96576 7.05158 9.52084 7.05158 9.24576 7.32573Z"/>
            <path d="M9.24576 12.3758L7.62392 13.9976L7.24176 13.6146C6.96761 13.3404 6.52176 13.3404 6.24761 13.6146C5.97345 13.8896 5.97345 14.3346 6.24761 14.6096L7.1273 15.4884C7.2593 15.6204 7.43745 15.6952 7.62392 15.6952C7.81038 15.6952 7.98945 15.6204 8.12145 15.4884L10.2399 13.3699C10.515 13.0958 10.515 12.6499 10.2399 12.3758C9.96576 12.1016 9.52084 12.1016 9.24576 12.3758Z"/>
            <path d="M9.24576 17.0557L7.62392 18.6776L7.24176 18.2945C6.96761 18.0203 6.52176 18.0203 6.24761 18.2945C5.97345 18.5696 5.97345 19.0145 6.24761 19.2896L7.1273 20.1683C7.2593 20.3003 7.43745 20.3751 7.62392 20.3751C7.81038 20.3751 7.98945 20.3003 8.12145 20.1683L10.2399 18.0499C10.515 17.7757 10.515 17.3299 10.2399 17.0557C9.96576 16.7816 9.52084 16.7816 9.24576 17.0557Z"/>
        </SvgIcon>
    );
}