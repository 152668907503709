import { SvgIcon } from "@mui/material";
import React from "react";

interface IconAttributes {
    className?: string,
}

export const ClassIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <SvgIcon width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.438 9.96661C18.438 10.7936 17.765 11.4676 16.937 11.4676C16.11 11.4676 15.437 10.7946 15.437 9.96661C15.437 9.13961 16.11 8.46561 16.937 8.46561C17.765 8.46561 18.438 9.13961 18.438 9.96661ZM5.56398 9.96661C5.56398 9.13961 6.23698 8.46561 7.06498 8.46561C7.89198 8.46561 8.56498 9.13961 8.56498 9.96661C8.56498 10.7946 7.89198 11.4676 7.06498 11.4676C6.23698 11.4676 5.56398 10.7946 5.56398 9.96661ZM18.322 16.5786C17.011 18.8496 14.648 20.2056 12.001 20.2056C9.35398 20.2056 6.99098 18.8496 5.67998 16.5786C5.47298 16.2196 5.59498 15.7616 5.95398 15.5546C6.31298 15.3466 6.77198 15.4696 6.97898 15.8286C8.01898 17.6296 9.89598 18.7056 12.001 18.7056C14.106 18.7056 15.983 17.6296 17.023 15.8286C17.23 15.4696 17.689 15.3466 18.048 15.5546C18.407 15.7616 18.529 16.2196 18.322 16.5786ZM12.001 23.4226C6.21098 23.4226 1.50098 18.7126 1.50098 12.9226C1.50098 7.13261 6.21098 2.42261 12.001 2.42261C17.791 2.42261 22.501 7.13261 22.501 12.9226C22.501 18.7126 17.791 23.4226 12.001 23.4226ZM12.001 0.922607C5.38398 0.922607 0.000976562 6.30561 0.000976562 12.9226C0.000976562 19.5396 5.38398 24.9226 12.001 24.9226C18.618 24.9226 24.001 19.5396 24.001 12.9226C24.001 6.30561 18.618 0.922607 12.001 0.922607Z"/>
        </SvgIcon>
    );
}