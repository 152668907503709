import React, { useEffect, useState } from "react";
import Router from "./features/routes/routes";
import { RootState } from "./features/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setAppState } from "./features/redux/appStateSlice";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { GetSystemInfo } from "./apiRequest/footer";
import { setFooter } from "./features/redux/footerSlice";
import { FooterState } from "./features/authentication/types/user";
import { SessionExpirationProvider } from "./features/authentication/SessionExpirationProvider";
import { getGlobalConfigs } from "./configs/globalConfigs";

function App() {
  const [loadedFooter, setLoadedFooter] = useState(false);
  const authenticated = useSelector((state: RootState) => state.user.authenticated);
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let path = location.pathname;
    if (!authenticated && path !== "/login" && path !== "/login/recovery") {
      path = `/login`;
      navigate(path);
    }
    dispatch(setAppState(path));
  });

  useEffect(() => {
    async function loadData(): Promise<void> {
      const globalConfig = getGlobalConfigs();

      try {
        const systemInfoResponse = await GetSystemInfo();
        const footerData: FooterState = {
          version: systemInfoResponse.version,
          commit: systemInfoResponse.commit,
          branch: systemInfoResponse.branch,
          appName: globalConfig.appName,
          schoolName: globalConfig.schoolName,
          schoolEmail: globalConfig.schoolEmail,
          schoolWebsite: globalConfig.schoolWebsite,
        };
        // TODO after we will have data uncomment
        // const footerData: FooterState = {...systemInfoResponse}
        dispatch(setFooter(footerData));
        setLoadedFooter(true);
      } catch (error) {
        console.log(error);
      }
    }
    if (!loadedFooter) loadData();
  }, [dispatch, loadedFooter]);

  return (
    <ThemeProvider theme={theme}>
      <SessionExpirationProvider>
        <Router />
      </SessionExpirationProvider>
    </ThemeProvider>
  );
}

export default App;
