import { Box, Grid } from "@mui/material";
import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import SubjectItem from "../components/SubjectItem";
import { RootState } from "../features/redux/store";
import AddSubjectItem from "../components/AddSubjectItem";
import { queries } from "../features/graphql/queries";
import { useQuery } from "@apollo/client";
import CreateSubjectModal from "../components/modals/CreateSubjectModal";


interface ISubjects {}

const Subjects: React.FC<ISubjects> = () => {
  const role = useSelector((state: RootState) => state.user.role);
  const [SubData, setSubData] = useState<ISubjectsForTeacher[]>([]);
  const [open, setOpen] = React.useState(false);

  const teacherQuery = queries.GET_SUBJECTS_FOR_TEACHER;
  const studentQuery = queries.GET_SUBJECTS_FOR_STUDENTS;

  const { loading, error, data, refetch } = useQuery<ISubjectsForTeacherResponse>(role === 'TEACHER' ? teacherQuery : studentQuery);

  async function getSubjectForTeacher() {
    refetch();
  }
  async function reloadSubjectForTeacher() {
    await getSubjectForTeacher();

    const SubDataResponse: ISubjectsForTeacher[] = [];
    if (data){
      if (role === 'TEACHER') {
        data.subjectsForTeacher?.map((value: ISubjectsForTeacher) => {
          console.log('classs: ', value);
          SubDataResponse.push(value)
        })
      } else {
        data.subjectsForStudent?.map((value: ISubjectsForTeacher) => {
          console.log('classs: ', value);
          SubDataResponse.push(value)
        })
      }
      setSubData(SubDataResponse);
    }
  }

  useEffect(() => {
    reloadSubjectForTeacher()
  }, [data]);

  return (
    <Box>
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="left" spacing={4}>
            {SubData.map((value) => (
              <Grid key={value.id} item>
                <SubjectItem
                  {...value}
                />
              </Grid>
            ))}
            {role === "TEACHER" || role === "ADMIN"? (
              <Grid item>
                <AddSubjectItem
                  onOpen={() => {
                    setOpen(true);
                  }}
                />
              </Grid>
            ) : (
              <div></div>
            )}
          </Grid>
        </Grid>
      </Grid>
      <CreateSubjectModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        resetSubjects={refetch}
      />
    </Box>
  );
};

export default Subjects;
