import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CardContent,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { useSelector } from "react-redux";
import useStyles from "../configs/styles";
import { t } from "../features/i18n";
import { RootState } from "../features/redux/store";
import GetIcon from "./GetIcon";
import { DownloadIcon } from "./icons/menu/DownloadIcon";
import UploadFileModal from "./modals/UploadFileModal";
import ApproveModal from "./modals/ApproveModal";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DocumentIcon } from "./icons/menu/DocumentIcon";
import { DeleteDocument, DeleteExpectedDocument, DownloadDocuments, GetDocumentList, GetDocumentListExp, GetExpectedDocumentList } from "../apiRequest/documents";
import getFormattedDate, { convertDocumentIcon } from "../features/GlobalFunction/globalFunction";
import CreateExpectedDocumentModal from "./modals/CreateExpectedDocumentModal";
import UploadFileIcon from '@mui/icons-material/UploadFile';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface IDocumentsList {
  resource_id: number;
  resource_type: string;
  studentAssignment_id?: number;
  color?: string;
  title?: string | "Dokumenty";
}

const DocumentsList: React.FC<IDocumentsList> = ({
  resource_id,
  resource_type,
  color,
  title,
  studentAssignment_id,
}) => {
  const classes = useStyles({});
  const role = useSelector((state: RootState) => state.user.role);
  const [addFileOpen, setAddFileOpen] = useState(false);
  const [addExpectedDocumentOpen, setAddExpectedDocumentOpen] = useState(false);
  const [addStudentExpectedDocumentOpen, setAddStudentExpectedDocumentOpen] = useState(false);

  const [approveModal, setApproveModal] = useState(false);
  const [documentsData, setDocumentsData] = useState<IDocumentsTableData[]>();
  const [documentData, setDocumentData] = useState<IDocumentsTableData>();
  const [expecetedDocumentsData, setExpecetedDocumentsData] = useState<IExpectedDocumentsTableData[]>();
  const apiCalled = useRef(false);

  function findExpeceted(expected: IDocumentsTableData, uploaded: IDocumentsTableExpData[]): IExpectedDocumentsTableData {
    const exist = uploaded.some(item => item.expected_document_id === expected.id);
    let file_id: number = expected.id
    let file_name: string = expected.name
    uploaded.map((item) => {
      if (item.expected_document_id === expected.id) {
        file_id = item.id
        file_name = item.name
      }
    })
    return {
      id: file_id,
      created_at: expected.created_at,
      name: file_name,
      icon: expected.icon,
      exist: exist
    };
  }

  async function ExpectedDocumentForStudentLogic() {
    try {
      setDocumentsData(await GetExpectedDocumentList(resource_id.toString()));
      if (studentAssignment_id) {
        let expected: IDocumentsTableData[] = await GetExpectedDocumentList(resource_id.toString());
        let uploaded: IDocumentsTableExpData[] = await GetDocumentListExp(studentAssignment_id.toString(), "student_assignment")
        let uploaded2: IExpectedDocumentsTableData[] = expected.map(item => findExpeceted(item, uploaded));
        console.log("findExpeceted:", uploaded2)
        setExpecetedDocumentsData(uploaded2)
      }


      console.log("get list document successfully");
    } catch (error) {
      console.error("Error occurred while get list document:", error);
    }
  }

  async function GetDocuments() {
    if (resource_type === "expected_document") {
      ExpectedDocumentForStudentLogic();
    } else {
      try {
        setDocumentsData(await GetDocumentList(resource_id.toString(), resource_type));
        console.log("get list document successfully");
      } catch (error) {
        console.error("Error occurred while get list document:", error);
      }
    }
  }

  const handleOpenAddModal = async () => {
    if (resource_type === "expected_document") {
      setAddExpectedDocumentOpen(true)
    } else {
      setAddFileOpen(true)
    }
  };

  const handleAddStudentExpectedDocument = (doc: IDocumentsTableData) => {
    if (doc) {
      setDocumentData(doc);
      setAddStudentExpectedDocumentOpen(true);
    }
  };

  const handleSetDeleteDocument = (doc: IDocumentsTableData) => {
    if (doc) {
      setDocumentData(doc);
      setApproveModal(true);
    }
  };

  const handleDeleteDocument = async () => {
    if (documentData) {
      if (resource_type === "expected_document") {
        try {
          await DeleteExpectedDocument(documentData.id);
          GetDocuments();
          console.log("delete expected document successfully");
        } catch (error) {
          console.error("Error occurred while delete expected document:", error);
        }
      } else {
        try {
          await DeleteDocument(documentData.id);
          GetDocuments();
          console.log("delete document successfully");
        } catch (error) {
          console.error("Error occurred while delete document:", error);
        }
      }


    }
  };

  useEffect(() => {
    async function loadData(): Promise<void> {
      GetDocuments();
    }
    if (!apiCalled.current) {
      loadData();
      apiCalled.current = true;
    }
  }, []);

  return (
    <>
      <Box className={classes.tableCardContent}>
        <CardContent
          sx={{
            paddingTop: "20px",
            paddingLeft: "0px",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              color: color,
            }}
          >
            <Grid item sx={{ mr: "20px" }}>
              <DocumentIcon className={classes.icon} />
            </Grid>
            <Grid item>
              <Typography
                className={classes.tableTitle}
              >
                {title ? title : t("documents")}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        {role === "TEACHER" ? (
          <Button
            variant="outlined"
            className={classes.tableButton}
            onClick={handleOpenAddModal}
            sx={{
              color: color,
              borderColor: color,
              "&:hover": {
                color: "white",
                backgroundColor: color,
              },
            }}
          >
            {t("add_document")}
          </Button>
        ) : (
          <div></div>
        )}



      </Box>
      {resource_type === "expected_document" && studentAssignment_id ? (
        <TableContainer
          sx={{
            paddingBottom: "20px",
          }}
        >
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ width: "140px" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#808080",
                    }}
                  >
                    {t("date")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell style={{ width: "auto" }} align="left">
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#808080",
                    }}
                  >
                    {t("name")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell style={{ width: "200px" }} align="left">
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#808080",
                    }}
                  >
                    {t("file_type")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expecetedDocumentsData?.map((row, index) => (

                <StyledTableRow key={index}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ width: "140px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {getFormattedDate(row.created_at)}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "auto" }} align="left">
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "#4D4D4D",
                        // fontWeight: "bold",
                      }}
                    >
                      {row.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "200px" }} align="left">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item sx={{ mr: "20px" }}>
                        <GetIcon iconName={convertDocumentIcon(row.icon)} />
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={
                            {
                              // fontSize: "24px",
                            }
                          }
                        >
                          {row.icon}
                        </Typography>
                      </Grid>
                    </Grid>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.exist ? (
                      <> <IconButton
                        sx={{
                          height: "11px",
                          padding: "0px",
                        }}
                        onClick={() => DownloadDocuments(row)}
                      >
                        <DownloadIcon />
                      </IconButton></>
                    ) : (
                      <IconButton
                        sx={{
                          height: "11px",
                          padding: "0px",
                        }}
                        onClick={() => handleAddStudentExpectedDocument(row)}
                      >
                        <UploadFileIcon />
                      </IconButton>

                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (


        <TableContainer
          sx={{
            paddingBottom: "20px",
          }}
        >
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ width: "140px" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#808080",
                    }}
                  >
                    {t("date")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell style={{ width: "auto" }} align="left">
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#808080",
                    }}
                  >
                    {t("name")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell style={{ width: "200px" }} align="left">
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#808080",
                    }}
                  >
                    {t("file_type")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentsData?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ width: "140px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {getFormattedDate(row.created_at)}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "auto" }} align="left">
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "#4D4D4D",
                        // fontWeight: "bold",
                      }}
                    >
                      {row.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "200px" }} align="left">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item sx={{ mr: "20px" }}>
                        <GetIcon iconName={convertDocumentIcon(row.icon)} />
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={
                            {
                              // fontSize: "24px",
                            }
                          }
                        >
                          {row.icon}
                        </Typography>
                      </Grid>
                    </Grid>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.icon === "none" ? (
                      <></>
                    ) : (
                      <>
                        {role === "TEACHER" ? (
                          <IconButton
                            sx={{
                              height: "11px",
                              padding: "0px",
                              mr: "5px",
                            }}
                            onClick={() => handleSetDeleteDocument(row)}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        ) : (
                          <div></div>
                        )}

                        {resource_type === "expected_document" && role === "STUDENT" ? (
                          <IconButton
                            sx={{
                              height: "11px",
                              padding: "0px",
                            }}
                            onClick={() => handleAddStudentExpectedDocument(row)}
                          >
                            <UploadFileIcon />
                          </IconButton>

                        ) : (<>
                          {resource_type === "expected_document" ? (<></>) : (
                            <IconButton
                              sx={{
                                height: "11px",
                                padding: "0px",
                              }}
                              onClick={() => DownloadDocuments(row)}
                            >
                              <DownloadIcon />
                            </IconButton>)}</>

                        )}
                      </>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      )}

      <UploadFileModal
        resource_id={resource_id}
        open={addFileOpen}
        color={color}
        resource_type={resource_type}
        onClose={() => setAddFileOpen(false)}
        handleReload={GetDocuments} />
      {studentAssignment_id ? (
        <UploadFileModal
          resource_id={studentAssignment_id}
          expected_document_id={documentData?.id}
          open={addStudentExpectedDocumentOpen}
          color={color}
          resource_type="student_assignment"
          onClose={() => setAddStudentExpectedDocumentOpen(false)}
          handleReload={GetDocuments} />
      ) : (null)}
      <CreateExpectedDocumentModal
        open={addExpectedDocumentOpen}
        onClose={() => setAddExpectedDocumentOpen(false)}
        resetTable={GetDocuments}
        assignment_id={resource_id}
      />
      <ApproveModal
        message={t("file_really_want_delete")}
        open={approveModal}
        color={color}
        Approve={() => {
          handleDeleteDocument();
          setApproveModal(false);
        }}
        Denied={() => setApproveModal(false)}
      />
    </>
  );
};

export default DocumentsList;