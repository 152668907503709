import { SvgIcon } from "@mui/material";
import React from "react";

interface IconAttributes {
    className?: string,
}

export const SingOutIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <SvgIcon width="29" height="33" viewBox="0 0 29 33"xmlns="http://www.w3.org/2000/svg">
            <path d="M6.96084 5.96647C0.0608449 9.94147 -2.13215 19.4705 2.30685 26.1955C8.00385 34.8305 20.5808 34.9035 26.3618 26.3365C30.9088 19.5985 28.7518 9.97247 21.7978 5.96647C20.4498 5.19047 19.1148 5.88747 19.1838 7.33247C19.2198 8.09047 19.3738 8.29647 20.4188 8.98647C29.8188 15.1935 25.1898 29.8575 13.9678 29.4275C3.15084 29.0135 -0.717155 14.9665 8.33984 8.98647C9.38384 8.29647 9.53785 8.09047 9.57485 7.33247C9.64385 5.88747 8.30885 5.19047 6.96084 5.96647ZM13.9128 0.777474C13.5168 0.904474 13.1778 1.17247 12.9748 1.51847L12.7698 1.86747V18.7845L12.9568 19.1185C13.5708 20.2135 15.1868 20.2135 15.8018 19.1185L15.9888 18.7845V1.86747L15.7808 1.51447C15.4278 0.913474 14.5568 0.569474 13.9128 0.777474Z"/>
        </SvgIcon>
    );
}