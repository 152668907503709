import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SubjectIcon } from "../icons/menu/SubjectIcon";
import { t } from "../../features/i18n";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { z } from "zod";
import MyTextField from "./MyTextField";
import MyIconField from "./MyIconField";
import { updateSubject } from "../../apiRequest/subject";
import MessageComponent from "../MessageComponent";
import GetIcon from "../GetIcon";

const schema = z.object({
  subjectName: z
    .string()
    .min(1, { message: t("name_subject_min_one_letter") })
    .max(25, { message: t("name_subject_too_long") }),
});

type FormData = z.infer<typeof schema>;

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: "8px solid #71BD55",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  root: {
    flexDirection: "column",
    display: "flex",
    width: "20%",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#d4e9cc",
    },
    backgroundColor: "#D9D9D9",
    textDecoration: "none",
    color: "inherit",
  },
  iconTable: {
    flexDirection: "column",
    display: "flex",
    width: "20%",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#d4e9cc",
    },
    backgroundColor: "#D9D9D9",
    textDecoration: "none",
    color: "inherit",
  },
}));

const iconTable: IIconName[] = [
  { id: 1, name: "Matematika" },
  { id: 2, name: "Informatika" },
  { id: 3, name: "Prirodni vedy" },
  { id: 4, name: "Fyzika" },
  { id: 5, name: "Chemie" },
  { id: 6, name: "Cesky jazyk" },
  { id: 7, name: "Anglicky jazyk" },
  { id: 8, name: "Historie" },
  { id: 9, name: "Zdravoveda" },
];

interface IUpdateSubjectModal {
  open: boolean;
  onClose: () => void;
  resetSubjects: () => void;
  subjectID: number;
  subjectName: string;
  subjectIcon: string;
};

const UpdateSubjectModal: React.FC<IUpdateSubjectModal> = ({ open, onClose, resetSubjects, subjectID, subjectName, subjectIcon }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [currentSubjectName, setCurrentSubjectName] = useState("");

  const [selectedIcons, setSelectedIcons] = useState<string>("");
  const classes = useStyles();

  const handleIconClick = (iconName: string) => {
    setSelectedIcons(iconName)
  };

  const handleSubjectNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSubjectName(event.target.value);
  };

  const onSubmit = async (data: FormData) => {
    try {
      console.log('Validation passed:', data);
      setLoading(true)
      await updateSubject({
        id: subjectID,
        name: currentSubjectName,
        icon: selectedIcons,
        description: ""
      })
     
      setTimeout(() => {
        setSuccessAlertOpen(true);
        setTimeout(() => {
          resetSubjects();
          onClose();
        }, 2000);
      }, 2000);
    } catch (error) {
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }
  };

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
      setCurrentSubjectName(subjectName)
      setSelectedIcons(subjectIcon)
    }
  }, [open]);


  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {successAlertOpen ? (
          <MessageComponent
            errorChange={errorAlertOpen}
            errorMessage={t("update_subject_error")}
            successMessage={t("update_subject_success")}
          />
        ) : (
          <Box>
            <Box
              sx={{
                maxHeight: "50px",
                alignText: "left",
                color: '#71BD55',
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item sx={{ mr: "20px" }}>
                  <SubjectIcon />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "24px",
                    }}
                  >
                    {t("add_subject")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField sx={{ mb: 2, mt: 2 }}
                  {...register('subjectName')}
                  required
                  label={t("subjectName")}
                  value={currentSubjectName}
                  onChange={handleSubjectNameChange}
                  error={!!errors.subjectName}
                  helperText={errors.subjectName?.message}
                  fullWidth
                />
                <Box sx={{ mt: "50px ", background: "#D9D9D9", height: "200px" }}>
                  <Box sx={{ margin: "10px" }}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#4D4D4D",
                      }}>
                      {t("select_subject_icon")}
                    </Typography>
                  </Box>
                  <Box sx={{ margin: "10px" }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      {iconTable.map((icon) => (
                        <Box
                          key={icon.id}
                          className={classes.iconTable}
                          style={{
                            background: selectedIcons?.includes(icon.name) ? "#d4e9cc" : "none",
                          }}
                        >
                          <Grid
                            item
                            onClick={() => {
                              handleIconClick(icon.name);
                            }}
                          >
                            <GetIcon iconName={icon.name} />
                          </Grid>
                        </Box>
                      ))}
                    </Grid>
                  </Box>
                </Box>
                <Box sx={{ mt: "25px " }}>
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{
                      width: 140,
                      height: 40,
                      color: "#71BD55",
                      textTransform: "none",
                      fontWeight: "bold",
                      borderColor: "#71BD55",
                    }}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    sx={{
                      width: 140,
                      height: 40,
                      bgcolor: "#71BD55",
                      "&:hover": {
                        backgroundColor: "#71BD55",
                      },
                      fontWeight: "bold",
                      position: "absolute",
                      right: "40px",
                    }}
                  >
                    {loading ? t("processing") : t("confirm")}

                  </Button>
                </Box>
            </form>
          </Box>
        )}
      </Paper>
    </Modal>
  );
};

export default UpdateSubjectModal;
