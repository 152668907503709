import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { t } from "../features/i18n";
import { CalendarIcon } from "../components/icons/menu/CalendarIcon";
import SectionItem from "../components/SectionItem";
import api from "../api";
import { useNavigate, useParams } from "react-router-dom";
import UpdateEventModal from "../components/modals/UpdateEventModal";

const color = "#0058B4";
interface ICalendarDetail { }

const CalendarDetail: React.FC<ICalendarDetail> = () => {
  const { eventID } = useParams();
  const [openUpdateFileModal, setOpenUpdateFileModal] = React.useState(false);
  const [CalendarDetailIsEmpty, setCalendarDetailIsEmpty] = useState(false);
  const [responseData, setResponseData] = useState<IEventResponseData>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [openUpdateEventModal, setOpenUpdateEventModal] = React.useState(false);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleGetEvent = async () => {
    if (eventID)
      await GetEvent(eventID);
  };

  async function GetEvent(id: string) {
    try {
      api.get('/events/?event_id=' + id)
        .then((response) => {
          console.log("response.data");
          console.log(response.data);
          const data = response.data;
          let eventsTableRowsData: IEventResponseData
          if (data.length === 0) {
            console.log("response.data are empty");
            eventsTableRowsData = ({
              id: 3,
              created_at: "-.-.----",
              updated_at: "-.-.----",
              created_by: "",
              updated_by: null,
              resource_id: 0,
              resource_type: "",
              title: "----",
              content: t("curriculum_is_not_there"),
              icon: "",
              start: "-.-.----",
              end: "-.-.----"
            });
            console.log("data.id:", data.id);
            setResponseData(eventsTableRowsData);

          } else {
            setResponseData(data);
            setCalendarDetailIsEmpty(true)
          }
        })
        .catch((error) => {
          console.error(error);
          setResponseError(true)
        });
    } catch (error) {
      console.error(error);
      setResponseError(true)
    }
  }

  useEffect(() => {
    async function loadData(): Promise<void> {
      if (eventID)
        await GetEvent(eventID);
    }
    loadData();
  }, []);

  return (
    <Box>
      <Box display="flex" alignItems="center" sx={{ mb: "15px " }}>
        <Typography variant="h4" sx={{ color: color, mb: 5 }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item sx={{ mr: "36px" }}>
              <CalendarIcon />
            </Grid>
            <Grid item>{t("event")}</Grid>
            <Button
          variant="outlined"
          onClick={handleGoBack}
          sx={{
            width: 184,
            height: 40,
            color: "#72b855",
            textTransform: 'none',
            fontWeight: 'bold',
            borderColor: "#72b855",
            position: 'absolute',
            right: 16
          }}
        >
          {t("back")}
        </Button>
          </Grid>
        </Typography>
      </Box>
      <Typography variant="h4" sx={{ mb: 5 }}>
        {!responseData ? (
          <>
            {t("section_not_there")}
          </>
        ) : (
          <>
            <Box sx={{ mb: "20px" }}>
              <SectionItem
                resource_id={responseData.id}
                resource_type="event"
                title={responseData.title}
                content={responseData.content}
                modalButton={() => setOpenUpdateEventModal(true)}
                buttonName={t("edit_event")}
                color={color} />
            </Box>
          </>
        )}
      </Typography>
      <UpdateEventModal
        open={openUpdateEventModal}
        resetTable={handleGetEvent}
        EventData={responseData}
        onClose={() => {
          setOpenUpdateEventModal(false);
        }} />
    </Box>
  );
};

export default CalendarDetail;
