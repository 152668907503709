import { Box, Grid } from "@mui/material";
import CurriculumTable from "../components/tables/CurriculumTable";
import { t } from "../features/i18n";
import React from "react";
import UsersTable from "../components/tables/UsersTable";

interface IUsersManagement { }

const UsersManagement: React.FC<IUsersManagement> = () => {

  return (
    <Box>
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="left" spacing={4}>
            <Grid item sm={12}>
              <UsersTable color={"#faba42"} title={t("table_of_users")} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UsersManagement;
