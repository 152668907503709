import React from "react";
import { Card, Grid, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { t } from "../features/i18n";
import useStyles from "../configs/styles";

export interface IVirtualReality { }

const VirtualReality: React.FC<IVirtualReality> = (props) => {
  console.log("VirtualReality");
  const classes = useStyles();
  const location = useLocation();
  return (
    <div>
      <Typography variant="h4" sx={{ mb: 5 }}>
        {t("name_virtual_reality")}
      </Typography>
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="left" spacing={4}>

            <Grid item>
              <Card
                className={classes.classCard}
                elevation={3}
                component={Link}
                to={location.pathname + "/models"}
              >
                <div style={{ width: '60%' }}>
                  <img src="3d.svg" alt="3d view" style={{ width: '100%', height: 'auto', display: 'block', maxWidth: '100%', objectFit: 'contain' }} />
                </div>

              </Card>
            </Grid>

            <Grid item>
              <Card
                className={classes.classCard}
                elevation={3}
                component={Link}
                to={location.pathname + "/panoramas"}
              >
                <div style={{ width: '60%' }}>
                  <img src="360.svg" alt="360-degree view" style={{ width: '100%', height: 'auto', display: 'block', maxWidth: '100%', objectFit: 'contain' }} />
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default VirtualReality;
