import { SvgIcon } from "@mui/material";
import React from "react";

interface IconAttributes {
    className?: string,
}

export const VirtualRealityIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <SvgIcon width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.01415 9.52915C4.46461 9.69115 4.9483 9.42815 5.09876 8.94215C5.89261 6.35815 8.3443 4.93415 11.9997 4.93415C15.6541 4.93415 18.1058 6.35815 18.9006 8.94315C19.0511 9.43115 19.5385 9.69115 19.9852 9.53015C20.4348 9.36715 20.6766 8.84215 20.5271 8.35515C19.4951 5.00115 16.3871 3.07715 11.9997 3.07715C7.61138 3.07715 4.50338 5.00115 3.47138 8.35515C3.32184 8.84215 3.56461 9.36715 4.01415 9.52915Z"/>
            <path d="M12.8573 13.292H11.1432C10.6696 13.292 10.2856 13.708 10.2856 14.221C10.2856 14.733 10.6696 15.149 11.1432 15.149H12.8573C13.3309 15.149 13.7149 14.733 13.7149 14.221C13.7149 13.708 13.3309 13.292 12.8573 13.292Z"/>
            <path d="M22.2854 17.941H21.4278V14.2269H22.2854V17.941ZM19.7137 18.8699C19.7137 19.3819 19.3287 19.799 18.857 19.799H15.4371C14.7097 19.799 14.4152 19.1799 14.0829 18.8859C13.5087 18.2639 12.7546 17.9529 11.9995 17.9529C11.2444 17.9529 10.4903 18.2639 9.91613 18.8859C9.61982 19.1479 9.27089 19.799 8.56197 19.799H5.14197C4.67028 19.799 4.28536 19.3819 4.28536 18.8699V13.299C4.28536 12.787 4.67028 12.3699 5.14197 12.3699H18.857C19.3287 12.3699 19.7137 12.787 19.7137 13.299V18.8699ZM2.5712 17.941H1.71366V14.2269H2.5712V17.941ZM22.2854 12.3699H21.27C20.9155 11.2919 19.973 10.5129 18.857 10.5129H5.14197C4.02597 10.5129 3.08351 11.2919 2.72905 12.3699H1.71366C0.768433 12.3699 -0.000488281 13.2029 -0.000488281 14.2269V17.941C-0.000488281 18.966 0.768433 19.799 1.71366 19.799H2.72905C3.08351 20.877 4.02597 21.6559 5.14197 21.6559H8.56197C10.1358 21.6559 10.8531 20.424 11.1281 20.199C11.5934 19.695 12.4047 19.695 12.87 20.199C13.1284 20.41 13.8771 21.6559 15.4371 21.6559H18.857C19.973 21.6559 20.9155 20.877 21.27 19.799H22.2854C23.2306 19.799 23.9995 18.966 23.9995 17.941V14.2269C23.9995 13.2029 23.2306 12.3699 22.2854 12.3699Z"/>
        </SvgIcon>
    );
}