import { Grid, Typography } from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { t } from "../../features/i18n";
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

type Props = {};

const TaskTableLegend = (props: Props) => {
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
      <Grid item xs={6}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item sx={{ mr: "6px" }}>
            <ErrorOutlineOutlinedIcon sx={{ fontSize: "16px", color: "red" }} />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: "12px",
                color: "#808080",
              }}
            >
              {t("test")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item sx={{ mr: "6px" }}>
            <CottageOutlinedIcon sx={{ fontSize: "16px", color: "#71BD55" }} />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: "12px",
                color: "#808080",
              }}
            >
              {t("homework")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TaskTableLegend;
