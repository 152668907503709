import { SvgIcon } from "@mui/material";
import React from "react";

interface IconAttributes {
    className?: string,
}

export const DashboardIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 6.25C9.5 6.388 9.388 6.5 9.25 6.5H1.75C1.612 6.5 1.5 6.388 1.5 6.25V1.75C1.5 1.612 1.612 1.5 1.75 1.5H9.25C9.388 1.5 9.5 1.612 9.5 1.75V6.25ZM9.25 0H1.75C0.785 0 0 0.785 0 1.75V6.25C0 7.215 0.785 8 1.75 8H9.25C10.215 8 11 7.215 11 6.25V1.75C11 0.785 10.215 0 9.25 0Z"/>
            <path d="M9.5 22.25C9.5 22.388 9.388 22.5 9.25 22.5H1.75C1.612 22.5 1.5 22.388 1.5 22.25V11.75C1.5 11.612 1.612 11.5 1.75 11.5H9.25C9.388 11.5 9.5 11.612 9.5 11.75V22.25ZM9.25 10H1.75C0.785 10 0 10.785 0 11.75V22.25C0 23.215 0.785 24 1.75 24H9.25C10.215 24 11 23.215 11 22.25V11.75C11 10.785 10.215 10 9.25 10Z"/>
            <path d="M22.5 22.25C22.5 22.388 22.388 22.5 22.25 22.5H14.75C14.612 22.5 14.5 22.388 14.5 22.25V17.75C14.5 17.612 14.612 17.5 14.75 17.5H22.25C22.388 17.5 22.5 17.612 22.5 17.75V22.25ZM22.25 16H14.75C13.785 16 13 16.785 13 17.75V22.25C13 23.215 13.785 24 14.75 24H22.25C23.215 24 24 23.215 24 22.25V17.75C24 16.785 23.215 16 22.25 16Z"/>
            <path d="M22.5 12.25C22.5 12.388 22.388 12.5 22.25 12.5H14.75C14.612 12.5 14.5 12.388 14.5 12.25V1.75C14.5 1.612 14.612 1.5 14.75 1.5H22.25C22.388 1.5 22.5 1.612 22.5 1.75V12.25ZM22.25 0H14.75C13.785 0 13 0.785 13 1.75V12.25C13 13.215 13.785 14 14.75 14H22.25C23.215 14 24 13.215 24 12.25V1.75C24 0.785 23.215 0 22.25 0Z"/>
        </SvgIcon>
    );
}