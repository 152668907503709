import { NavBarItems } from "../../features/authentication/types/user";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { DashboardIcon } from "../icons/menu/DashboardIcon";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { t } from "../../features/i18n";

const adminItems: NavBarItems[] = [
  {
    index: true,
    path: "/overview",
    state: "overview",
    color: "#4785c3",
    selectedColor: "#E0F1FE",
    sidebarProps: {
      displayText: t("overview"),
      icon: <DashboardIcon />,
    },
  },
  {
    path: "/userManagement",
    state: "userManagement",
    color: "#faba42",
    selectedColor: "#FFF0D4",
    sidebarProps: {
      displayText: t("user_managment"),
      icon: <PeopleOutlineIcon />,
    },
  },
  {
    path: "/hint",
    state: "hint",
    color: "#a0a0a0",
    selectedColor: "#EFEFEF",
    sidebarProps: {
      displayText: t("name_hint"),
      icon: <HelpOutlineOutlinedIcon />,
    },
  },
];

export default adminItems;
