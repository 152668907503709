import React from "react";
import { TextField, Button, Typography } from "@mui/material";
import { t } from "../features/i18n";
import { Link } from "react-router-dom";

interface LoginFormProps {
  handleLogin: (userCode: string, password: string) => void;
  userNonExist: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({ userNonExist, handleLogin }) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const userCode = formData.get("userCode") as string;
    const password = formData.get("password") as string;
    handleLogin(userCode, password);
  };
  const btnstyle = { margin: "8px 0" };
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        name="userCode"
        label={t("user_code")}
        placeholder={t("enter_user_code")}
        variant="filled"
        fullWidth
        required
        sx={{
          mt: "80px",
        }}
      />
      <TextField
        name="password"
        label={t("password")}
        placeholder={t("enter_password")}
        type="password"
        variant="filled"
        fullWidth
        required
        sx={{
          mt: "20px",
          mb: "30px",
        }}
      />
      {userNonExist === true ? (
        <>
          <Typography
            sx={{
              color: "red",
            }}
          >
            {t("message_wrong_password")}
          </Typography>
        </>
      ) : (
        <div></div>
      )}
      <Typography>
        {t("message_forgot_password")}
        <Link to={"/login/recovery"}>{t("message_generate_new")}</Link>
      </Typography>

      <Button
        type="submit"
        color="primary"
        variant="contained"
        style={btnstyle}
        fullWidth
        sx={{
          bgcolor: "#f18733",
          fontWeight: "bold",
          mt: "30px",
          "&:hover": {
            backgroundColor: "#f18733",
          },
        }}
      >
        {t("login")}
      </Button>
    </form>
  );
};

export default LoginForm;
