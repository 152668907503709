import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ImageView from "./ImageView";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ImageButton from "./ImageButton";
import { t } from "../../features/i18n";
import PannellumViewer from "./PannellumViewer";

interface TSPanoramaViewerProps {
  images: GroupedImages;
}

const PanoramaViewer: React.FC<TSPanoramaViewerProps> = ({ images }) => {
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [loadStatus, setLoadStatus] = useState(0);
  const [scale, setScale] = useState(1);
  const [selectedImageGroup, setSelectedImageGroup] = useState<number>(0);
  const [selectedImageId, setSelectedImageId] = useState<number>(0);

  const imageViewRef = useRef<any>(null);
  const apiCalled = useRef(false);

  async function loadData(): Promise<void> {
    console.log("PanoramaViewer useEffect");

    setLoadStatus(0);
    handleResetImage();
    setImagesLoaded(false);
    fetchData();
    await renderImages();

    // console.log(`currentImageUrl ${currentImageUrl} `);
    // console.log(`currentImageIndex ${currentImageIndex} `);
    // console.log(`imagesLoaded ${imagesLoaded} `);
    // console.log(`loadStatus ${loadStatus} `);
    // console.log(images);
    // console.log("selectedImage");
    // const selectedImages = images[selectedImageId];
    // console.log(selectedImages);
  }
  useEffect(() => {
    if (!apiCalled.current) {
      loadData();
      apiCalled.current = true;
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [selectedImageGroup, selectedImageId]);

  const imagesRef = useRef<HTMLImageElement[]>([]);

  let imageData: any[] = [];
  let a: number = 0;


  const fetchData = () => {
    console.log(`fetchData inside images :`);
    imageData = [];
    console.log(selectedImageGroup);
    try {
      images[selectedImageGroup].map(image => {
        imageData.push(image.path)
      })
      console.log(imageData);
    } catch (error) {
      console.log(error)
    }
  };

  const renderImages = async (): Promise<void> => {
    await loadImages();
    const currentImage = imagesRef.current[selectedImageId];
    console.log("imagesRef");
    console.log(imagesRef);
    try {
      setCurrentImageUrl(currentImage.src);
    } catch (error) {
      console.log(error)
    }
    setImagesLoaded(true);
  };

  const loadImage = async (path: string): Promise<HTMLImageElement> => {
    const amount = images[selectedImageGroup].map(image => image.amount)
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {

        resolve(img);
        a++;
        setLoadStatus(Math.round((a * 100) / amount[1]));
      };
      img.onerror = reject;
      img.src = path;
    });
  };

  const loadImages = async (): Promise<void> => {
    a = 0;
    console.log("imageData");
    console.log(imageData);
    const images = await Promise.all(imageData.map((path) => loadImage(path)));
    console.log("images");
    console.log(images);
    images.sort((a, b) => {
      const aIndex = imageData.indexOf(a.src);
      const bIndex = imageData.indexOf(b.src);
      return aIndex - bIndex;
    });
    imagesRef.current = images;
  };

  const handlePreviousPhoto = () => {
    if (1 <= selectedImageId) {
      setSelectedImageId(selectedImageId - 1);
    } else {
      setSelectedImageId(images[selectedImageGroup].length - 1);
    }
  };

  const handleNextPhoto = () => {
    if (images[selectedImageGroup].length - 1 <= selectedImageId) {
      setSelectedImageId(0);
    } else {
      setSelectedImageId(selectedImageId + 1);
    }
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    if (scale > 0.1) {
      setScale(scale - 0.1);
    }
  };

  const handleNewModel = async (imgNum: number) => {
    console.log("set setSelectedGroup", imgNum)
    await setSelectedImageGroup(imgNum);
    setSelectedImageId(0);
  };

  const handleResetImage = () => {
    setScale(1);
    if (imageViewRef.current) {
      imageViewRef.current.handleReset();
    }
  };
  const imageUrl = 'bratislava1.jpg'; 
  return (
    <div>
      <Box
        sx={{
          m: "10px",
          width: "800px",
        }}
      >
        {/* <ImageView
          ref={imageViewRef}
          src={currentImageUrl}
          scale={scale}
          isLoad={imagesLoaded}
          percent={loadStatus}
        /> */}
          <PannellumViewer imageUrl={currentImageUrl} />
        <Grid
          container
          spacing={2}
          sx={{
            mt: "10px",
          }}
        >
          <Grid item xs={12 / 5}>
            <Button
              variant="text"
              sx={{
                width: 140,
                height: 40,
                color: "black",
                bgcolor: "white",
                fontWeight: "bold",
              }}
              startIcon={<KeyboardArrowLeftIcon />}
              onClick={() => handlePreviousPhoto()}
            >
              {t("previous_photo")}
            </Button>
          </Grid>

          <Grid item xs={12 / 5}>
            <Button
              variant="text"
              sx={{
                width: 140,
                height: 40,
                color: "black",
                bgcolor: "white",
                fontWeight: "bold",
              }}
              startIcon={<KeyboardArrowRightIcon />}
              onClick={() => handleNextPhoto()}
            >
              {t("next_photo")}
            </Button>
          </Grid>
        </Grid>

        <Typography variant="h5" sx={{ mt: 2 }}>
          {t("pick_model")}
        </Typography>

        <Grid container spacing={2}>
          {Object.keys(images).map(id => (
            <Grid key={id} item xs={3}>

              <ImageButton
                id={parseInt(id)}
                selectImage={handleNewModel}
                selectedNumber={selectedImageGroup}
                description={
                  images[parseInt(id)]?.[0]?.description?.toString() || ""
                }
                src={
                  images[parseInt(id)].map(item => (
                    item.path
                  ))[0].toString()
                }
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default PanoramaViewer;
