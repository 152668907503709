import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  Alert,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { t } from "../../features/i18n";
import { z } from 'zod';
import api from "../../api";
import MessageComponent from "../MessageComponent";

let color: string = "#0058B4";

const schema = z.object({
  title: z.string().min(1, t("name_min_one_symbol")),
  content: z.string().min(1),
  startDate: z.date(),
  endDate: z.date(),
});
type FormData = z.infer<typeof schema>;

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));

interface IUpdateEventModal {
  open: boolean;
  onClose: () => void;
  resetTable: () => void;
  EventData?: IEventResponseData;
};


const UpdateEventModal: React.FC<IUpdateEventModal> = ({ open, onClose, resetTable, EventData }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
  const [loading, setLoading] = useState<boolean>(false);

  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
    }
  }, [open]);

  const handleCancel = () => {
    onClose();
  };

  const onSubmit = async (data: FormData) => {
    console.log(data);
    try {
      if (EventData && data) {
        const { title, content, startDate, endDate } = data;
        const objectUpdateEvent: IEventData = {
          id: EventData?.id,
          title,
          content,
          icon: EventData.icon,
          start: startDate.toString() + '.140000Z',
          end: endDate.toString() + '.140000Z',
        };
        setLoading(true)
        await handleUpdateEvent(objectUpdateEvent)
        setTimeout(() => {
          setSuccessAlertOpen(true);
          setTimeout(() => {
            resetTable();
            onClose();
          }, 2000);
        }, 2000);
      } else {
        throw "chyba pri updatovani";
      }
    } catch (error) {
      console.error('Chyba při odesílání:', error);
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }

  };
  async function handleUpdateEvent(data: IEventData) {
    console.log("handleUpdateEvent:", data);
    return api.put('/events/update/', data)
      .then((response) => {
        console.log("handleUpdateEvent data:", response.data);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {successAlertOpen ? (
          <MessageComponent
            errorChange={errorAlertOpen}
            errorMessage={t("update_event_error")}
            successMessage={t("update_event_success")}
          />
        ) : (

          <Box>
            <Box
              sx={{
                maxHeight: "50px",
                alignText: "left",
                color: color,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "24px",
                    }}
                  >
                    {t("edit_event")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>

              <TextField sx={{ mb: 2, mt: 2 }}
                {...register('title')}
                required
                label={t("event_name")}
                defaultValue={EventData?.title}
                error={!!errors.title}
                helperText={errors.title?.message}
                fullWidth
              />

              <TextField sx={{ mb: 2 }}
                {...register('content')}
                required
                label={t("note")}
                defaultValue={EventData?.content}
                error={!!errors.content}
                helperText={errors.content?.message}
                fullWidth />

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                {t("start")}
              </Typography>

              <TextField sx={{ mb: 2 }}
                {...register('startDate')}
                required
                type="datetime-local"
                // label="Datum a čas začátku"
                defaultValue={EventData?.start.split('.')[0]}
                InputLabelProps={{ shrink: true }}
                error={!!errors.startDate}
                helperText={errors.startDate?.message}
                fullWidth />

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                {t("end")}
              </Typography>

              <TextField sx={{ mb: 2 }}
                {...register('endDate')}
                required
                type="datetime-local"
                defaultValue={EventData?.end.split('.')[0]}
                InputLabelProps={{ shrink: true }}
                error={!!errors.endDate}
                helperText={errors.endDate?.message}
                fullWidth />

              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{
                  width: 140,
                  height: 40,
                  color: color,
                  textTransform: "none",
                  fontWeight: "bold",
                  borderColor: color,
                }}
              >
                {t("cancel")}
              </Button>

              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{
                  width: 140,
                  height: 40,
                  bgcolor: color,
                  "&:hover": {
                    backgroundColor: color,
                  },
                  fontWeight: "bold",
                  position: "absolute",
                  right: "40px",
                }}
              >
                {loading ? t("processing") : t("confirm")}

              </Button>
            </form>
          </Box>
        )}

      </Paper>
    </Modal>
  );
};

export default UpdateEventModal;
