import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useStyles from "../../configs/styles";
import DocumentsList from "../DocumentsList";
import UploadFileModal from "../modals/UploadFileModal";
import { DeleteDocument, DownloadDocuments, GetDocumentListExp, GetExpectedDocumentList } from "../../apiRequest/documents";
import { DocumentIcon } from "../icons/menu/DocumentIcon";
import { t } from "../../features/i18n";
import getFormattedDate, { convertDocumentIcon } from "../../features/GlobalFunction/globalFunction";
import GetIcon from "../GetIcon";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { DownloadIcon } from "../icons/menu/DownloadIcon";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ApproveModal from "../modals/ApproveModal";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface IExpectedDocumentsTable {
  resource_id: number;
  resource_type: string;
  studentAssignment_id?: number;
  color?: string;
  title?: string;
  submited?: boolean;
  uploadDisabled?: boolean;
}

const ExpectedDocumentsTable: React.FC<IExpectedDocumentsTable> = ({
  color,
  resource_id,
  resource_type,
  title,
  studentAssignment_id,
  submited,
  uploadDisabled,
}) => {
  const classes = useStyles({});
  const [addStudentExpectedDocumentOpen, setAddStudentExpectedDocumentOpen] = useState(false);
  const [documentsData, setDocumentsData] = useState<IDocumentsTableData[]>();
  const [documentData, setDocumentData] = useState<IDocumentsTableData>();
  const [expecetedDocumentsData, setExpecetedDocumentsData] = useState<IExpectedDocumentsTableData[]>();
  const apiCalled = useRef(false);
  const [approveModal, setApproveModal] = useState(false);

  function findExpeceted(expected: IDocumentsTableData, uploaded: IDocumentsTableExpData[]): IExpectedDocumentsTableData {
    const exist = uploaded.some(item => item.expected_document_id === expected.id);
    let file_id: number = expected.id
    let file_name: string = expected.name
    uploaded.map((item) => {
      if (item.expected_document_id === expected.id) {
        file_id = item.id
        file_name = item.name
      }
    })
    return {
      id: file_id,
      created_at: expected.created_at,
      name: file_name,
      icon: expected.icon,
      exist: exist
    };
  }

  async function ExpectedDocumentForStudentLogic() {
    try {
      setDocumentsData(await GetExpectedDocumentList(resource_id.toString()));
      if (studentAssignment_id) {
        let expected: IDocumentsTableData[] = await GetExpectedDocumentList(resource_id.toString());
        let uploaded: IDocumentsTableExpData[] = await GetDocumentListExp(studentAssignment_id.toString(), "student_assignment")
        let uploaded2: IExpectedDocumentsTableData[] = expected.map(item => findExpeceted(item, uploaded));
        console.log("findExpeceted:", uploaded2)
        setExpecetedDocumentsData(uploaded2)
      }
      // setExpecetedDocumentsData(fakedata)

      console.log("get list document successfully");
    } catch (error) {
      console.error("Error occurred while get list document:", error);
    }
  }

  const handleAddStudentExpectedDocument = (doc: IDocumentsTableData) => {
    if (doc) {
      setDocumentData(doc);
      setAddStudentExpectedDocumentOpen(true);
    }
  };

  const handleSetDeleteDocument = (doc: IDocumentsTableData) => {
    if (doc) {
      setDocumentData(doc);
      setApproveModal(true);
    }
  };

  const handleDeleteDocument = async () => {
    if (documentData) {
      try {
        await DeleteDocument(documentData.id);
        ExpectedDocumentForStudentLogic();
        console.log("delete document successfully");
      } catch (error) {
        console.error("Error occurred while delete document:", error);
      }
    }
  };

  useEffect(() => {
    async function loadData(): Promise<void> {
      ExpectedDocumentForStudentLogic();
    }
    if (!apiCalled.current) {
      loadData();
      apiCalled.current = true;
    }
  }, []);


  return (
    <Card
      className={classes.tableCard}
      elevation={3}
      style={{ borderTop: "8px solid" + color }}
    >
      <Box className={classes.tableCardContent}>
        <CardContent
          sx={{
            paddingTop: "20px",
            paddingLeft: "0px",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              color: color,
            }}
          >
            <Grid item sx={{ mr: "20px" }}>
              <DocumentIcon className={classes.icon} />
            </Grid>
            <Grid item>
              <Typography
                className={classes.tableTitle}
              >
                {title ? title : t("documents")}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
      <TableContainer
        sx={{
          paddingBottom: "20px",
        }}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: "50px" }}>

              </StyledTableCell>
              <StyledTableCell style={{ width: "140px" }}>
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#808080",
                  }}
                >
                  {t("date")}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ width: "auto" }} align="left">
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#808080",
                  }}
                >
                  {t("name")}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ width: "200px" }} align="left">
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#808080",
                  }}
                >
                  {t("file_type")}
                </Typography>
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expecetedDocumentsData?.map((row, index) => (<>
              {row.exist ? (
                <>
                  <StyledTableRow key={index}>

                    {row.icon === "none" ? (
                      <StyledTableCell style={{ width: "50px" }}></StyledTableCell>
                    ) : (<StyledTableCell style={{ width: "50px" }}><CheckCircleIcon sx={{ color: "#72b855" }} /></StyledTableCell>)}


                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{ width: "140px" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        {getFormattedDate(row.created_at)}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "auto" }} align="left">
                      <Typography
                        sx={{
                          fontSize: "15px",
                          color: "#4D4D4D",
                          // fontWeight: "bold",
                        }}
                      >
                        {row.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "200px" }} align="left">
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid item sx={{ mr: "20px" }}>
                          <GetIcon iconName={convertDocumentIcon(row.icon)} />
                        </Grid>
                        <Grid item>
                          <Typography>
                            {row.icon}
                          </Typography>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    {row.icon === "none" ? (
                      <> <StyledTableCell align="right"></StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell></>
                    ) : (<>
                      <StyledTableCell align="right">
                        <IconButton
                          sx={{
                            height: "11px",
                            padding: "0px",
                          }}
                          onClick={() => DownloadDocuments(row)}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <IconButton
                          sx={{
                            height: "11px",
                            padding: "0px",
                            mr: "5px",
                          }}
                          onClick={() => handleSetDeleteDocument(row)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </StyledTableCell></>
                    )}
                  </StyledTableRow>
                </>
              ) : (
                <>
                  <StyledTableRow key={index}>
                    <StyledTableCell style={{ width: "50px" }}><ErrorOutlineIcon sx={{ color: "#faba42" }} /></StyledTableCell>

                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{ width: "140px" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        {getFormattedDate(row.created_at)}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "auto" }} align="left">
                      <Typography
                        sx={{
                          fontSize: "15px",
                          color: "#4D4D4D",
                          // fontWeight: "bold",
                        }}
                      >
                        {row.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "200px" }} align="left">
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid item sx={{ mr: "20px" }}>
                          <GetIcon iconName={convertDocumentIcon(row.icon)} />
                        </Grid>
                        <Grid item>
                          <Typography>
                            {row.icon}
                          </Typography>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton
                        disabled={uploadDisabled}
                        sx={{
                          height: "11px",
                          padding: "0px",
                        }}
                        onClick={() => handleAddStudentExpectedDocument(row)}
                      >
                        <UploadFileIcon />
                      </IconButton>

                    </StyledTableCell>
                  </StyledTableRow>
                </>
              )}

            </>
            ))}

          </TableBody>
        </Table>
      </TableContainer>


      {studentAssignment_id ? (
        <UploadFileModal
          resource_id={studentAssignment_id}
          expected_document_id={documentData?.id}
          open={addStudentExpectedDocumentOpen}
          color={color}
          resource_type="student_assignment"
          onClose={() => setAddStudentExpectedDocumentOpen(false)}
          handleReload={ExpectedDocumentForStudentLogic} />
      ) : (null)}

      <ApproveModal
        message={t("file_really_want_delete")}
        open={approveModal}
        color={color}
        Approve={() => {
          handleDeleteDocument();
          setApproveModal(false);
        }}
        Denied={() => setApproveModal(false)}
      />
    </Card>
  );
};

export default ExpectedDocumentsTable;
