import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CurriculumIcon } from "../icons/menu/CurriculumIcon";
import useStyles from "../../configs/styles";
import EastIcon from "@mui/icons-material/East";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ApproveModal from "../modals/ApproveModal";
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import CreateUserModal from "../modals/CreateUserModal";
import { GetUser, GetUsersList, deleteUser } from "../../apiRequest/user";
import CreateStudentUserModal from "../modals/CreateStudentUserModal";
import ClassesExpanderTable from "./ClassesExpanderTable";
import UpdateStudentUserModal from "../modals/UpdateStudentUserModal";
import UpdateUserModal from "../modals/UpdateUserModal";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { t } from "../../features/i18n";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const classOverviewMocks: IClassOverview[] = [
  {
    id: 1,
    created_at: "2024-04-23T08:00:00Z",
    updated_at: "2024-04-23T10:30:00Z",
    created_by: "John Doe",
    updated_by: "Jane Doe",
    class_name: "1.A",
  },
  {
    id: 2,
    created_at: "2024-04-24T08:00:00Z",
    updated_at: "2024-04-24T10:30:00Z",
    created_by: "Alice Smith",
    updated_by: "Bob Johnson",
    class_name: "2.B",
  },
  {
    id: 3,
    created_at: "2024-04-25T08:00:00Z",
    updated_at: "2024-04-25T10:30:00Z",
    created_by: "Eva Green",
    updated_by: "Tom Brown",
    class_name: "3.C",
  },
];

interface IUsersTable {
  color: string;
  title: string;
}

const UsersTable: React.FC<IUsersTable> = ({
  color,
  title,
}) => {
  const classes = useStyles({});
  const [approveModal, setApproveModal] = useState(false);
  const [approveUnassignModal, setApproveUnassignModal] = useState(false);
  const [targetRow, setTargetRow] = useState<IUser>();
  const [responseError, setResponseError] = useState(false);
  const [responseData, setResponseData] = useState<IUser[]>([]);
  const [openUpdateUserModal, setOpenUpdateUserModal] = useState(false);
  const [openUpdateStudentUserModal, setOpenUpdateStudentUserModal] = useState(false);
  const [UpdateUserData, setUpdateUserData] = useState<IUpdateUserInput>();
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openCreateStudentUserModal, setOpenCreateStudentUserModal] = useState(false);

  const [filtr, setFiltr] = React.useState('');



  const handleChange = (event: SelectChangeEvent) => {
    setFiltr(event.target.value as string);
  };

  const [isLoaded, setIsLoaded] = useState(false);



  async function GetAllUsers() {
    try {

      setResponseData(await GetUsersList(filtr))
      setResponseData(prevData => [...prevData].sort((a, b) => a.name.localeCompare(b.name)));
    } catch (error) {

    }
  }
  const handleUpdateUser = (doc: IUser) => {
    if (doc) {
      setUpdateUserData({
        code:doc.code,
        email:doc.email,
        name:doc.name,
        
      });
      setOpenUpdateUserModal(true);
    }
  };

  const handleUpdateStudentUser = (doc: IUser) => {
    if (doc) {
      setUpdateUserData({
        code:doc.code,
        email:doc.email,
        name:doc.name,
        class_id:doc.student?.class_id,
        class_name:doc.student?.class_name,
      });
      setOpenUpdateStudentUserModal(true);
    }
  };


  const handleDeleteUser = (doc: IUser) => {
    if (doc) {
      setTargetRow(doc);
      setApproveModal(true);
    }
  };

  async function AprovedDeleteUser() {
    try {
      if (targetRow)
        deleteUser(targetRow);
    } catch (error) {

    }
  }
  useEffect(() => {
    GetAllUsers();
  }, [filtr]);

  useEffect(() => {
    async function loadData(): Promise<void> {
      GetAllUsers();
    }
    loadData();
  }, []);

  return (
    <Card
      className={classes.tableCard}
      elevation={3}
      style={{ borderTop: "8px solid" + color }}
    >
      <Box className={classes.tableCardContent}>
        <CardContent
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "0px",
          }}
        >

          <Typography
            className={classes.tableTitle}
            sx={{
              color: color,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item sx={{ mr: "20px" }}>
                <CurriculumIcon />
              </Grid>
              <Grid item>{title}</Grid>
            </Grid>
          </Typography>
        </CardContent>
        <Box sx={{
        }}>
          <Button
            variant="outlined"
            className={classes.tableButton}
            sx={{
              color: color,
              borderColor: color,
              "&:hover": {
                color: "white",
                backgroundColor: color,
              },
              mr: 4
            }}
            onClick={() => setOpenCreateUserModal(true)}
          >
            {t("add_user")}
          </Button>
          <Button
            variant="outlined"
            className={classes.tableButton}
            sx={{
              color: color,
              borderColor: color,
              "&:hover": {
                color: "white",
                backgroundColor: color,
              },
            }}
            onClick={() => setOpenCreateStudentUserModal(true)}
          >
            {t("add_student")}
          </Button>

        </Box>
      </Box>
      <Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Filtr</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filtr}
            label="filtr"
            onChange={handleChange}
          >
            <MenuItem value="">{t("all_role")}</MenuItem>
            <MenuItem value="student">{t("student")}</MenuItem>
            <MenuItem value="teacher">{t("teacher_no")}</MenuItem>
            <MenuItem value="admin">{t("admin")}</MenuItem>
            <MenuItem value="super_teacher">{t("super_teacher")}</MenuItem>
          </Select>
        </FormControl>

      </Typography>
      <TableContainer
        sx={{
          paddingBottom: "20px",
        }}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: "200px" }}>
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#808080",
                  }}
                >
                  {t("user_name")}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ width: "200px" }}>
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#808080",
                  }}
                >
                  {t("email")}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ width: "100px" }} align="left">
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#808080",
                  }}
                >
                  {t("rights")}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ width: "200px" }} align="left">

              </StyledTableCell>

              <StyledTableCell
                style={{ width: "30px" }}
                align="right"
              ></StyledTableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {responseData?.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell style={{ width: "200px" }}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "#4D4D4D",
                    }}
                  >
                    {row.name}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell style={{ width: "200px" }}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "#4D4D4D",
                    }}
                  >
                    {row.email}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell style={{ width: "100px" }} align="left">
                  <Typography
                    sx={{
                      fontSize: "15px",
                    }}
                  >
                    {row.role}
                  </Typography>
                </StyledTableCell>
                {row.role == "student" ? (
                  <>
                    <StyledTableCell style={{ width: "70px" }} align="left">
                      {row.student?.class_name}
                    </StyledTableCell>
                  </>
                ) : (
                  <>
                    <StyledTableCell style={{ width: "200px" }} align="left">
                      <ClassesExpanderTable
                        color={color}
                        classInfo={row.teacher?.classes_overview}
                        resetTable={GetAllUsers}
                        teacherCode={row.teacher?.user_code}
                      />
                      {/* <ClassesExpanderTable color={color} classInfo={classOverviewMocks} /> */}
                    </StyledTableCell>
                  </>
                )}

                <StyledTableCell style={{ width: "30px" }} align="right">
                </StyledTableCell>
                <StyledTableCell style={{ width: "30px" }} align="right">
                  {row.role == "student" ? (
                    <>
                      <IconButton
                        onClick={() => handleUpdateStudentUser(row)}
                        sx={{
                          height: "11px",
                          padding: "0px",
                          color: color,
                        }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        onClick={() => handleUpdateUser(row)}
                        sx={{
                          height: "11px",
                          padding: "0px",
                          color: color,
                        }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </>
                  )}

                </StyledTableCell>
                <StyledTableCell style={{ width: "30px" }} align="right">
                  <IconButton
                    onClick={() => handleDeleteUser(row)}
                    sx={{
                      height: "11px",
                      padding: "0px",
                      color: color,
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ApproveModal
        message={t("really_want_delete_user")}
        open={approveModal}
        Approve={() => {
          AprovedDeleteUser();
          setApproveModal(false);
        }}
        Denied={() => setApproveModal(false)}
      />
      <CreateUserModal
        open={openCreateUserModal}
        resetTable={GetAllUsers}
        onClose={() => {
          setOpenCreateUserModal(false);
        }} />

      <CreateStudentUserModal
        open={openCreateStudentUserModal}
        resetTable={GetAllUsers}
        onClose={() => {
          setOpenCreateStudentUserModal(false);
        }} />
      <UpdateUserModal
        open={openUpdateUserModal}
        user={UpdateUserData}
        resetTable={GetAllUsers}
        onClose={() => {
          setOpenUpdateUserModal(false);
        }} />

      <UpdateStudentUserModal
        open={openUpdateStudentUserModal}
        user={UpdateUserData}
        resetTable={GetAllUsers}
        onClose={() => {
          setOpenUpdateStudentUserModal(false);
        }} />
    </Card>
  );
};

export default UsersTable;
