import { AxiosError } from 'axios';
import api from '../api'


export async function GetUser(UserCode: string): Promise<IUser> {
  try {
    const response = await api.get(`/user/?code=${UserCode}`);
    return response.data;
  } catch (error) {
    console.error("Error occurred while fetching user:", error);
    throw error;
  }
};

export async function GetUserValidation(UserCode: string): Promise<boolean> {
  try {
    const response = await api.get(`/user/?code=${UserCode}`);
    // Pokud je uživatel nalezen, vracíme true
    return true;
  } catch (error: any) {
    if ((error as AxiosError).response?.status === 404) {
      return false;
    }
    console.error("Error occurred while fetching user:", error);
    throw error;
  }
};

export async function GetUsersList(role?: string): Promise<IUser[]> {
  try {
    const response = await api.get(`/user/list/${role ? `?role=${role}` : ''}`);
    return response.data;
  } catch (error) {
    console.error("Error occurred while fetching users list:", error);
    throw error;
  }
};

export async function CreateUser(userData: ICreateUserInput): Promise<IUser> {
  try {
    const response = await api.post('/user/create/', userData);
    return response.data;
  } catch (error) {
    console.error("Error occurred while creating user:", error);
    throw error;
  }
};

export async function updateUser(userData: IUpdateUserInput): Promise<IUser> {
  try {
    const response = await api.put('/user/update/', userData);
    return response.data;
  } catch (error) {
    console.error("Error occurred while updating user:", error);
    throw error;
  }
};

export async function deleteUser(user: IUser): Promise<void> {
  try {
    await api.delete(`/user/delete/?code=${user.code}`);
    console.log("User deleted successfully");
  } catch (error) {
    console.error("Error occurred while deleting user:", error);
    throw error;
  }
};

export const setUserRole = async (code: string, role: string): Promise<IUser> => {
  try {
    const response = await api.post<IUser>('/user/set_role/', null, {
      params: {
        code,
        role,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred while setting user role:", error);
    throw error;
  }
};










