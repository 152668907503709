// import React from "react";
// import { SvgIcon } from '@mui/material';
// interface IconAttributes {
//     className?: string,
// }

// export const PDFIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
//     return (
//         <SvgIcon width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path d="M17.25 22.9399C17.25 23.4909 16.801 23.9399 16.25 23.9399H2.25C1.699 23.9399 1.25 23.4909 1.25 22.9399V2.93994C1.25 2.38894 1.699 1.93994 2.25 1.93994H12.25V4.93994C12.25 6.04294 13.147 6.93994 14.25 6.93994H17.25V22.9399ZM13.25 2.64694L16.543 5.93994H14.25C13.699 5.93994 13.25 5.49094 13.25 4.93994V2.64694ZM18.104 6.08594L13.104 1.08594C13.01 0.992941 12.883 0.939941 12.75 0.939941H2.25C1.147 0.939941 0.25 1.83694 0.25 2.93994V22.9399C0.25 24.0429 1.147 24.9399 2.25 24.9399H16.25C17.353 24.9399 18.25 24.0429 18.25 22.9399V6.43994C18.25 6.30694 18.197 6.17994 18.104 6.08594Z"/>
//             <path d="M14.25 16.6219C14.25 16.8469 14.25 16.9399 13.786 16.9429C13.65 16.9139 13.335 16.7279 12.946 16.4629C13.087 16.4479 13.191 16.4399 13.25 16.4399C13.989 16.4399 14.198 16.5119 14.25 16.6219ZM8.633 16.3619C8.665 16.2749 8.695 16.1869 8.724 16.0979C8.91 15.5409 9.077 15.0409 9.226 14.5909C9.433 14.8199 9.657 15.0539 9.897 15.2929C9.99 15.3869 10.223 15.5979 10.532 15.8619C9.916 15.9969 9.26 16.1629 8.633 16.3619ZM8.25 10.9879C8.25 9.93994 8.575 9.93994 8.75 9.93994C9.104 9.93994 9.25 9.93994 9.25 10.9399C9.25 11.1509 9.109 11.6779 8.852 12.5009C8.459 11.8969 8.25 11.3789 8.25 10.9879ZM4.75 19.9399C4.464 19.9399 4.271 19.8049 4.25 19.7789C4.25 19.4249 5.307 18.9239 6.33 18.4389C6.395 18.4089 6.46 18.3779 6.527 18.3459C5.776 19.4349 5.034 19.9399 4.75 19.9399ZM11.799 15.6179C11.336 15.2539 10.896 14.8789 10.604 14.5859C10.223 14.2049 9.883 13.8359 9.588 13.4849C10.048 12.0629 10.25 11.3299 10.25 10.9399C10.25 9.27994 9.65 8.93994 8.75 8.93994C8.066 8.93994 7.25 9.29494 7.25 10.9879C7.25 11.7339 7.659 12.6399 8.469 13.6919C8.271 14.2969 8.038 14.9949 7.776 15.7819C7.65 16.1589 7.513 16.5089 7.369 16.8319C7.251 16.8839 7.137 16.9369 7.026 16.9929C6.629 17.1909 6.251 17.3699 5.901 17.5359C4.305 18.2919 3.25 18.7919 3.25 19.7789C3.25 20.4959 4.029 20.9399 4.75 20.9399C5.68 20.9399 7.083 19.6979 8.109 17.6059C9.173 17.1859 10.497 16.8749 11.541 16.6809C12.378 17.3239 13.302 17.9399 13.75 17.9399C14.99 17.9399 15.25 17.2229 15.25 16.6219C15.25 15.4399 13.899 15.4399 13.25 15.4399C13.048 15.4399 12.507 15.4989 11.799 15.6179Z"/>
//         </SvgIcon>
//     );
// }

import * as React from "react";
import { SvgIcon as MuiSvgIcon, SvgIconProps, styled } from "@mui/material";

const SvgIcon = styled(MuiSvgIcon, {
  name: "PDFIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})<SvgIconProps>(() => ({
    // xmlns="http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
//   strokeWidth: "2.25px",
}));

SvgIcon.defaultProps = {
  viewBox: "0 0 24 24",
  focusable: "false",
//   "aria-hidden": "true",
};

const PDFIcon: React.FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      {/* <path d="M15,19.16V15.07a4.27,4.27,0,0,0,6,0h0a4.27,4.27,0,0,0,0-6h0a4.27,4.27,0,0,0-6,0l-3,3-3,3a4.27,4.27,0,0,1-6,0h0a4.27,4.27,0,0,1,0-6h0A4.27,4.27,0,0,1,9,9" /> */}
      <path d="M17.25 22.9399C17.25 23.4909 16.801 23.9399 16.25 23.9399H2.25C1.699 23.9399 1.25 23.4909 1.25 22.9399V2.93994C1.25 2.38894 1.699 1.93994 2.25 1.93994H12.25V4.93994C12.25 6.04294 13.147 6.93994 14.25 6.93994H17.25V22.9399ZM13.25 2.64694L16.543 5.93994H14.25C13.699 5.93994 13.25 5.49094 13.25 4.93994V2.64694ZM18.104 6.08594L13.104 1.08594C13.01 0.992941 12.883 0.939941 12.75 0.939941H2.25C1.147 0.939941 0.25 1.83694 0.25 2.93994V22.9399C0.25 24.0429 1.147 24.9399 2.25 24.9399H16.25C17.353 24.9399 18.25 24.0429 18.25 22.9399V6.43994C18.25 6.30694 18.197 6.17994 18.104 6.08594Z" />
      <path d="M14.25 16.6219C14.25 16.8469 14.25 16.9399 13.786 16.9429C13.65 16.9139 13.335 16.7279 12.946 16.4629C13.087 16.4479 13.191 16.4399 13.25 16.4399C13.989 16.4399 14.198 16.5119 14.25 16.6219ZM8.633 16.3619C8.665 16.2749 8.695 16.1869 8.724 16.0979C8.91 15.5409 9.077 15.0409 9.226 14.5909C9.433 14.8199 9.657 15.0539 9.897 15.2929C9.99 15.3869 10.223 15.5979 10.532 15.8619C9.916 15.9969 9.26 16.1629 8.633 16.3619ZM8.25 10.9879C8.25 9.93994 8.575 9.93994 8.75 9.93994C9.104 9.93994 9.25 9.93994 9.25 10.9399C9.25 11.1509 9.109 11.6779 8.852 12.5009C8.459 11.8969 8.25 11.3789 8.25 10.9879ZM4.75 19.9399C4.464 19.9399 4.271 19.8049 4.25 19.7789C4.25 19.4249 5.307 18.9239 6.33 18.4389C6.395 18.4089 6.46 18.3779 6.527 18.3459C5.776 19.4349 5.034 19.9399 4.75 19.9399ZM11.799 15.6179C11.336 15.2539 10.896 14.8789 10.604 14.5859C10.223 14.2049 9.883 13.8359 9.588 13.4849C10.048 12.0629 10.25 11.3299 10.25 10.9399C10.25 9.27994 9.65 8.93994 8.75 8.93994C8.066 8.93994 7.25 9.29494 7.25 10.9879C7.25 11.7339 7.659 12.6399 8.469 13.6919C8.271 14.2969 8.038 14.9949 7.776 15.7819C7.65 16.1589 7.513 16.5089 7.369 16.8319C7.251 16.8839 7.137 16.9369 7.026 16.9929C6.629 17.1909 6.251 17.3699 5.901 17.5359C4.305 18.2919 3.25 18.7919 3.25 19.7789C3.25 20.4959 4.029 20.9399 4.75 20.9399C5.68 20.9399 7.083 19.6979 8.109 17.6059C9.173 17.1859 10.497 16.8749 11.541 16.6809C12.378 17.3239 13.302 17.9399 13.75 17.9399C14.99 17.9399 15.25 17.2229 15.25 16.6219C15.25 15.4399 13.899 15.4399 13.25 15.4399C13.048 15.4399 12.507 15.4989 11.799 15.6179Z" />
    </SvgIcon>
  );
};

export default PDFIcon;
// import React from 'react';

// interface PDFIconProps {
//   sx?: any; // SX parametry přijaté jako props
// }

// const PDFIcon: React.FC<PDFIconProps> = ({ sx }) => {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 24 24"
//       width="24"
//       height="24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//       style={sx} // Použijte SX parametry pro stylování SVG ikony
//     >
//       <circle cx="12" cy="12" r="10" />
//       <line x1="12" y1="8" x2="12" y2="16" />
//       <line x1="8" y1="12" x2="16" y2="12" />
//     </svg>
//   );
// };

// export default PDFIcon;


// import React from 'react';
// import { styled } from '@mui/material/styles';
// import Box, { BoxProps } from '@mui/material/Box'; // Importujte Box komponentu z @mui/system

// // Definice SX parametrů pro vlastní ikonu
// interface MyCustomIconProps extends BoxProps {
//   size?: number;
// }

// // Vytvořte styled komponentu pro SVG ikonu, která bude podporovat SX parametry
// const StyledIcon = styled(Box)<MyCustomIconProps>({
//   // Definujte styly pro vaši ikonu
//   // Například, zde můžete nastavit společné styly pro vaši ikonu
//   // Pokud chcete, aby se sx parametry aplikovaly jen na vnější box, použijte { sx: {} } místo {}
//   display: 'inline-block',
//   verticalAlign: 'middle',
// });

// const PDFIcon: React.FC<MyCustomIconProps> = ({ size = 24, ...props }) => {
//   return (
//     // Použijte StyledIcon komponentu a předejte props a SX parametry dále
//     <StyledIcon {...props} sx={{ ...props.sx, width: size, height: size }}>
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 24 24"
//         fill="none"
//         stroke="currentColor"
//         strokeWidth="2"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       >
//         <circle cx="12" cy="12" r="10" />
//         <line x1="12" y1="8" x2="12" y2="16" />
//         <line x1="8" y1="12" x2="16" y2="12" />
//       </svg>
//     </StyledIcon>
//   );
// };

// export default PDFIcon;