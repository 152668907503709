import React from "react";
import { Box, Card } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../features/redux/store";
import GetIcon from "./GetIcon";
import useStyles from "../configs/styles";

const SubjectItem: React.FC<ISubjectsForTeacher> = ({ id, name,icon,className }) => {
  const classes = useStyles({});
  const location = useLocation();
  const role = useSelector((state: RootState) => state.user.role);
  return (
    <Card
      className={classes.classCard}
      elevation={3}
      component={Link}
      to={location.pathname + "/" + id + "/" + name}
    >
      <GetIcon iconName={icon} />
      <Box component="span" fontSize={"16px"}>
        {name}
      </Box>
      {role === "TEACHER" ? (
        <Box component="span" fontSize={"16px"}>
          {className}
        </Box>
      ) : (
        <div></div>
      )}
    </Card>
  );
}

export default SubjectItem;
