import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Outlet } from "react-router-dom";
import { t } from "../i18n";
import { styled } from "@mui/material";

export type IRole = "ADMIN" | "SUPER_TEACHER" | "TEACHER" | "STUDENT";

interface IAuthGuardProps {
  allowedRoles: IRole[];
}

const StyledError = styled("div")(({ theme }) => ({
    fontSize: "large",
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
  }));

const AuthGuard: React.FC<IAuthGuardProps> = ({ allowedRoles }) => {
  const user = useSelector((state: RootState) => state.user);

  if (user && user.authenticated && user.role && allowedRoles.includes(user.role as IRole)) {
    return <Outlet />;
  }

  return <StyledError>{t("user_not_allowed")} 😔⛔</StyledError>;
};

export default AuthGuard;
