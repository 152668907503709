import { Box, Typography } from "@mui/material";
import React from "react";
import { t } from "../features/i18n";
import { getGlobalConfigs } from "../configs/globalConfigs";
import { useSelector } from "react-redux";
import { RootState } from "../features/redux/store";

const AdminTools: React.FC = () => {
  const [openAddClassModal, setOpenAddClassModal] = React.useState(false);
  const globalConfigs = getGlobalConfigs();

  const branch = useSelector((state: RootState) => state.footer.branch);
  const commit = useSelector((state: RootState) => state.footer.commit);
  const version = useSelector((state: RootState) => state.footer.version);

  let appName = `${globalConfigs.appName} [${process.env.REACT_APP_ENV_NAME}]`;

  const handleAddClass = () => {
    setOpenAddClassModal(true);
  };
  return (
    <Box>
      {t("web_app_version")}
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: "bold",
        }}
      >
        {process.env.REACT_APP_VERSION}
      </Typography>
      <br />
      {t("api_version")}
      <table>
        <tbody>
          <tr>
            <td>{t("version")}:</td>
            <td>{version}</td>
          </tr>
          <tr>
            <td>{t("commit")}:</td>
            <td>{commit}</td>
          </tr>
          <tr>
            <td>{t("branch")}:</td>
            <td>{branch}</td>
          </tr>
        </tbody>
      </table>
      <br />
      {t("current_env")}
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: "bold",
        }}
      >
        {appName}
      </Typography>
      {/* <Button onClick={handleAddClass}>CreateClass</Button>
      <CreateNewClassModal
        open={openAddClassModal}
        onClose={() => {
          setOpenAddClassModal(false);
        } } resetTable={function (): void {
          throw new Error("Function not implemented.");
        } }      /> */}
    </Box>
  );
};

export default AdminTools;
