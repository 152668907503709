import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../features/redux/store";
import { logout } from "../../features/redux/userSlice";
import { LogoIcon } from "../icons/LogoIcon";
import { SingOutIcon } from "../icons/menu/SingOutIcon";
import AvatarMenu from "./AvatarMenu";

export interface ILogOutProps {}

const NavigationBar: React.FC<ILogOutProps> = (props) => {
  const userName = useSelector((state: RootState) => state.user.userName);
  const role = useSelector((state: RootState) => state.user.role);
  const dispatch = useDispatch();

  // const client = useApolloClient();

  // const handleDataReset = () => {F
  //   client.resetStore();
  // };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          bgcolor: "#302c2c",
        }}
      >
        <Toolbar>
          <LogoIcon />
          <Typography align="left" variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>

          <AvatarMenu />

          <Button
            color="inherit"
            onClick={() => {
              dispatch(logout());
            }}
          >
            <SingOutIcon />
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavigationBar;
