import React from "react";
import { SvgIcon } from '@mui/material';
interface IconAttributes {
    className?: string,
}

export const WordIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <SvgIcon width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.96969 9.15282V9.15382H4.82769L5.53969 6.54882C5.63369 6.22082 5.71069 5.92782 5.77669 5.64082C5.83269 5.92882 5.90069 6.22282 5.97969 6.54382L6.61169 9.15382H7.46969L8.91069 4.06982H7.99269L7.33069 6.60082L7.30169 6.71382C7.22369 7.02482 7.14769 7.32782 7.07969 7.62282C7.01369 7.29182 6.93469 6.94882 6.85569 6.61582L6.24569 4.06982H5.38569L4.71569 6.59882C4.62169 6.95282 4.52669 7.31482 4.44769 7.65582C4.37769 7.32282 4.29669 6.96382 4.21269 6.60882L3.61669 4.06982H2.67969L3.96969 9.15282Z"/>
            <path d="M16.6498 23.7039H1.85481C1.72481 23.7039 1.61881 23.5979 1.61881 23.4679V2.41194C1.61881 2.28194 1.72481 2.17594 1.85481 2.17594H13.0448V5.75594C13.0448 6.09694 13.3218 6.37294 13.6628 6.37294H16.8858V23.4679C16.8858 23.5979 16.7798 23.7039 16.6498 23.7039ZM14.2808 3.14194L16.1168 5.13694H14.2808V3.14194ZM13.9338 0.939941H1.85481C1.04381 0.939941 0.382812 1.60094 0.382812 2.41194V23.4679C0.382812 24.2799 1.04381 24.9399 1.85481 24.9399H16.6508C17.4618 24.9399 18.1218 24.2799 18.1218 23.4679V5.49094L13.9338 0.939941Z"/>
            <path d="M9.62023 8.999H15.1512C15.4072 8.999 15.6142 8.792 15.6142 8.536C15.6142 8.28 15.4072 8.073 15.1512 8.073H9.62023C9.36423 8.073 9.15723 8.28 9.15723 8.536C9.15723 8.792 9.36423 8.999 9.62023 8.999Z"/>
            <path d="M15.1498 19.4219H3.6378C3.3828 19.4219 3.1748 19.6289 3.1748 19.8849C3.1748 20.1409 3.3828 20.3479 3.6378 20.3479H15.1498C15.4058 20.3479 15.6128 20.1409 15.6128 19.8849C15.6128 19.6289 15.4058 19.4219 15.1498 19.4219Z"/>
            <path d="M15.1498 11.165H3.6378C3.3828 11.165 3.1748 11.372 3.1748 11.628C3.1748 11.884 3.3828 12.091 3.6378 12.091H15.1498C15.4058 12.091 15.6128 11.884 15.6128 11.628C15.6128 11.372 15.4058 11.165 15.1498 11.165Z"/>
            <path d="M15.1498 13.9048H3.6378C3.3828 13.9048 3.1748 14.1118 3.1748 14.3678C3.1748 14.6238 3.3828 14.8308 3.6378 14.8308H15.1498C15.4058 14.8308 15.6128 14.6238 15.6128 14.3678C15.6128 14.1118 15.4058 13.9048 15.1498 13.9048Z"/>
            <path d="M15.1498 16.644H3.6378C3.3828 16.644 3.1748 16.851 3.1748 17.107C3.1748 17.363 3.3828 17.57 3.6378 17.57H15.1498C15.4058 17.57 15.6128 17.363 15.6128 17.107C15.6128 16.851 15.4058 16.644 15.1498 16.644Z"/>
        </SvgIcon>
    );
}