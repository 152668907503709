import { Button } from "@mui/material";

import GetIcon from "./GetIcon";

type Props = {
  id: number;
  selectedButton: number;
  name: string;
  label: string;
  color: string;
  submit: (value: number) => void;
};

const SubjectButton = (props: Props) => {
  return (
    <>
      {props.id === props.selectedButton ? (
        <Button
          key={props.id}
          variant="contained"
          sx={{
            width: 184,
            height: 40,
            bgcolor: props.color,
            fontWeight: "bold",
            mr: "16px ",
            '&:hover': {
                backgroundColor: props.color,
              },
          }}
          startIcon={<GetIcon iconName={props.name} />}
          onClick={() => props.submit(props.id)}
        >
          {props.label}
        </Button>
      ) : (
        <Button
          key={props.id}
          variant="text"
          sx={{
            width: 184,
            height: 40,
            color: "black",
            bgcolor: "white",
            fontWeight: "bold",
            mr: "16px ",
          }}
          startIcon={<GetIcon iconName={props.name} />}
          onClick={() => props.submit(props.id)}
        >
          {props.label}
        </Button>
      )}
    </>
  );
};

export default SubjectButton;
