import React, { useEffect, useState } from "react";

type Props = {
  id: number;
  src: string;
  selectedNumber: number;
  description?: string;
  selectImage: (value: number) => void;
};

const ImageButton: React.FC<Props> = ({
  id,
  src,
  selectImage,
  selectedNumber,
  description
}) => {
  const [imageSize, setImageSize] = useState({ width: "auto", height: "auto" });

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const { naturalWidth, naturalHeight } = img;
      if (naturalWidth > naturalHeight) {
        setImageSize({ width: "100%", height: "auto" });
      } else {
        setImageSize({ width: "auto", height: "100%" });
      }
    };
  }, [src]);

  const containerStyle: React.CSSProperties = {
    width: "150px",
    height: "150px",
    border: `5px solid ${selectedNumber === id ? "red" : "black"}`,
    borderRadius: "10px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: 'relative', // Přidáme relaticní pozici pro umístění popisku
  };

  const imageStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  };

  const descriptionStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: '0',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    width: '100%',
    padding: '5px',
  };

  return (
    <div style={containerStyle}>
      <img
        src={src}
        alt={`Obrázek + ${id}`}
        style={imageStyle}
        onClick={() => selectImage(id)}
      />
      <div style={descriptionStyle}>{description}</div> {/* Přidáme popisek pod tlačítko s fotkou */}
    </div>
  );
};

export default ImageButton;
