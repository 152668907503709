import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FooterState } from "../authentication/types/user";

const initialState: FooterState = {
  version: null,
  commit: null,
  branch: null,
  appName: null,
  schoolName: null,
  schoolEmail: null,
  schoolWebsite: null,
};

export const footerSlice = createSlice({
  name: "footerState",
  initialState,
  reducers: {
    setFooter: (state, action: PayloadAction<FooterState>) => {
      const { version, commit, branch, schoolName, schoolEmail, schoolWebsite, appName } = action.payload;
      return { ...state, version, commit, branch, schoolName, schoolEmail, schoolWebsite, appName };
    },
  },
});

export const { setFooter } = footerSlice.actions;

export default footerSlice.reducer;
