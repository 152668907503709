export function secondsToMinutesAndSeconds(seconds: number, locale = "cs"): string {
  const rtf = new Intl.RelativeTimeFormat(locale, { numeric: "always", style: "narrow" });

  // Convert seconds to minutes and remaining seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Construct the human-readable string
  const parts = [];
  if (minutes > 0) {
    parts.push(rtf.format(minutes, "minute"));
  }
  if (remainingSeconds > 0) {
    parts.push(rtf.format(remainingSeconds, "second").match(/\d+ \w+/)); // remove string "za" from the beginning
  }

  return parts.join(" ");
}
