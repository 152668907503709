import { gql } from "@apollo/client";
export const queries = {
    GET_CLASS_STUDENTS: gql`
  query GetStudents {
    classStudents(id: 10) {
      classId
      id
      user {
        code
        email
        name
        role
      }
    }
  }
`,
GET_CLASSES: gql`
query GetClasses {
  classes {
    className
    description
    graduationYear
    id
    startYear
    symbol
    teacher {
      code
      email
      name
      role
    }
    teacherCode
    teacherId
  }
}
`,
    GET_STUDENT_CLASS: gql`
  query GetStudentClass {
    studentClass {
      className
      description
      graduationYear
      id
      startYear
      symbol
      teacherCode
      teacherId
      teacher {
        code
        email
        name
        role
      }
    }
  }
`,
    GET_TEACHER: gql`
  query GetTeacher {
    teacher(code: "") {
      classes {
        className
        description
        graduationYear
        id
        startYear
        symbol
        teacher {
          code
          email
          name
          role
        }
        teacherId
        teacherCode
      }
      id
      user {
        code
        email
        name
        role
      }
    }
  }
`,
    GET_TEACHER_CLASS: gql`
  query GetTeacherClass {
    teacherClass(id: 10) {
      className
      description
      graduationYear
      id
      startYear
      symbol
      teacher {
        code
        email
        name
        role
      }
      teacherCode
      teacherId
    }
  }
`,
    GET_USER: gql`
  query GetUser {
    user(code: "") {
      code
      email
      name
      role
    }
  }
`,
    GET_USERS: gql`
  query GetUsers {
    users {
      code
      email
      name
      role
    }
  }
`,
  GET_SUBJECTS_FOR_STUDENTS: gql`
  query GetSubjectsForStudent {
    subjectsForStudent {
      description
      icon
      name
      id
    }
  }
`,
GET_SUBJECTS_FOR_TEACHER: gql`
  query GetSubjectsForTeacher {
    subjectsForTeacher {
      description
      icon
      id
      name
    }
  }
`,
};

