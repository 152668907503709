import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

import { useSelector } from "react-redux";
import useStyles from "../configs/styles";
import { t } from "../features/i18n";
import { RootState } from "../features/redux/store";

import { CurriculumIcon } from "./icons/menu/CurriculumIcon";
import DocumentsList from "./DocumentsList";

interface ISectionItem {
  resource_id: number;
  color?: string;
  title?: string;
  buttonName?: string;
  content?: string;
  addButton?: () => void;
  modalButton?: () => void;
  resource_type: string;
}

const SectionItem: React.FC<ISectionItem> = ({
  resource_id,
  color,
  title,
  content,
  resource_type,
  buttonName,
  modalButton,
}) => {
  const classes = useStyles({});
  const role = useSelector((state: RootState) => state.user.role);

  return (
    <Card
      className={classes.tableCard}
      elevation={3}
      style={{ borderTop: "8px solid" + color }}
    >
      <Box className={classes.tableCardContent}>
        <CardContent
          sx={{
            paddingTop: "20px",
            paddingLeft: "0px",
          }}
        >
          <Typography
            className={classes.tableTitle}
            sx={{
              color: color,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item sx={{ mr: "20px" }}>
                <CurriculumIcon />
              </Grid>
              <Grid item>{title}</Grid>
            </Grid>
          </Typography>
        </CardContent>
        {role === "TEACHER" ? (
          <Button
            variant="outlined"
            className={classes.tableButton}
            onClick={modalButton}
            sx={{
              color: color,
              borderColor: color,
              "&:hover": {
                color: "white",
                backgroundColor: color,
              },
            }}
          >
            {buttonName? buttonName : t("edit_section")}
          </Button>
        ) : (
          <div></div>
        )}
      </Box>
      <Typography >
        <CardContent
          sx={{
            // paddingBottom: "20px",
            // paddingLeft: "0px",
          }}
        >
          {content}
        </CardContent>

      </Typography>
      <DocumentsList
        resource_id={resource_id}
        resource_type={resource_type}
        color={color}
      />
    </Card>
  );
};

export default SectionItem;


