import React, { ReactElement, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Masonry from "react-masonry-css";
import TaskAssignment from "../components/TaskAssignment";
import DocumentsTable from "../components/tables/DocumentsTable";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { Button, Grid, Typography, IconButton } from "@mui/material";
import GetIcon from "../components/GetIcon";
import { useNavigate, useParams } from "react-router-dom";
import { t } from "../features/i18n";
import { gql, useQuery } from '@apollo/client';
import getFormattedDate from "../features/GlobalFunction/globalFunction";
import { GetStudentAssignment, submitStudentAssignment } from "../apiRequest/task";
import ExpectedDocumentsTable from "../components/tables/ExpectedDocumentsTable";
import SubmitSolutionModal from "../components/modals/SubmitSolutionModal";

const GET_SUBJECT_BY_ID = gql`
  query GetSubject($id: Int!) {
    subject(subjectId: $id) {
      icon
      id
      name
      description
    }
  }
`;

interface ITaskDetails { }

const TaskDetailsStudent: React.FC<ITaskDetails> = () => {
  const [responseData, setResponseData] = useState<IAssignmentDetailResponseData>();
  const { data: subjectData } = useQuery(GET_SUBJECT_BY_ID, {
    variables: { id: responseData?.subject_id }
  });
  const [iconName, setIconName] = useState("");
  const { tasksId } = useParams();
  const [openSubmitSolutionModal, setOpenSubmitSolutionModal] = useState(false);
  const [submitButtonDisable, setSubmitButtonDisable] = useState(false);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigace zpět na předchozí stránku
  };

  console.log("responseData?.id", responseData?.id)
  console.log("tasksId", tasksId)
  const selectedPageStatus = (status?: string): ReactElement<any, any> => {
    let color: string;
    let message: string;

    switch (status) {
      case "new":
        color = "#FCBB41";
        message = t("new");
        break;
      case "in_progress":
        color = "#71BD55";
        message = t("in_progress");
        break;
      case "closed":
        color = "#008CFB";
        message = t("closed");
        break;
      default:
        color = "black";
        message = t("unknown");
    }

    return (
      <>
        <Brightness1Icon
          sx={{
            color: { color },
          }}
        />
        <Typography
          sx={{
            fontSize: "20px",
            color: "#333333",
            ml: "15px",
          }}
        >
          {message}
        </Typography>
      </>
    );
  };

  const breakpoints = {
    default: 2,
    1000: 1,
  };
  async function loadData(): Promise<void> {
    if (tasksId) {
      try {
        const assignmentData = await GetStudentAssignment(parseInt(tasksId));
        setResponseData(assignmentData);
        
        const assignmentStart = new Date(assignmentData.start);
        const assignmentEnd = new Date(assignmentData.end);
        const currentDate = new Date();
        
        const isDateWithinRange = currentDate >= assignmentStart && currentDate <= assignmentEnd;
 
        setSubmitButtonDisable(!isDateWithinRange);
        
        
      } catch (error) {
        console.error("Chyba při získávání úkolů pro studenta:", error);
      }
    }
  }
  useEffect(() => {

    loadData();
  }, []);



  useEffect(() => {
    if (subjectData?.subject.icon) {
      setIconName(subjectData?.subject.icon)
    }
  }, [subjectData]);

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', position: 'absolute', right: 16 }}>
        <Button
          variant="outlined"
          onClick={handleGoBack}
          sx={{
            width: 184,
            height: 40,
            color: "#72b855",
            textTransform: 'none',
            fontWeight: 'bold',
            borderColor: "#72b855",
            marginBottom: 2,
          }}
        >
          {t("back")}
        </Button>

        <Button
          onClick={() => setOpenSubmitSolutionModal(true)}
          variant="contained"
          disabled={submitButtonDisable}
          sx={{
            width: 184,
            height: 40,
            bgcolor: "#71BD55",
            "&:hover": {
              backgroundColor: "#71BD55",
            },
            fontWeight: "bold",

          }}
        >
          {t("finish")}
        </Button>

      </Box>
      <Typography variant="h4" sx={{ mb: 5 }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item sx={{ mr: "36px" }}>
            <GetIcon iconName={iconName} />
          </Grid>
          <Grid item>{responseData?.name}</Grid>
          <IconButton
            sx={{
              height: "11px",
              padding: "0px",
              color: "#333333",
              ml: "16px",
            }}
          >
          </IconButton>
        </Grid>
      </Typography>
      <Box display="flex" alignItems="center" sx={{ mb: "45px " }}>
        {selectedPageStatus(responseData?.status)}
        <Typography
          sx={{
            fontSize: "16px",
            color: "#333333",
            ml: "50px",
          }}
        >
          {t("beginning")}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            color: "#333333",
            ml: "11px",
          }}
        >
          {responseData ? getFormattedDate(responseData.start.toString()) : ""}
        </Typography>
        <IconButton
          sx={{
            height: "11px",
            padding: "0px",
            color: "#333333",
            ml: "16px",
          }}
        >
        </IconButton>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#333333",
            ml: "50px",
          }}
        >
          {t("submission")}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            color: "#333333",
            ml: "11px",
          }}
        >
          {responseData ? getFormattedDate(responseData.end.toString()) : ""}
        </Typography>
        <IconButton
          sx={{
            height: "11px",
            padding: "0px",
            color: "#333333",
            ml: "16px",
          }}
        >
        </IconButton>

      </Box>

      <Masonry
        breakpointCols={breakpoints}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <TaskAssignment
          color="#f18733"
          title={t("task_assignment")}
          content={responseData?.description}
          assignmentID={Number(tasksId)}
        />
        {responseData?.id ? (<ExpectedDocumentsTable
          color="#999999"
          title={t("required_documents")}
          resource_id={responseData.id}
          studentAssignment_id={parseInt(tasksId || "0")}
          resource_type="expected_document"
          uploadDisabled={submitButtonDisable}
        />) : (<></>)}
        {responseData?.id ? (<DocumentsTable
          color="#41BFAF"
          title={t("name_documents")}
          resource_id={responseData.id}
          resource_type="assignment"
        />) : (<></>)}
      </Masonry>

      <SubmitSolutionModal 
      open={openSubmitSolutionModal}
       onClose={() => setOpenSubmitSolutionModal(false)} 
      resetTable={loadData} 
      assignment_id={parseInt(tasksId || "0")}/>
    </Box>
  );
};

export default TaskDetailsStudent;
