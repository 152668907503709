import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  Alert,
  AlertTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "../../features/i18n";
import { gql, useMutation } from "@apollo/client";
import { z, ZodError } from 'zod';
import MyTextField from "./MyTextField";
import { RootState } from "../../features/redux/store";
import { useSelector } from "react-redux";
import MessageComponent from "../MessageComponent";
let color: string = "#302c2c";

const ASSIGN_TEACHER = gql`
mutation assignTeacher($classId: Int!, $teacherCode: String!) {
  assignTeacher(classId: $classId, teacherCode: $teacherCode) {
      className
      description
      graduationYear
      id
      startYear
      symbol
      teacherCode
      teacher {
        code
        name
        role
        email
      }
      teacherId
    }
}
`
const CREATE_CLASS = gql`
  mutation createClass(
    $startYear: Int!
    $graduationYear: Int!
    $symbol: String!
    $description: String!
  ) {
    createClass(
      createClassInput: {
        startYear: $startYear
        graduationYear: $graduationYear
        symbol: $symbol
        description: $description
      }
    ) {
        className
        description
        graduationYear
        id
        startYear
        symbol
        teacher {
          code
          email
          role
          name
        }
        teacherCode
        teacherId
    }
  }
`

const schema = z.object({
  className: z
    .string()
    .min(1, { message: t("symbol_class_min_one_letter") })
    .max(1, { message: t("symbol_class_max_one_letter") }),
  startYear: z
    .number()
    .min(new Date().getFullYear() - 10, { message: t("year_start_studium_too_low") })
    .max(new Date().getFullYear() + 20, { message: t("year_start_studium_too_high") }),
  graduationYear: z
    .number()
    .min(new Date().getFullYear() - 10, { message: t("year_end_studium_too_low") })
    .max(new Date().getFullYear() + 20, { message: t("year_end_studium_too_high") }),
  descriptions: z
    .string(),
}).refine((data) => data.startYear !== data.graduationYear, {
  message: t("year_start_end_studium_cant_be_same"),
  path: ["startYear"], // path of error
}).refine((data) => data.startYear <= data.graduationYear, {
  message: t("year_end_studium_cant_be_lower_then_start"),
  path: ["graduationYear"], // path of error
});

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));

interface ICreateNewClassModal {
  open: boolean;
  onClose: () => void;
  resetTable: () => void;
  skipAssignToTeacher?: boolean
};

const CreateNewClassModal: React.FC<ICreateNewClassModal> = ({ open, onClose, resetTable,skipAssignToTeacher }) => {
  const [createNewClassMutation, { error: createNewClassError, loading: createNewClassLoading, data: createNewClassData }] =
    useMutation(CREATE_CLASS);
  const [assingNewClassMutation, { error: assingNewClassError, loading: assingNewClassLoading, data: assingNewClassData }] =
    useMutation(ASSIGN_TEACHER);
  const [loading, setLoading] = useState<boolean>(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);

  const classes = useStyles();
  const userCode = useSelector((state: RootState) => state.user.userCode);
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
  } = methods;

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    console.log(data);
    try {
      if (data) {
        const validatedData = schema.parse(data);
        console.log('Validation passed:', data);
        setLoading(true)
        await handleCreateClass(validatedData.className, validatedData.startYear, validatedData.graduationYear, validatedData.descriptions)
        setTimeout(() => {
          setSuccessAlertOpen(true);
          setTimeout(() => {
            resetTable();
            onClose();
          }, 2000);
        }, 2000);
      } else {
        throw "chyba pri updatovani";
      }

    } catch (error) {
      console.error('Chyba při odesílání:', error);
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }
  };

  const handleCreateClass = async (
    className: string,
    startYear: number,
    graduationYear: number,
    description: string,

  ) => {
    console.log('handleCreateClass: ', [className, startYear, graduationYear, description]);
    try {
      const response = await createNewClassMutation({
        variables: {
          symbol: className,
          startYear: startYear,
          graduationYear: graduationYear,
          description: description,
        },
      });
      console.log("response:", response);
      if (userCode && !skipAssignToTeacher)

        await handleAssingNewClass(response.data.createClass.id, userCode)
    } catch (error) {
      console.log("Error:", error);
    }

  };

  const handleAssingNewClass = async (
    classId: number,
    teacherCode: string,
  ) => {
    console.log("handleAssingNewClass:", teacherCode);
    try {
      const response = await assingNewClassMutation({
        variables: {
          classId: classId,
          teacherCode: teacherCode,
        },
      });
      console.log("response:", response);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
    }

  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {successAlertOpen ? (
          <MessageComponent
            errorChange={errorAlertOpen}
            errorMessage={t("create_task_error")}
            successMessage={t("create_task_success")}
          />
        ) : (
          <Box>
            <Box
              sx={{
                maxHeight: "50px",
                alignText: "left",
                color: color,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "24px",
                    }}
                  >
                    {t("enter_paramaters_for_create_new_class")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <FormProvider {...methods}>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ mt: "30px " }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <MyTextField
                  name="className"
                  schema={schema}
                  setValue={setValue}
                  type="text"
                  required
                  fullWidth
                  label={t("symbol_class")}
                  sx={{ mb: 2 }}
                />
                <MyTextField
                  min={new Date().getFullYear() - 10}
                  max={new Date().getFullYear() + 20}
                  name="startYear"
                  schema={schema}
                  setValue={setValue}
                  type="number"
                  required
                  fullWidth
                  label={t("year_start_studium")}
                  sx={{ mb: 2 }}
                />
                <MyTextField
                  min={new Date().getFullYear() - 10}
                  max={new Date().getFullYear() + 20}
                  name="graduationYear"
                  schema={schema}
                  setValue={setValue}
                  type="number"
                  required
                  fullWidth
                  label={t("year_end_studium")}
                  sx={{ mb: 2 }}
                />
                <MyTextField
                  name="descriptions"
                  schema={schema}
                  setValue={setValue}
                  type="text"
                  fullWidth
                  label={t("description_class")}
                  sx={{ mb: 2 }}
                />
                <Button
                  variant="outlined"
                  onClick={onClose}
                  sx={{
                    width: 140,
                    height: 40,
                    color: color,
                    textTransform: "none",
                    fontWeight: "bold",
                    borderColor: color,
                  }}
                >
                  {t("cancel")}
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    width: 140,
                    height: 40,
                    bgcolor: color,
                    "&:hover": {
                      backgroundColor: color,
                    },
                    fontWeight: "bold",
                    position: "absolute",
                    right: "40px",
                  }}
                >
                  {t("confirm")}
                </Button>
              </Box>
            </FormProvider>
          </Box>
        )}
      </Paper>
    </Modal>
  );
};

export default CreateNewClassModal;
