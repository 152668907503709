import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import GetIcon from "../components/GetIcon";
import SubjectButton from "../components/SubjectButton";
import DocumentsTable from "../components/tables/DocumentsTable";
import CurriculumTable from "../components/tables/CurriculumTable";

import Masonry from "react-masonry-css";
import { t } from "../features/i18n";

import EventsTableDetail from "../components/tables/EventsTableDetail";
import TaskTable from "../components/tables/TaskTable";
import { useSelector } from "react-redux";
import { RootState } from "../features/redux/store";
import { GetSubject, deleteSubject } from "../apiRequest/subject";
import ApproveModal from "../components/modals/ApproveModal";
import UpdateSubjectModal from "../components/modals/UpdateSubjectModal";

const buttonRows: IButton[] = [
  { id: 1, label: t("overview"), name: "overview", color: "#4785c3" },
  { id: 2, label: t("name_calendar"), name: "calendar", color: "#3158ae" },
  { id: 3, label: t("tasks"), name: "tasks", color: "#f18733" },
  { id: 4, label: t("name_documents"), name: "dokumenty", color: "#41BFAF" },
  { id: 5, label: t("name_curriculum"), name: "curriculum", color: "#f55ea4" },
];

interface ISubjectItemPage { };

const SubjectItemPage: React.FC<ISubjectItemPage> = () => {
  const role = useSelector((state: RootState) => state.user.role);
  const [selectedButton, setSelectedButton] = useState(1);
  const { subjectId, subjectName } = useParams();
  const [openUpdateSubjectModal, setOpenUpdateSubjectModal] = useState(false);
  const [approveDeleteSubjectModal, setApproveDeleteSubjectModal] = useState(false);
  const [targetRow, setTargetRow] = useState(0);
  const [pageData, setPageData] = useState<ISubjectInput>()

  const handleDeleteSubject = (classId?: string) => {
    if (classId) {
      setTargetRow(parseInt(classId));
      setApproveDeleteSubjectModal(true);
    }
  };

  const handleButtonClick = (buttonNumber: number) => {
    setSelectedButton(buttonNumber);
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const breakpoints = {
    default: 2,
    1000: 1,
  };
  async function loadData(): Promise<void> {
    if (subjectId) {
      setPageData(await GetSubject(parseInt(subjectId)));
    }
  }


  useEffect(() => {
    loadData();
  }, []);

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', position: 'absolute', right: 16 }}>
        <Button
          variant="outlined"
          onClick={handleGoBack}
          sx={{
            width: 184,
            height: 40,
            color: "#72b855",
            textTransform: 'none',
            fontWeight: 'bold',
            borderColor: "#72b855",
            marginBottom: 2,
            // marginTop: 2
          }}
        >
          {t("back")}
        </Button>
        {role === "TEACHER" ? (<>
          <Button
            variant="outlined"
            onClick={() => setOpenUpdateSubjectModal(true)}
            sx={{
              width: 184,
              height: 40,
              color: "#F88931",
              textTransform: 'none',
              fontWeight: 'bold',
              borderColor: "#F88931",
              marginBottom: 2,
            }}
          >
            {t("edit_subject")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleDeleteSubject(subjectId)}
            sx={{
              width: 184,
              height: 40,
              color: "red",
              textTransform: 'none',
              fontWeight: 'bold',
              borderColor: "red",
            }}
          >
            {t("delete_subject")}
          </Button></>
        ) : (<></>)}
      </Box>
      <Typography variant="h4" sx={{ mb: "70px" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item sx={{ mr: "36px" }}>
            <GetIcon iconName={pageData?.icon} />
          </Grid>
          <Grid item>{pageData?.name}
          </Grid>
        </Grid>
      </Typography>

      <Box sx={{ mb: "25px " }}>
        {buttonRows.map((buttonRow) => (
          <SubjectButton
            id={buttonRow.id}
            key={buttonRow.id}
            label={buttonRow.label}
            selectedButton={selectedButton}
            name={buttonRow.name}
            color={buttonRow.color}
            submit={handleButtonClick}
          />
        ))}

      </Box>

      {selectedButton === 1 ? (
        <>
          <Masonry
            breakpointCols={breakpoints}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            <TaskTable
              color="#f18733"
              title={t("tasks")}
              limit={7}
              subjectID={parseInt(subjectId || "0")}
              select={() => handleButtonClick(3)}
            />
            <EventsTableDetail
              color="#0058B4"
              title={t("name_calendar")}
              select={() => handleButtonClick(2)}
              limit={7}
              {...(subjectId && {
                resource_id: parseInt(subjectId),
                resource_type: "subject",
                buttonName: t("add_event")
              })}
            />
          </Masonry>
          <CurriculumTable
            color="#f55ea4"
            title={t("name_curriculum")}
            subjectId={subjectId}
          />
        </>
      ) : (
        <div></div>
      )}

      {selectedButton === 2 ? (<>
        <EventsTableDetail
          color="#0058B4"
          title={t("name_calendar")}
          {...(subjectId && {
            resource_id: parseInt(subjectId),
            resource_type: "subject",
            buttonName: t("add_event")
          })}
        />
      </>
      ) : (
        <div></div>
      )}

      {selectedButton === 3 ? (
        <TaskTable
          color="#f18733"
          title={t("tasks")}
          subjectID={parseInt(subjectId || "0")}
        />
      ) : (
        <div></div>
      )}

      {selectedButton === 4 ? (
        <DocumentsTable
          color="#41BFAF"
          title={t("name_documents")}
          resource_id={parseInt(subjectId || "0")}
          resource_type="subject"
        />
      ) : (
        <div></div>
      )}

      {selectedButton === 5 ? (
        <CurriculumTable
          color="#f55ea4"
          title={t("name_curriculum")}
          subjectId={subjectId}
        />
      ) : (
        <div></div>
      )}

      {pageData ? (
        <UpdateSubjectModal
          open={openUpdateSubjectModal}
          resetSubjects={loadData}
          onClose={() => {
            setOpenUpdateSubjectModal(false);
          }} subjectID={pageData.id} subjectName={pageData.name} subjectIcon={pageData.icon} />
      ) : (<></>)}
      <ApproveModal
        message={t("really_want_delete_subject")}
        open={approveDeleteSubjectModal}
        Approve={() => {
          deleteSubject(targetRow);
          setApproveDeleteSubjectModal(false);
          handleGoBack()
        }}
        Denied={() => setApproveDeleteSubjectModal(false)}
      />
    </Box>
  );
};

export default SubjectItemPage;
