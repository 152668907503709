import React from "react";
import { ButtonBase, Card, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import useStyles from "../configs/styles";


interface IAddClass {
  onClick: () => void;
}

const AddClass: React.FC<IAddClass> = ({ onClick }) => {
  const classes = useStyles({});

  return (
    <ButtonBase
      onClick={onClick}
    >
      <Card
        className={classes.classCard}
        elevation={3}
      >
        <Typography
          sx={{
            fontSize: "50px",
            fontWeight: "bold",
          }}
        >
          +
        </Typography>

      </Card>
    </ButtonBase>
  );
};

export default AddClass;
