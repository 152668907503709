import React, { useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { TaskIcon } from "./icons/menu/TaskIcon";
import MUIRichTextEditor from "mui-rte";
import useStyles from "../configs/styles";
import { useSelector } from "react-redux";
import { RootState } from "../features/redux/store";
import { t } from "../features/i18n";

interface ITaskAssignment { 
  color:string;
  title:string;
  content?:string;
  assignmentID:number;
}
const TaskAssignment: React.FC<ITaskAssignment> = ({
  color,
  title,
  content,
  assignmentID,
}) => {
  const classes = useStyles({
    tableButtonColor: color,
    tableBorderColor: color,
    tableTitleColor: color,
  });
  const [contentState, setContentState] = useState<any>(content);
  const [enableEdit, setEnableEdit] = useState(false);

  const handleEditButton = () => {
    setEnableEdit(true);
  };

  const handleSaveButton = () => {
    setContentState(contentState);
    setEnableEdit(false);
  };

  const role = useSelector((state: RootState) => state.user.role);

  return (
    <Card
      className={classes.tableCard}
      elevation={3}
      style={{ borderTop: "8px solid" + color }}
    >
      <Box className={classes.tableCardContent}>
        <CardContent
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "0px",
          }}
        >
          <Typography
            className={classes.tableTitle}
            sx={{
              color: color,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item sx={{ mr: "20px" }}>
                <TaskIcon />
              </Grid>
              <Grid item>{title}</Grid>
            </Grid>
          </Typography>
        </CardContent>
        {/* {role === "TEACHER" ? (
          enableEdit ? (
            <Button
              variant="outlined"
              className={classes.tableButton}
              sx={{
                color: color,
                "&:hover": {
                  color: "white",
                  backgroundColor: color,
                },
              }}
              onClick={() => handleSaveButton()}
            >
              {t("save_assignment")}
            </Button>
          ) : (
            <Button
              variant="outlined"
              className={classes.tableButton}
              sx={{
                color: color,
                borderColor: color,
                "&:hover": {
                  color: "white",
                  backgroundColor: color,
                },
              }}
              onClick={() => handleEditButton()}
            >
              {t("edit_assignment")}
            </Button>
          )
        ) : (
          <div></div>
        )} */}
      </Box>
      <Box sx={{ paddingBottom: "20px" }}>
        {!enableEdit ? (
          // <MUIRichTextEditor defaultValue={contentState} readOnly={true} controls={[]} />
          content
        ) : (
          content
          // <MUIRichTextEditor
          //   label="Type something here..."
          //   defaultValue={contentState}

          //   controls={["bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "link", "numberList", "bulletList", "quote", "code"]}
          // />
        )}
      </Box>
    </Card>
  );
};

export default TaskAssignment;
