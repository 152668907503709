import React from "react";

interface IconAttributes {
    className?: string,
}

export const EnglishIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
    <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30 0.0439453C46.569 0.0439453 60 13.4749 60 30.0439C60 46.6129 46.569 60.0439 30 60.0439C13.431 60.0439 0 46.6129 0 30.0439C0 13.4749 13.431 0.0439453 30 0.0439453Z" fill="#F0F0F0"/>
        <path d="M6.20194 11.7791C3.84494 14.8451 2.06894 18.3791 1.03394 22.2181H16.6409L6.20194 11.7791Z" fill="#0052B4"/>
        <path d="M58.9669 22.2181C57.9319 18.3791 56.1549 14.8451 53.7989 11.7791L43.3599 22.2181H58.9669Z" fill="#0052B4"/>
        <path d="M1.03296 37.8701C2.06796 41.7091 3.84496 45.2431 6.20096 48.3081L16.639 37.8701H1.03296Z" fill="#0052B4"/>
        <path d="M48.2649 6.24515C45.1989 3.88815 41.6649 2.11215 37.8259 1.07715V16.6831L48.2649 6.24515Z" fill="#0052B4"/>
        <path d="M11.7349 53.8421C14.8009 56.1991 18.3349 57.9751 22.1739 59.0101V43.4041L11.7349 53.8421Z" fill="#0052B4"/>
        <path d="M22.1741 1.0769C18.3351 2.1119 14.8011 3.8889 11.7361 6.2449L22.1741 16.6839V1.0769Z" fill="#0052B4"/>
        <path d="M37.8259 59.0101C41.6649 57.9751 45.1989 56.1981 48.2639 53.8421L37.8259 43.4031V59.0101Z" fill="#0052B4"/>
        <path d="M43.3599 37.8701L53.7979 48.3091C56.1549 45.2431 57.9319 41.7091 58.9669 37.8701H43.3599Z" fill="#0052B4"/>
        <path d="M59.746 26.1309H33.913V0.297943C32.632 0.130943 31.326 0.0439453 30 0.0439453C28.674 0.0439453 27.368 0.130943 26.087 0.297943V26.1309H0.253998C0.0869978 27.4119 0 28.7179 0 30.0439C0 31.3709 0.0869978 32.6759 0.253998 33.9569H26.087V59.7899C27.368 59.9569 28.674 60.0439 30 60.0439C31.326 60.0439 32.632 59.9569 33.913 59.7899V33.9569H59.746C59.913 32.6759 60 31.3709 60 30.0439C60 28.7179 59.913 27.4119 59.746 26.1309Z" fill="#D80027"/>
        <path d="M37.8264 37.8701L51.2134 51.2571C51.8293 50.6426 52.4162 49.9977 52.9769 49.3309L41.5147 37.8701L37.8264 37.8701Z" fill="#D80027"/>
        <path d="M22.1746 37.8696L8.78761 51.2566C9.40209 51.8725 10.0463 52.4601 10.7131 53.0194L22.1739 41.5586L22.1746 37.8696Z" fill="#D80027"/>
        <path d="M22.1738 22.2183L8.78688 8.83131C8.17099 9.44579 7.58409 10.0907 7.02335 10.7575L18.4856 22.2183L22.1738 22.2183Z" fill="#D80027"/>
        <path d="M37.8259 22.2175L51.2129 8.83058C50.5984 8.21469 49.9535 7.62779 49.2867 7.06706L37.8259 18.5293L37.8259 22.2175Z" fill="#D80027"/>
    </svg>
    );
}