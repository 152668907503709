import { SvgIcon } from "@mui/material";
import React from "react";

interface IconAttributes {
    className?: string,
}

export const SubjectIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <SvgIcon width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.2012 19.1401H7.80117C7.46962 19.1401 7.20117 19.3958 7.20117 19.7116C7.20117 20.0273 7.46962 20.283 7.80117 20.283H13.2012V20.8544C13.2012 21.1702 13.4696 21.4259 13.8012 21.4259C14.1327 21.4259 14.4012 21.1702 14.4012 20.8544V20.283H16.2012C16.5327 20.283 16.8012 20.0273 16.8012 19.7116C16.8012 19.3958 16.5327 19.1401 16.2012 19.1401Z"/>
            <path d="M22.801 19.7102C22.8001 20.4365 22.3201 21.0841 21.601 21.3271V16.853H22.201C22.5325 16.8539 22.8001 17.1087 22.801 17.4245V19.7102ZM20.401 20.853C20.3992 22.4302 19.057 23.7085 17.401 23.7102H6.60098C4.94498 23.7085 3.60275 22.4302 3.60098 20.853V12.2816C3.60275 10.3345 4.47564 8.48054 6.00098 7.1853V9.42446C6.00098 12.0412 7.45342 13.4245 10.201 13.4245H13.801C16.5494 13.4245 18.001 12.0412 18.001 9.42446V7.1853C19.5272 8.48054 20.4001 10.3345 20.401 12.2816V20.853ZM7.20098 6.35229C8.29342 5.7453 9.53609 5.42446 10.801 5.42446H13.201C14.4659 5.42446 15.7085 5.7453 16.801 6.35229V9.42446C16.801 11.4003 15.8756 12.2816 13.801 12.2816H10.201C8.1272 12.2816 7.20098 11.4003 7.20098 9.42446V6.35229ZM9.60098 4.2816C9.60098 3.01938 10.6756 1.99588 12.001 1.99588C13.3263 1.99588 14.401 3.01938 14.401 4.2816V4.37303C14.0036 4.31546 13.6028 4.28499 13.201 4.2816H10.801C10.3992 4.28499 9.99831 4.31546 9.60098 4.37303V4.2816ZM2.40098 21.3271C1.68275 21.0841 1.20187 20.4365 1.20098 19.7102V17.4245C1.20187 17.1087 1.47031 16.8539 1.80098 16.853H2.40098V21.3271ZM22.201 15.7102H21.601V12.2816C21.5956 8.74636 19.1574 5.63271 15.601 4.61853V4.2816C15.601 2.38784 13.9894 0.853027 12.001 0.853027C10.0125 0.853027 8.40098 2.38784 8.40098 4.2816V4.61853C4.84453 5.63271 2.40631 8.74636 2.40098 12.2816V15.7102H1.80098C0.807199 15.711 0.00186545 16.478 0.000976562 17.4245V19.7102C0.00542101 21.2145 1.23564 22.4564 2.81164 22.5487C3.49964 23.9514 4.97609 24.8488 6.60098 24.853H17.401C19.0259 24.8488 20.5023 23.9514 21.1903 22.5487C22.7663 22.4564 23.9965 21.2145 24.001 19.7102V17.4245C24.0001 16.478 23.1948 15.711 22.201 15.7102Z"/>
        </SvgIcon>
    );
}