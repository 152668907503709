import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { t } from "../../features/i18n";
import { z } from 'zod';
import { UpdateAssignment } from "../../apiRequest/task";
import MessageComponent from "../MessageComponent";
import { formatDateTimeForInput } from "../../features/GlobalFunction/globalFunction";

let color: string = "#f18733";

const schema = z.object({
  name: z.string().min(1, t("name_min_one_symbol")),
  description: z.string().min(1),
  type: z.string().min(1),
  startDate: z.date(),
  endDate: z.date(),
});
type FormData = z.infer<typeof schema>;

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));

interface IUpdateTaskModal {
  open: boolean;
  onClose: () => void;
  resetTable: () => void;
  AssignmentData?: IAssignmentUpdateData;
};

const UpdateTaskModal: React.FC<IUpdateTaskModal> = ({ open, onClose, resetTable, AssignmentData }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setLoading(false);
      setErrorAlertOpen(false);
      setSuccessAlertOpen(false);
    }
    console.log(`dates ${formatDateTimeForInput(AssignmentData?.start)} ${AssignmentData?.end}`);
  }, [open]);

  const handleCancel = () => {
    onClose();
  };

  const onSubmit = async (data: FormData) => {

    try {
      if (!data) {
        throw "input data fail"
      }
      if (!AssignmentData) {
        throw "input data fail"
      }
      const { name, description, type, startDate, endDate } = data;
      const objectUpdate: IAssignmentUpdateData = {
        id: AssignmentData?.id,
        assignment_type: type,
        name,
        description,
        start: startDate.toString() + ":00Z",
        end: endDate.toString() + ":00Z",
      };
      setLoading(true)
      await UpdateAssignment(objectUpdate)
      setTimeout(() => {
        setSuccessAlertOpen(true);
        setTimeout(() => {
          resetTable();
          onClose();
        }, 2000);
      }, 2000);


    } catch (error) {
      console.error('Chyba při odesílání:', error);
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {successAlertOpen ? (
          <MessageComponent
            errorChange={errorAlertOpen}
            errorMessage={t("update_task_error")}
            successMessage={t("update_task_success")}
          />
        ) : (
          <Box>
            <Box
              sx={{
                maxHeight: "50px",
                alignText: "left",
                color: color,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "24px",
                    }}
                  >
                    {t("edit_task")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>

              <TextField sx={{ mb: 2, mt: 2 }}
                {...register('name')}
                required
                label={t("task_name")}
                defaultValue={AssignmentData?.name}
                error={!!errors.name}
                helperText={errors.name?.message}
                fullWidth
              />

              <TextField sx={{ mb: 2 }}
                {...register('description')}
                required
                label={t("task_assignment")}
                multiline
                rows={3}
                defaultValue={AssignmentData?.description}
                error={!!errors.description}
                helperText={errors.description?.message}
                fullWidth />

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                {t("start")}
              </Typography>

              <TextField sx={{ mb: 2 }}
                {...register('startDate')}
                required
                type="datetime-local"
                defaultValue={formatDateTimeForInput(AssignmentData?.start)}
                InputLabelProps={{ shrink: true }}
                error={!!errors.startDate}
                helperText={errors.startDate?.message}
                fullWidth
              />

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                {t("end")}
              </Typography>

              <TextField sx={{ mb: 2 }}
                {...register('endDate')}
                required
                type="datetime-local"
                defaultValue={formatDateTimeForInput(AssignmentData?.end)}
                InputLabelProps={{ shrink: true }}
                error={!!errors.endDate}
                helperText={errors.endDate?.message}
                fullWidth />

              <TextField
                sx={{ mb: 2 }}
                {...register('type')}
                select
                label={t("type")}
                defaultValue={AssignmentData?.assignment_type}
                error={!!errors.type}
                helperText={errors.type?.message}
                fullWidth
              >
                <MenuItem value="homework">{t("homework")}</MenuItem>
                <MenuItem value="test">{t("test")}</MenuItem>
              </TextField>

              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{
                  width: 140,
                  height: 40,
                  color: color,
                  textTransform: "none",
                  fontWeight: "bold",
                  borderColor: color,
                }}
              >
                {t("cancel")}
              </Button>

              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{
                  width: 140,
                  height: 40,
                  bgcolor: color,
                  "&:hover": {
                    backgroundColor: color,
                  },
                  fontWeight: "bold",
                  position: "absolute",
                  right: "40px",
                }}
              >
                {loading ? t("processing") : t("confirm")}

              </Button>
            </form>
          </Box>
        )}
      </Paper>
    </Modal>
  );
};

export default UpdateTaskModal;
