export default function getFormattedDate(date: string): string {
  const dateObject = new Date(date);

  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();

  const hour = dateObject.getHours();
  const min = dateObject.getMinutes().toString().padStart(2, '0');

  const formattedDate = `${day}.${month}.${year} ${hour}:${min}`
  return formattedDate;
}

export function getFormattedDayDate(date: string): string {
  const dateObject = new Date(date);

  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();


  const formattedDate = `${day}.${month}.${year}`
  return formattedDate;
}

export function convertDocumentIcon(date: string): string {
  return date.replace(".", "");
}


export const formatDateTimeForInput = (dateTime: string | undefined): string => {
  if (!dateTime) return '';
  const dateTimeWithoutZ = dateTime.endsWith('Z') ? dateTime.slice(0, -1) : dateTime;

  return dateTimeWithoutZ.split('.')[0];
};