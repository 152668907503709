import React, { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import { t } from "../features/i18n";
import PanoramaViewer from "../features/ThreeSixtyViewer/PanoramaViewer";

export interface IPanoramas { }

const Panoramas: React.FC<IPanoramas> = (props) => {
  const [images, setImages] = useState<IImageParameters[]>([]);
  const [groupedData, setGroupedData] = useState<GroupedImages>();
  const [dataCheck, setDataCheck] = useState<boolean>(false);
  const apiCalled = useRef(false);

  async function loadData(): Promise<void> {
    if (dataCheck !== true) {
      setDataCheck(false);
      fetchImages();
      console.log("Panoramas 123");
    }
  }

  useEffect(() => {

    if (!apiCalled.current) {
      loadData();
      apiCalled.current = true;
    }
  }, []);


  const fetchImages = async () => {
    try {
      const response = await fetch("/Panorama/PanoramaGalleryData.json");
      const data = await response.json();
      await addImageData(data);
    } catch (error) {
      console.error("Something went wrong", error);
    }
  };

  const addImageData = async (data: any) => {
    let imagesData: IImageParameters[] = [];
    data.map((img: any, index: any) => {
      if (
        img.amount !== undefined &&
        img.path !== undefined &&
        img.fileName !== undefined
      ) {
        for (let i = 1; i <= img.amount; i++) {
          const filepath = img.path.replace("{index}", i.toString());
          const fileName = img.fileName.replace("{index}", i.toString());
          const fullpath = filepath + "/" + fileName;

          let description = t("missing_description");
          if (img.description !== undefined) {
            description = img.description;
          }

          const image: IImageParameters = {
            id: index,
            amount: img.amount,
            path: fullpath,
            fileName: fileName,
            firstImagePath: fullpath,
            rotation: "",
            description: description
          };
          imagesData.push(image);
        }
      }
      

      if (imagesData.length !== 0) {
        setImages(imagesData);
        const groupedData: GroupedImages = imagesData.reduce((acc: GroupedImages, item: IImageParameters) => {
          if (!acc[item.id]) {
            acc[item.id] = [];
          }
          acc[item.id].push(item);
          return acc;
        }, {});

        setGroupedData(groupedData);
        console.log(`Panoramas 123 groupedData ${groupedData} `);
        console.log(groupedData);
        setDataCheck(true);
      }
    });
  };

  return (
    <div>
      <Typography variant="h4" sx={{ mb: 5 }}>
        {t("panoramas")}
      </Typography>
      {dataCheck && groupedData? (
        <PanoramaViewer images={groupedData} />
      ) : (
        <Typography sx={{}}>
          {t("not_find_any_models")}
        </Typography>
      )}
      
    </div>
  );
};

export default Panoramas;
