import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  styled,
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  Checkbox,
  Button,
  IconButton,
} from "@mui/material";
import { CurriculumIcon } from "../icons/menu/CurriculumIcon";
import { useSelector } from "react-redux";
import { RootState } from "../../features/redux/store";
import useStyles from "../../configs/styles";
import { t } from "../../features/i18n";
import EastIcon from "@mui/icons-material/East";
import { GetListAvailableAssigneesTable, assignStudentAssignment, unassignStudentAssignment } from "../../apiRequest/task";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import SolutionModal from "../modals/SolutionModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StudentsAssignmentTable: React.FC<IStudentTable> = ({
  color,
  title,
  buttonName,
  assignmentID,
  classID,
  modalButton,
}) => {
  const classes = useStyles({});
  const role = useSelector((state: RootState) => state.user.role);
  const [editStudent, setEditStudent] = useState(false);
  const [tableData, setTableData] = useState<IStudentAssignmentTableData[]>()
  const [loaded, setLoaded] = useState(false);
  const [markSolutionID, setMarkSolutionID] = useState<IStudentAssignmentTableData>();
  const [openSolution, setOpenSolution] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);

  const isSelected = (userCode: string): boolean => {
    return selected.includes(userCode);
  };

  const handleClick = (event: React.MouseEvent<unknown>, userCode: string) => {
    if (isSelected(userCode)) {
      setSelected(selected.filter(item => item !== userCode));
    } else {
      setSelected([...selected, userCode]);
    }
  };

  const handleAssignStudents = async () => {
    console.log("selected", selected)
    if(assignmentID){
      await assignStudentAssignment({assignment_id:assignmentID,user_codes:selected})
      setTableData(await GetListAvailableAssigneesTable(assignmentID))
      setSelected([]);
    }
  };

  const handleUnassignStudents = async (userCode: string) => {
    console.log("handleUnassignStudents", userCode)
    if(assignmentID){
      await unassignStudentAssignment({assignment_id:assignmentID,user_codes:[userCode]})
      setTableData(await GetListAvailableAssigneesTable(assignmentID))
      setSelected([]);
    }
    
  };

  const handleShowSolution = (student_assignment: IStudentAssignmentTableData) => {
    setMarkSolutionID(student_assignment)
    console.log("handleShowSolution", student_assignment)

    setOpenSolution(true)
  };


  const handleEditStudent = (state: boolean) => {
    setSelected([]);
    setEditStudent(state)
  };


  useEffect(() => {

    async function loadData(): Promise<void> {

      if (assignmentID) {
        setTableData(await GetListAvailableAssigneesTable(assignmentID))
      } else {
        const dataResponse: IStudentAssignmentTableData[] = [];
        dataResponse.push({
          user_code: "",
          email: "",
          name: t("student_is_not_there"),
          class_id: 0,
          class_name: "",
          student_assignment: {
            id: 0,
            created_at: "",
            updated_at: "",
            created_by: "",
            updated_by: null,
            student_id: 0,
            assignment_id: 0,
            grade: null,
            comment: null,
            content: null,
            status: "",
            submitted_at: null,
            assessed_at: null,
            assessed_by: null
          }
        })

        setTableData(dataResponse)

        if (dataResponse.length > 1) {
          setLoaded(true)
        }

      }
    }
    if (!loaded) {
      loadData();
    }

  }, []);

  return (
    <Card
      className={classes.tableCard}
      elevation={3}
      style={{ borderTop: "8px solid" + color }}
    >
      <Box className={classes.tableCardContent}>
        <CardContent
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "0px",
          }}
        >
          <Typography
            className={classes.tableTitle}
            sx={{
              color: color,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item sx={{ mr: "20px" }}>
                <CurriculumIcon />
              </Grid>
              <Grid item>{title}</Grid>
            </Grid>
          </Typography>
        </CardContent>
        {editStudent ? (
          <Button
            variant="outlined"
            className={classes.tableButton}
            sx={{
              color: color,
              "&:hover": {
                color: "white",
                backgroundColor: color,
              },
            }}
            onClick={() => handleEditStudent(false)}
          >
            {t("preview_solutions")}
          </Button>
        ) : (
          <Button
            variant="outlined"
            className={classes.tableButton}
            sx={{
              color: color,
              borderColor: color,
              "&:hover": {
                color: "white",
                backgroundColor: color,
              },
            }}
            onClick={() => handleEditStudent(true)}
          >
            {t("edit_students")}
          </Button>
        )}
      </Box>

      {editStudent ? (
        <>
          <TableContainer
            sx={{
              paddingBottom: "20px",
            }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: "50px" }} align="left" />
                  <StyledTableCell style={{ width: "auto" }} align="left">
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#808080",
                      }}
                    >
                      {t("name_student")}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.map((row, index) => {
                  const isItemSelected = isSelected(row.user_code);
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell style={{ width: "50px" }} align="left">
                        {row.student_assignment === null ? (<Checkbox
                          onClick={(event) => handleClick(event, row.user_code)}
                          color="primary"
                          checked={isItemSelected}
                        />) : (<></>)}

                      </StyledTableCell>
                      <StyledTableCell style={{ width: "auto" }} align="left" component="th"
                        scope="row"
                        padding="none">
                        <Typography
                          sx={{
                            fontSize: "15px",
                            color: "#4D4D4D",
                          }}
                        >
                          {row.name}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "50px" }} align="right">
                        {row.student_assignment === null ? (
                          <></>
                        ) : (
                          <IconButton
                            onClick={() => handleUnassignStudents(row.user_code)}
                            sx={{
                              height: "11px",
                              padding: "0px",
                              color: color,
                            }}
                          >
                            <LinkOffIcon />
                          </IconButton>
                        )}

                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: "30px ", mb: "20px" }}>
            <Button
              onClick={handleAssignStudents}
              variant="outlined"
              sx={{
                width: 216,
                height: 40,
                color: color,
                textTransform: "none",
                fontWeight: "bold",
                borderColor: color,
                "&:hover": {
                  color: "white",
                  backgroundColor: color,
                },
              }}
            >
              {t("assign_students")}
            </Button>
          </Box>
        </>) : (
        <TableContainer
          sx={{
            paddingBottom: "20px",
          }}
        >
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ width: "300px" }} align="left">
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#808080",
                    }}
                  >
                    {t("name_student")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell style={{ width: "300px" }} align="left">
                  {t("state")}
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.map((row, index) => (
                <>{row.student_assignment === null ? (<></>) : (
                  <StyledTableRow key={index}>

                    <StyledTableCell style={{ width: "300px" }} align="left">
                      <Typography
                        sx={{
                          fontSize: "15px",
                          color: "#4D4D4D",
                        }}
                      >
                        {row.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "300px" }} align="left">
                      <Typography
                        sx={{
                          fontSize: "15px",
                          color: "#4D4D4D",
                        }}
                      >
                        {row.student_assignment.status}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: "50px" }} align="right">
                      <IconButton
                        onClick={() => handleShowSolution(row)}
                        sx={{
                          height: "11px",
                          padding: "0px",
                          color: color,
                        }}
                      >
                        <EastIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>)}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <SolutionModal
        open={openSolution}
        studentAssignmentID={markSolutionID?.student_assignment.id || 0}
        assignmentID={markSolutionID?.student_assignment.assignment_id || 0}
        studentName={markSolutionID?.name || ""}
        onClose={() => {
          setOpenSolution(false);
        }}
      />

    </Card>
  );
};

export default StudentsAssignmentTable;
