import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Button, Typography, Container} from "@mui/material";
import { t } from "../features/i18n";

// ----------------------------------------------------------------------

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <>
      <Container>
        <StyledContent sx={{ textAlign: "center", alignItems: "center" }}>
          <Typography variant="h3" paragraph>
            {t("sorry_page_not_found")}
          </Typography>

          <Typography sx={{ color: "text.secondary" }}>
            {t("sorry_page_not_found_desc")}
          </Typography>

          <Button
            to="/"
            size="large"
            variant="contained"
            component={RouterLink}
          >
            {t("back_to_home")}
          </Button>
        </StyledContent>
      </Container>
    </>
  );
}
