import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import DocumentsTable from "../components/tables/DocumentsTable";
import { t } from "../features/i18n";
import SubjectButton from "../components/SubjectButton";
import StudentsTable from "../components/tables/StudentsTable";
import { useSelector } from "react-redux";
import { RootState } from "../features/redux/store";
import EventsTableDetail from "../components/tables/EventsTableDetail";
import { GetClassForStudent, GetClassForTeacher } from "../apiRequest/task";
import UpdateClassModal from "../components/modals/UpdateClassModal";
import ApproveModal from "../components/modals/ApproveModal";
import { deleteClass } from "../apiRequest/class";

const buttonRows: IButton[] = [
  { id: 1, label: t("overview"), name: "overview", color: "#4785c3" },
  { id: 2, label: t("name_calendar"), name: "calendar", color: "#3158ae" },
  { id: 3, label: t("documents"), name: "dokumenty", color: "#41BFAF" },
  { id: 4, label: t("students"), name: "curriculum", color: "#999999" },
];

interface IMyClass { };

const MyClass: React.FC<IMyClass> = ({ }) => {
  const role = useSelector((state: RootState) => state.user.role);
  const { classID } = useParams();
  const [selectedButton, setSelectedButton] = useState(1);
  const [openUpdateClassModal, setOpenUpdateClassModal] = useState(false);
  const [assinged, setAssinged] = useState(false);
  const [pageData, setPageData] = useState<IClassPageData>()
  const [resourceID, setresourceID] = useState(0)
  const [approveDeleteClassModal, setApproveDeleteClassModal] = useState(false);
  const [targetRow, setTargetRow] = useState(0);

  const handleDeleteClass = (classId?: string) => {
    if (classId) {
      setTargetRow(parseInt(classId));
      setApproveDeleteClassModal(true);
    }
  };

  const handleButtonClick = (buttonNumber: number) => {
    setSelectedButton(buttonNumber);
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const breakpoints = {
    default: 2,
    1000: 1,
  };

  async function getResourceID() {
    if (role === "TEACHER" && classID) {
      setresourceID(parseInt(classID))
    }
    if (role === "STUDENT" && pageData) {
      setresourceID(pageData?.id)
    }
  }

  async function loadData(): Promise<void> {
    if (role === "TEACHER" && classID) {
      setPageData(await GetClassForTeacher(parseInt(classID)))
      await getResourceID()
      setAssinged(true)
    } else if (role === "STUDENT") {
      setPageData(await GetClassForStudent())
      await getResourceID()
      setAssinged(true)
    } else {
      setAssinged(false)
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Box>
      <Box display="flex" alignItems="center" sx={{ mb: "45px" }}>
        {assinged === false ? (
          <Typography sx={{ fontSize: "40px", fontWeight: "bold", color: "#333333", ml: "50px" }}>
            {t("student_or_teacher_assigned")}
          </Typography>
        ) : (<>

          <Typography sx={{ fontSize: "32px", fontWeight: "bold", color: "#333333", ml: "50px" }}>
            {pageData?.className}
          </Typography>
          <Typography sx={{ fontSize: "16px", color: "#333333", ml: "50px" }}>
          {t("teacher")}
          </Typography>
          <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: "#333333", ml: "2px" }}>
            {pageData?.teacherName}
          </Typography>
          {role === "TEACHER" && (
            <>
              <Typography sx={{ fontSize: "16px", color: "#333333", ml: "50px" }}>
              {t("begin_studium")}
              </Typography>
              <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: "#333333", ml: "2px" }}>
                {pageData?.startYear}
              </Typography>

              <Typography sx={{ fontSize: "16px", color: "#333333", ml: "50px" }}>
              {t("end_studium")}
              </Typography>
              <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: "#333333", ml: "2px" }}>
                {pageData?.graduationYear}
              </Typography>

            </>
          )}</>)}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', position: 'absolute', right: 16 }}>
          <Button
            variant="outlined"
            onClick={handleGoBack}
            sx={{
              width: 184,
              height: 40,
              color: "#72b855",
              textTransform: 'none',
              fontWeight: 'bold',
              borderColor: "#72b855",
              marginBottom: 2,
              marginTop: 2
            }}
          >
            {t("back")}
          </Button>
          {role === "TEACHER" ? (<>
            <Button
              variant="outlined"
              onClick={() => setOpenUpdateClassModal(true)}
              sx={{
                width: 184,
                height: 40,
                color: "#F88931",
                textTransform: 'none',
                fontWeight: 'bold',
                borderColor: "#F88931",
                marginBottom: 2,
              }}
            >
              {t("edit_class")}
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleDeleteClass(classID)}
              sx={{
                width: 184,
                height: 40,
                color: "red",
                textTransform: 'none',
                fontWeight: 'bold',
                borderColor: "red",
              }}
            >
              {t("delete_class")}
            </Button></>
          ) : (<></>)}
        </Box>
      </Box>
      <Box sx={{ mb: "45px " }}>
        {buttonRows.map((buttonRow) => (
          <SubjectButton
            id={buttonRow.id}
            key={buttonRow.id}
            label={buttonRow.label}
            selectedButton={selectedButton}
            name={buttonRow.name}
            color={buttonRow.color}
            submit={handleButtonClick}
          />
        ))}

      </Box>
      {selectedButton === 1 ? (
        <Masonry
          breakpointCols={breakpoints}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          <EventsTableDetail
            color="#0058B4"
            title={t("name_calendar")}
            {...(classID && {
              resource_id: parseInt(classID),
              resource_type: "class",
              buttonName: t("add_event")
            })}
          />

          <DocumentsTable
            color="#41BFAF"
            title={t("name_documents")}
            resource_id={resourceID}
            resource_type="class"
          />
        </Masonry>
      ) : (
        <div></div>
      )}
      {selectedButton === 2 ? (
        <EventsTableDetail
          color="#0058B4"
          title={t("name_calendar")}
          {...(classID && {
            resource_id: parseInt(classID),
            resource_type: "class",
            buttonName: t("add_event")
          })}
        />
      ) : (
        <div></div>
      )}

      {selectedButton === 3 ? (
        <DocumentsTable
          color="#41BFAF"
          title={t("name_documents")}
          resource_id={resourceID}
          resource_type="class"
        />
      ) : (
        <div></div>
      )}
      {selectedButton === 4 ? (
        <StudentsTable
          color="#999999"
          title={t("students")}
          buttonName={t("save_assignment_students")}
          classID={pageData?.id}
        ></StudentsTable>
      ) : (
        <div></div>
      )}
      {pageData ? (
        <UpdateClassModal
          open={openUpdateClassModal}
          resetTable={loadData}
          onClose={() => {
            setOpenUpdateClassModal(false);
          }} classid={pageData.id} graduationYear={pageData.graduationYear} description={""} startYear={pageData.startYear} symbol={pageData.symbol} />
      ) : (<></>)}

      <ApproveModal
        message={t("really_want_delete_class")}
        open={approveDeleteClassModal}
        Approve={() => {
          deleteClass(targetRow);
          setApproveDeleteClassModal(false);
          handleGoBack()
        }}
        Denied={() => setApproveDeleteClassModal(false)}
      />
    </Box>
  );
};

export default MyClass;
