import { NavBarItems } from "../../features/authentication/types/user";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { CalendarIcon } from "../icons/menu/CalendarIcon";
import { VirtualRealityIcon } from "../icons/menu/VirtualRealityIcon";
import { TaskIcon } from "../icons/menu/TaskIcon";
import { SubjectIcon } from "../icons/menu/SubjectIcon";
import { ClassIcon } from "../icons/menu/ClassIcon";
import { DashboardIcon } from "../icons/menu/DashboardIcon";
import { t } from "../../features/i18n";

const studentItems: NavBarItems[] = [
  {
    index: true,
    path: "/overview",
    state: "overview",
    color: "#4785c3",
    selectedColor: "#E0F1FE",
    sidebarProps: {
      displayText: t("overview"),
      icon: <DashboardIcon />,
    },
  },
  {
    path: "/myClass",
    state: "myClass",
    color: "#faba42",
    selectedColor: "#FFF0D4",
    sidebarProps: {
      displayText: t("my_class"),
      icon: <ClassIcon />,
    },
  },
  {
    path: "/subjects",
    state: "subjects",
    color: "#72b855",
    selectedColor: "#EEF7EB",
    sidebarProps: {
      displayText: t("subjects"),
      icon: <SubjectIcon />,
    },
  },
  {
    path: "/tasks",
    state: "tasks",
    color: "#f18733",
    selectedColor: "#FCE8D9",
    sidebarProps: {
      displayText: t("tasks"),
      icon: <TaskIcon />,
    },
  },
  {
    path: "/calendar",
    state: "calendar",
    color: "#3158ae",
    selectedColor: "#D4E9FF",
    sidebarProps: {
      displayText: t("calendar"),
      icon: <CalendarIcon />,
    },
  },
  {
    path: "/virtualReality",
    state: "virtualReality",
    color: "#9b4c97",
    selectedColor: "#FDE7FD",
    sidebarProps: {
      displayText: t("vr"),
      icon: <VirtualRealityIcon />,
    },
  },
  {
    path: "/hint",
    state: "hint",
    color: "#a0a0a0",
    selectedColor: "#EFEFEF",
    sidebarProps: {
      displayText: t("name_hint"),
      icon: <HelpOutlineOutlinedIcon />,
    },
  },
];

export default studentItems;
