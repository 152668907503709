import React from "react";

interface IconAttributes {
    className?: string,
}

export const PhysicsIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <svg width="61" height="65" viewBox="0 0 61 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.6999 2.69696C27.4749 2.69696 24.2419 5.78296 21.8299 11.164C19.2599 16.897 17.8439 24.543 17.8439 32.694C17.8439 40.845 19.2599 48.491 21.8299 54.224C24.2419 59.605 27.4749 62.691 30.6999 62.691C33.9249 62.691 37.1579 59.605 39.5699 54.224C42.1409 48.491 43.5559 40.845 43.5559 32.694C43.5559 24.543 42.1409 16.897 39.5699 11.164C37.1579 5.78296 33.9249 2.69696 30.6999 2.69696ZM30.6999 64.833C26.5329 64.833 22.6879 61.377 19.8749 55.101C17.1839 49.098 15.7019 41.14 15.7019 32.694C15.7019 24.248 17.1839 16.29 19.8749 10.287C22.6879 4.01096 26.5329 0.553955 30.6999 0.553955C34.8669 0.553955 38.7119 4.01096 41.5259 10.287C44.2169 16.29 45.6989 24.248 45.6989 32.694C45.6989 41.14 44.2169 49.098 41.5259 55.101C38.7119 61.377 34.8669 64.833 30.6999 64.833Z" fill="#3397E8"/>
            <path d="M12.14 12.343C8.29504 12.343 5.42005 13.355 3.96605 15.294C2.94405 16.657 2.61805 18.443 2.99805 20.602C4.19505 27.405 11.899 36.334 22.625 43.349C29.877 48.091 37.565 51.364 44.274 52.566C50.608 53.7 55.405 52.799 57.433 50.094C58.455 48.731 58.78 46.945 58.4 44.786C57.203 37.983 49.499 29.054 38.773 22.04C31.521 17.297 23.833 14.024 17.125 12.822C15.48 12.517 13.812 12.356 12.14 12.343ZM49.289 55.192C47.48 55.179 45.675 55.006 43.896 54.675C36.911 53.424 28.94 50.038 21.452 45.142C15.899 41.51 11.035 37.315 7.38605 33.01C3.76905 28.742 1.52205 24.58 0.887045 20.974C0.401045 18.208 0.859046 15.864 2.25105 14.009C4.82005 10.582 10.237 9.41202 17.502 10.713C24.488 11.964 32.458 15.35 39.947 20.246C45.5 23.878 50.364 28.073 54.012 32.378C57.629 36.646 59.877 40.808 60.511 44.414C60.998 47.18 60.539 49.524 59.148 51.38C57.263 53.893 53.845 55.192 49.289 55.192Z" fill="#1261A0"/>
            <path d="M22.6261 22.04C11.9001 29.054 4.19607 37.982 2.99907 44.786C2.61907 46.945 2.94508 48.731 3.96707 50.094C5.99508 52.799 10.7911 53.7 17.1261 52.566C23.8341 51.364 31.5221 48.091 38.7741 43.349C49.5011 36.334 57.2041 27.406 58.4011 20.602C58.7811 18.443 58.4561 16.657 57.4341 15.294C55.4061 12.589 50.6091 11.688 44.2751 12.822C37.5661 14.024 29.8781 17.297 22.6261 22.04ZM12.1101 55.193C7.55407 55.193 4.13707 53.894 2.25207 51.38C0.861075 49.524 0.402076 47.18 0.889076 44.414C1.52308 40.808 3.77107 36.646 7.38807 32.378C11.0361 28.073 15.9001 23.878 21.4541 20.246C28.9411 15.35 36.9121 11.964 43.8981 10.713C51.1641 9.41202 56.5791 10.582 59.1491 14.009C60.5401 15.864 60.9991 18.208 60.5121 20.974C59.8781 24.58 57.6301 28.742 54.0131 33.01C50.3651 37.315 45.5011 41.51 39.9471 45.142C32.4601 50.038 24.4891 53.424 17.5031 54.675C15.7241 55.006 13.9201 55.179 12.1101 55.193Z" fill="#2978B8"/>
            <path d="M30.7 25.1938C34.842 25.1938 38.199 28.5518 38.199 32.6928C38.199 36.8348 34.842 40.1918 30.7 40.1918C26.558 40.1918 23.201 36.8348 23.201 32.6928C23.201 28.5518 26.558 25.1938 30.7 25.1938Z" fill="#EB423F"/>
            <path d="M4.98998 36.979C6.76498 36.979 8.20398 38.418 8.20398 40.193C8.20398 41.968 6.76498 43.407 4.98998 43.407C3.21498 43.407 1.77598 41.968 1.77598 40.193C1.77598 38.418 3.21498 36.979 4.98998 36.979Z" fill="#F7B030"/>
            <path d="M47.84 53.0491C49.615 53.0491 51.054 54.4881 51.054 56.2631C51.054 58.0381 49.615 59.4771 47.84 59.4771C46.065 59.4771 44.626 58.0381 44.626 56.2631C44.626 54.4881 46.065 53.0491 47.84 53.0491Z" fill="#F7B030"/>
            <path d="M38.1999 5.91089C39.9749 5.91089 41.4139 7.34989 41.4139 9.12489C41.4139 10.8999 39.9749 12.3389 38.1999 12.3389C36.4249 12.3389 34.9859 10.8999 34.9859 9.12489C34.9859 7.34989 36.4249 5.91089 38.1999 5.91089Z" fill="#F7B030"/>
        </svg>
    );
}