import { TextField, TextFieldProps } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Controller, UseFormSetValue, useFormContext } from 'react-hook-form';

interface IMyTextField {
  name: string;
  schema: any;
  type: string
  setValue: UseFormSetValue<any>;
  onChangeValue?: (value: string) => void;
  min?: number;
  max?: number;
  label: string;
  currentValue?:string;
}

const MyTextField: FC<IMyTextField & TextFieldProps> = ({ name, schema, label, type, setValue,onChangeValue,currentValue, min, max, ...otherProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [selectedValue, setSelectedValue] = useState<string>(currentValue || "");
  useEffect(() => {
    if(onChangeValue)
    onChangeValue(selectedValue);
  }, [onChangeValue, selectedValue]);


  return (
    <Controller
      name={name}
      control={control}
      defaultValue={undefined}
      render={({ field }) => (
        <TextField
          {...field}
          type={type}
          label={label}
          onChange={(e) => {
            if (type === "number") {
              const valueAsNumber = parseFloat(e.target.value);
              setValue(name, valueAsNumber);
            } else {
              setValue(name, e.target.value);
              setSelectedValue(e.target.value)
            }
          }}
          error={!!errors.name}
          // helperText={errors.name?.message as string}
          helperText={errors[name]?.message?.toString() ?? ""}
          inputProps={{ min: min, max: max }}
          {...otherProps}

        />
      )}
    />

  );
};

export default MyTextField;
