import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { Provider } from "react-redux";
import { CssBaseline } from "@mui/material";
import { persistor, store } from "./features/redux/store";
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import { PersistGate } from "redux-persist/integration/react";
import { getGlobalConfigs } from "./configs/globalConfigs";

const globalConfigs = getGlobalConfigs();
const baseGqlUrl = `${globalConfigs.apiUrl}/gql/graphql`;

console.log(`%c>>> Running app for ${process.env.REACT_APP_ENV_NAME} environment <<<`, "color:yellow;");
console.table({
  "App Name": globalConfigs.appName,
  "API URL": globalConfigs.apiUrl,
  "GQL URL": baseGqlUrl,
  environment: process.env.REACT_APP_ENV_NAME,
  version: process.env.REACT_APP_VERSION,
  commit: process.env.REACT_APP_VERSION_COMMIT,
  branch: process.env.REACT_APP_VERSION_BRANCH,
});

document.title = globalConfigs.appName;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const httpLink = new HttpLink({ uri: baseGqlUrl });

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("token");

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  }));

  return forward ? forward(operation) : null;
});

const namedLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: `${baseGqlUrl}?${operation.operationName}`,
  }));
  return forward ? forward(operation) : null;
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, namedLink, httpLink]),
  cache: new InMemoryCache(),
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
          <CssBaseline />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
