import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

interface IMessageProps {
  errorChange: boolean;
  errorMessage: string;
  successMessage: string;
}

const MessageComponent: React.FC<IMessageProps> = ({ errorChange, errorMessage, successMessage }) => {
  const message = errorChange ? errorMessage  : successMessage;
  const severity = errorChange ? 'error' : 'success';

  return (
    <Alert severity={severity}>
      <AlertTitle>{message}</AlertTitle>
    </Alert>
  );
};

export default MessageComponent;
