import React from "react";

interface IconAttributes {
    className?: string,
}

export const ChemistryIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <svg width="62" height="63" viewBox="0 0 62 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M49.58 27.9941C49.48 27.9571 49.412 27.8631 49.41 27.7561L49.409 27.6901V23.8841L50.129 20.8381C50.267 20.2541 49.824 19.6941 49.224 19.6941H37.904C37.304 19.6941 36.861 20.2541 36.999 20.8381L37.72 23.8841V27.6901C37.72 27.8001 37.65 27.8971 37.547 27.9341C30.799 30.3701 25.98 36.8391 26.003 44.4321C26.033 54.0761 33.838 61.8561 43.481 61.8551C53.134 61.8551 60.959 54.0301 60.959 44.3771C60.959 36.8701 56.225 30.4691 49.58 27.9941Z" fill="#C7F0FE"/>
            <path d="M29.38 46.8731C29.358 39.7541 33.594 33.6221 39.684 30.8771C40.539 30.4921 41.096 29.6521 41.096 28.7161V27.0641C41.096 26.5731 41.039 26.0831 40.926 25.6051L40.896 25.4771C40.493 23.7711 41.787 22.1341 43.54 22.1341H49.819L50.126 20.8381C50.264 20.2541 49.821 19.6931 49.221 19.6931H37.901C37.301 19.6931 36.858 20.2541 36.996 20.8381L37.716 23.8831V27.6901C37.716 27.8001 37.647 27.8971 37.544 27.9341C30.796 30.3701 25.977 36.8391 26 44.4321C26.02 51.0021 29.649 56.7051 35.009 59.6741C31.56 56.4941 29.396 51.9421 29.38 46.8731Z" fill="#9AE6FB"/>
            <path d="M36.87 42.87L39.722 41.458C42.916 39.878 46.649 39.81 49.897 41.273C51.654 42.064 53.559 42.41 55.447 42.305L56.37 42.187C56.807 42.131 57.203 42.45 57.24 42.889C57.271 43.265 57.287 43.645 57.287 44.027C57.287 51.239 51.697 57.17 44.621 57.714H33.67V43.118L36.87 42.87Z" fill="#6CF5C2"/>
            <path d="M46.52 57.432C46.729 55.909 46.392 54.35 45.516 52.987L38.5 42.062L36.868 42.869L33.668 43.117V57.714H44.62C45.266 57.664 45.901 57.569 46.52 57.432Z" fill="#00F2A6"/>
            <path d="M46.2901 60.023C47.5751 57.669 47.4781 54.917 46.0291 52.661L32.1581 31.063C28.3781 34.279 25.9851 39.076 26.0011 44.432C26.0311 54.076 33.8351 61.856 43.4791 61.855C43.9731 61.855 44.4611 61.834 44.9441 61.794C45.4691 61.288 45.9241 60.694 46.2901 60.023Z" fill="#9AE6FB"/>
            <path d="M47.15 57.278C47.314 55.691 46.937 54.078 46.027 52.661L39.047 41.793L36.871 42.87L33.671 43.117V57.714H44.622C45.49 57.647 46.335 57.499 47.15 57.278Z" fill="#05E29C"/>
            <path d="M44 53.965C46.055 57.164 43.757 61.368 39.957 61.368H5.77201C1.97201 61.368 -0.325987 57.164 1.72901 53.965L15.853 31.972C16.113 31.567 16.251 31.097 16.251 30.616V18.249H29.478V30.616C29.478 31.097 29.616 31.567 29.876 31.972L44 53.965Z" fill="#DFF6FE"/>
            <path d="M24.9399 19.989V19.197H16.2469V30.617C16.2469 31.097 16.1089 31.568 15.8489 31.972L1.72491 53.965C-0.329092 57.164 1.96791 61.369 5.76891 61.369H11.5669C9.68691 59.94 8.97091 57.186 10.4189 54.931L24.5429 32.937C24.8029 32.533 24.9409 32.063 24.9409 31.582V19.989H24.9399Z" fill="#C7F0FE"/>
            <path d="M40.95 55.921C41.281 56.436 41.1189 56.901 40.993 57.131C40.867 57.362 40.5639 57.749 39.952 57.749H5.76795C5.15595 57.749 4.85295 57.362 4.72595 57.131C4.60095 56.901 4.43895 56.436 4.76995 55.921L15.418 39.342C15.664 38.96 16.088 38.731 16.544 38.731C18.032 38.733 19.518 38.966 20.946 39.43C23.674 40.316 26.587 40.359 29.313 39.577C29.882 39.414 30.49 39.635 30.81 40.132L40.95 55.921Z" fill="#FE9A9F"/>
            <path d="M40.95 55.921C41.281 56.436 41.1189 56.901 40.993 57.131C40.867 57.362 40.5639 57.749 39.952 57.749H5.76795C5.15595 57.749 4.85295 57.362 4.72595 57.131C4.60095 56.901 4.43895 56.436 4.76995 55.921L15.418 39.342C15.664 38.96 16.088 38.731 16.544 38.731C18.032 38.733 19.518 38.966 20.946 39.43C23.674 40.316 26.587 40.359 29.313 39.577C29.882 39.414 30.49 39.635 30.81 40.132L40.95 55.921Z" fill="#FE9A9F"/>
            <path d="M10.42 54.9309L20.468 39.2849C19.188 38.9179 17.868 38.7329 16.545 38.7319C16.09 38.7309 15.665 38.9599 15.42 39.3429L4.77203 55.9209C4.44103 56.4369 4.60303 56.9009 4.72803 57.1319C4.85503 57.3619 5.15803 57.7489 5.76903 57.7489H9.65703C9.61003 56.8079 9.84303 55.8299 10.42 54.9309Z" fill="#FD8087"/>
            <path d="M40.95 55.9211C41.281 56.4361 41.1189 56.9011 40.993 57.1311C40.867 57.3621 40.5639 57.7491 39.952 57.7491H5.76795C5.15595 57.7491 4.85295 57.3621 4.72595 57.1311C4.60095 56.9011 4.43895 56.4361 4.76995 55.9211L7.89895 51.0491L13.223 49.7871C15.777 49.1811 18.449 49.2911 20.946 50.1031C22.388 50.5711 23.881 50.8041 25.371 50.8041C27.408 50.8041 29.437 50.3711 31.32 49.5091L35.481 47.6071L35.572 47.5481L40.95 55.9211Z" fill="#FD8087"/>
            <path d="M10.42 54.9311L13.802 49.6641C13.609 49.7021 13.416 49.7421 13.224 49.7871L7.90103 51.0491L4.77203 55.9211C4.44103 56.4371 4.60303 56.9011 4.72803 57.1321C4.85503 57.3621 5.15803 57.7491 5.76903 57.7491H9.65703C9.61003 56.8081 9.84303 55.8301 10.42 54.9311Z" fill="#FE646E"/>
            <path d="M29.21 19.938H16.516C15.567 19.938 14.797 19.168 14.797 18.219C14.797 17.269 15.567 16.499 16.516 16.499H29.21C30.16 16.499 30.929 17.269 30.929 18.219C30.929 19.168 30.16 19.938 29.21 19.938Z" fill="#9AE6FB"/>
            <path d="M51.026 48.24C51.026 49.69 49.85 50.866 48.4 50.866C46.949 50.866 45.773 49.69 45.773 48.24C45.773 46.789 46.949 45.613 48.4 45.613C49.85 45.613 51.026 46.789 51.026 48.24Z" fill="#05E29C"/>
            <path d="M21.4 44.4929C21.4 45.5809 20.518 46.4629 19.43 46.4629C18.342 46.4629 17.46 45.5809 17.46 44.4929C17.46 43.4049 18.342 42.5229 19.43 42.5229C20.518 42.5229 21.4 43.4049 21.4 44.4929Z" fill="#FD8087"/>
            <path d="M25.734 9.61699C25.734 11.204 24.447 12.49 22.86 12.49C21.272 12.49 19.986 11.204 19.986 9.61699C19.986 8.02999 21.272 6.74299 22.86 6.74299C24.447 6.74299 25.734 8.02999 25.734 9.61699Z" fill="#FE9A9F"/>
            <path d="M46.464 12.3689C46.464 13.9559 45.177 15.2419 43.59 15.2419C42.002 15.2419 40.716 13.9559 40.716 12.3689C40.716 10.7819 42.002 9.49494 43.59 9.49494C45.177 9.49494 46.464 10.7819 46.464 12.3689Z" fill="#9CF8D3"/>
            <path d="M17.498 3.47998C17.498 4.55598 16.626 5.42798 15.55 5.42798C14.474 5.42798 13.602 4.55598 13.602 3.47998C13.602 2.40398 14.474 1.53198 15.55 1.53198C16.626 1.53198 17.498 2.40398 17.498 3.47998Z" fill="#FE9A9F"/>
            <path d="M16.25 27.804V31.353L1.72698 53.966C-0.327017 57.164 1.96998 61.369 5.77098 61.369H39.955C43.756 61.369 46.053 57.164 43.999 53.966L34.882 39.77" stroke="black" stroke-width="1.81" stroke-miterlimit="13.333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M32.7961 36.5245L29.4748 31.3521L29.4757 19.938L16.2486 19.9377L16.2482 23.9477" stroke="black" stroke-width="1.81" stroke-miterlimit="13.333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M40.95 55.9209L30.257 39.2709C27.268 40.3569 23.991 40.4209 20.946 39.4299C19.279 38.8879 17.534 38.6609 15.798 38.7489L4.76901 55.9209C4.43901 56.4359 4.60101 56.8999 4.72601 57.1309C4.85201 57.3609 5.15501 57.7479 5.76801 57.7479H39.952C40.564 57.7479 40.867 57.3609 40.993 57.1309C41.119 56.8999 41.281 56.4359 40.95 55.9209Z" stroke="black" stroke-width="1.81" stroke-miterlimit="13.333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.9 51.0484L13.2225 49.7865C15.7779 49.1817 18.4502 49.2912 20.9468 50.1031C24.3567 51.2124 28.0597 51.0004 31.3206 49.509L35.4818 47.6057" stroke="black" stroke-width="1.81" stroke-miterlimit="13.333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M41.1801 61.2111C41.9571 61.3181 42.7511 61.3741 43.5571 61.3741C53.1381 61.3741 60.9051 53.6071 60.9051 44.0261C60.9051 36.4971 56.1071 30.0881 49.4021 27.6891V23.8831L50.1221 20.8371C50.2601 20.2531 49.8171 19.6931 49.2171 19.6931H37.8981C37.2971 19.6931 36.8541 20.2531 36.9931 20.8371L37.7131 23.8831V27.6891C34.8401 28.7171 32.3171 30.4821 30.3721 32.7551" stroke="black" stroke-width="1.81" stroke-miterlimit="13.333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M29.21 19.938H16.516C15.567 19.938 14.797 19.168 14.797 18.219C14.797 17.269 15.567 16.499 16.516 16.499H29.21C30.16 16.499 30.929 17.269 30.929 18.219C30.929 19.168 30.16 19.938 29.21 19.938Z" stroke="black" stroke-width="1.81" stroke-miterlimit="13.333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M44.6225 57.7138C47.0435 57.5275 49.2908 56.7103 51.1985 55.4278" stroke="black" stroke-width="1.81" stroke-miterlimit="13.333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M54.0999 52.808C56.0869 50.426 57.2839 47.364 57.2839 44.027C57.2839 43.645 57.2679 43.266 57.2369 42.889C57.1999 42.45 56.8039 42.131 56.3669 42.187L55.4439 42.305C53.5559 42.41 51.6519 42.064 49.8949 41.273C46.6469 39.81 42.9129 39.878 39.7199 41.458L36.8669 42.87" stroke="black" stroke-width="1.81" stroke-miterlimit="13.333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M45.772 48.2399C45.772 46.7891 46.9481 45.6129 48.399 45.6129C49.8499 45.6129 51.026 46.7891 51.026 48.2399C51.026 49.6908 49.8499 50.8669 48.399 50.8669C46.9481 50.8669 45.772 49.6908 45.772 48.2399Z" stroke="black" stroke-width="1.81" stroke-miterlimit="13.333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.46 44.4929C17.46 43.4049 18.342 42.5229 19.43 42.5229C20.518 42.5229 21.4 43.4049 21.4 44.4929C21.4 45.5809 20.518 46.4629 19.43 46.4629C18.342 46.4629 17.46 45.5809 17.46 44.4929Z" stroke="black" stroke-width="1.81" stroke-miterlimit="13.333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.986 9.61697C19.986 8.0297 21.2727 6.74297 22.86 6.74297C24.4473 6.74297 25.734 8.0297 25.734 9.61697C25.734 11.2042 24.4473 12.491 22.86 12.491C21.2727 12.491 19.986 11.2042 19.986 9.61697Z" stroke="black" stroke-width="1.81" stroke-miterlimit="13.333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M40.716 12.3689C40.716 10.7817 42.0027 9.49492 43.59 9.49492C45.1773 9.49492 46.464 10.7817 46.464 12.3689C46.464 13.9562 45.1773 15.2429 43.59 15.2429C42.0027 15.2429 40.716 13.9562 40.716 12.3689Z" stroke="black" stroke-width="1.81" stroke-miterlimit="13.333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.602 3.47998C13.602 2.40413 14.4742 1.53198 15.55 1.53198C16.6259 1.53198 17.498 2.40413 17.498 3.47998C17.498 4.55583 16.6259 5.42798 15.55 5.42798C14.4742 5.42798 13.602 4.55583 13.602 3.47998Z" stroke="black" stroke-width="1.81" stroke-miterlimit="13.333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}