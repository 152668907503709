import { SvgIcon } from "@mui/material";
import React from "react";

interface IconAttributes {
    className?: string,
}

export const DocumentIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <SvgIcon width="26" height="33" viewBox="0 0 26 33" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.9996 29.8918C23.9996 30.4438 23.5516 30.8918 22.9996 30.8918H2.99952C2.44752 30.8918 1.99952 30.4438 1.99952 29.8918V3.89185C1.99952 3.33985 2.44752 2.89185 2.99952 2.89185H17.9995V6.89184C17.9995 7.99684 18.8945 8.89184 19.9995 8.89184H23.9996V29.8918ZM25.7096 7.18184L19.7095 1.18185C19.5205 0.994853 19.2655 0.890853 18.9995 0.891853H2.99952C1.34251 0.891853 -0.000488281 2.23485 -0.000488281 3.89185V29.8918C-0.000488281 31.5488 1.34251 32.8918 2.99952 32.8918H22.9996C24.6566 32.8918 25.9996 31.5488 25.9996 29.8918V7.89184C26.0016 7.62584 25.8966 7.37084 25.7096 7.18184Z"/>
            <path d="M21.999 24.8931H19.999V26.8931H21.999V24.8931Z"/>
            <path d="M21.9991 14.8936H3.99902V16.8936H21.9991V14.8936Z"/>
            <path d="M21.9991 19.8931H3.99902V21.8931H21.9991V19.8931Z"/>
            <path d="M17.9991 24.8931H3.99902V26.8931H17.9991V24.8931Z"/>
        </SvgIcon>
    );
}