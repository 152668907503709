import api from "../api";

interface ISystemInfoData {
    version: string;
    commit: string;
    branch: string;
    appName: string;
    schoolName: string;
    schoolEmail: string;
    schoolWebsite: string;
  }

export async function GetSystemInfo(): Promise<ISystemInfoData> {
    try {
        const response = await api.get('/system_info/')
        console.log("GetSystemInfo", response.data);
        return response.data as ISystemInfoData;
    } catch (error) {
        console.error("Error occurred while fetching system info:", error);
        throw error;
    }
}