import React from "react";
import Box from "@mui/material/Box";
import Masonry from "react-masonry-css";
import TaskTable from "../components/tables/TaskTable";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../features/redux/store";
import { t } from "../features/i18n";
import AdminTools from "../components/AdminTools";
import EventsTableDetail from "../components/tables/EventsTableDetail";

interface IOverview {}

const Overview: React.FC<IOverview> = () => {
  const breakpoints = {
    default: 2,
    1000: 1,
  };

  const role = useSelector((state: RootState) => state.user.role);
  return (
    <Box>
      {role === "TEACHER" ? (
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="left" spacing={4}>
              <Grid item sm={12}>
                <EventsTableDetail
                  navigationLink="calendar"
                  color="#0058B4"
                  title={t("name_calendar")}
                  limit={7}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div></div>
      )}

      {role === "STUDENT" ? (
        <Masonry
          breakpointCols={breakpoints}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          <TaskTable
            navigationLink="tasks"
            color="#f18733"
            title={t("tasks")}
            limit={7}
          />
          <EventsTableDetail
            navigationLink="calendar"
            color="#0058B4"
            title={t("name_calendar")}
            limit={7}
          />
        </Masonry>
      ) : (
        <div></div>
      )}

      {role === "ADMIN" ? (
        <AdminTools />
      ) : (
        <div></div>
      )}
    </Box>
  );
};

export default Overview;
