import React from "react";
import BlockIcon from "@mui/icons-material/Block";
import { EnglishIcon } from "./icons/subjects/EnglishIcon";
import { CzechIcon } from "./icons/subjects/CzechIcon";
import { MathematicsIcon } from "./icons/subjects/MathematicsIcon";
import { PhysicsIcon } from "./icons/subjects/PhysicsIcon";
import { ChemistryIcon } from "./icons/subjects/ChemistryIcon";
import { HistoryIcon } from "./icons/subjects/HistoryIcon";
import { Hygienics } from "./icons/subjects/Hygienics";
import { InformaticsIcon } from "./icons/subjects/InformaticsIcon";
import { ScienceIcon } from "./icons/subjects/ScienceIcon";
import AddIcon from "@mui/icons-material/Add";
import { CalendarIcon } from "./icons/menu/CalendarIcon";
import { ClassIcon } from "./icons/menu/ClassIcon";
import { CurriculumIcon } from "./icons/menu/CurriculumIcon";
import { DashboardIcon } from "./icons/menu/DashboardIcon";
import { DocumentIcon } from "./icons/menu/DocumentIcon";
import { SingOutIcon } from "./icons/menu/SingOutIcon";
import { SubjectIcon } from "./icons/menu/SubjectIcon";
import { TaskIcon } from "./icons/menu/TaskIcon";
import { VirtualRealityIcon } from "./icons/menu/VirtualRealityIcon";
import { JPGIcon } from "./icons/menu/JPGIcon";
import PDFIcon from "./icons/menu/PDFIcon";
import { WordIcon } from "./icons/menu/WordIcon";

// interface IconAttributes {
//   name: string;
//   icon: React.ElementType;
// }

// function getIconData(): IconAttributes[] {
//   // Toto jsou fallback data, která vrátíme, pokud dojde k chybě v API requestu
//   return [
//     { name: "Matematika", icon: MathematicsIcon },
//     { name: "Fyzika", icon: PhysicsIcon },
//     { name: "Chemie", icon: ChemistryIcon },
//   ];
// }

// function getB(name: string): React.ElementType {
//   let iconType: React.ElementType;
//   let data: IconAttributes[] = getIconData();
//   data.forEach((element) => {
//     console.log("element.name: " + element.name +"==="+"name: " +name)
//     if (element.name === name) {
//       console.log("ANO")
//       iconType = element.icon;
//       return iconType;
//     }
//   });
//   iconType = BlockIcon;

//   return iconType;
// }

// let iconMap = new Map<string, React.ElementType>([
//   ["Matematika", EnglishIcon],
//   ["anglictina", CzechIcon],
// ]);

type Props = {
  iconName?: string;
};

function getIconType(subject?: string): React.ElementType {
  let iconType: React.ElementType;

  switch (subject) {
    case "Chemie":
      iconType = ChemistryIcon;
      break;

    case "Cesky jazyk":
      iconType = CzechIcon;
      break;

    case "Anglicky jazyk":
      iconType = EnglishIcon;
      break;

    case "Historie":
      iconType = HistoryIcon;
      break;

    case "Zdravoveda":
      iconType = Hygienics;
      break;

    case "Informatika":
      iconType = InformaticsIcon;
      break;

    case "Matematika":
      iconType = MathematicsIcon;
      break;

    case "Fyzika":
      iconType = PhysicsIcon;
      break;

    case "Prirodni vedy":
      iconType = ScienceIcon;
      break;

    case "calendar":
      iconType = CalendarIcon;
      break;

    case "myClass":
      iconType = ClassIcon;
      break;

    case "curriculum":
      iconType = CurriculumIcon;
      break;

    case "overview":
      iconType = DashboardIcon;
      break;

    case "dokumenty":
      iconType = DocumentIcon;
      break;

    case "singOut":
      iconType = SingOutIcon;
      break;

    case "subjects":
      iconType = SubjectIcon;
      break;

    case "tasks":
      iconType = TaskIcon;
      break;

    case "virtualReality":
      iconType = VirtualRealityIcon;
      break;

    case "pdf":
      iconType = PDFIcon;
      break;

    case "jpg":
      iconType = JPGIcon;
      break;

    case "word":
      iconType = WordIcon;
      break;

    case "Add":
      iconType = AddIcon;
      break;

    default:
      iconType = BlockIcon;
      break;
  }

  return iconType;
}

const GetIcon = (props: Props) => {
  const IconType = getIconType(props.iconName);
  return (
    <>
      <IconType />
    </>
  );
};

export default GetIcon;
