import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  Alert,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { t } from "../../features/i18n";
import { z } from 'zod';
import { updateClass } from "../../apiRequest/class";
import MessageComponent from "../MessageComponent";
let color: string = "#F88931";

const schema = z.object({
  className: z
    .string()
    .min(1, { message: t("symbol_class_min_one_letter") })
    .max(1, { message: t("symbol_class_max_one_letter") }),
  startYear: z
    .number()
    .min(new Date().getFullYear() - 10, { message: t("year_start_studium_too_low") })
    .max(new Date().getFullYear() + 20, { message: t("year_start_studium_too_high") }),
  graduationYear: z
    .number()
    .min(new Date().getFullYear() - 10, { message: t("year_end_studium_too_low") })
    .max(new Date().getFullYear() + 20, { message: t("year_end_studium_too_high") }),
}).refine((data) => data.startYear !== data.graduationYear, {
  message: t("year_start_end_studium_cant_be_same"),
  path: ["startYear"], // path of error
}).refine((data) => data.startYear <= data.graduationYear, {
  message: t("year_end_studium_cant_be_lower_then_start"),
  path: ["graduationYear"], // path of error
});

type FormData = z.infer<typeof schema>;

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));

interface IUpdateClassModal {
  open: boolean;
  onClose: () => void;
  resetTable: () => void;
  classid: number;
  graduationYear: number;
  description: string;
  startYear: number;
  symbol: string;
};

const UpdateClassModal: React.FC<IUpdateClassModal> = ({ open, onClose, resetTable, classid, graduationYear, description, startYear, symbol }) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const classes = useStyles();
  const [classSymbol, setClassSymbol] = useState('');
  const [classStartYear, setClassStartYear] = useState(0);
  const [classGraduationYear, setClassGraduationYear] = useState(0);

  const { register, handleSubmit, formState: { errors } } = useForm<FormData>();


  const handleClassSymbolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClassSymbol(event.target.value);
  };

  const handleClassStartYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    setClassStartYear(value);
  };

  const handleClassGraduationYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    setClassGraduationYear(value);
  };

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);

      setClassSymbol(symbol)
      setClassStartYear(startYear)
      setClassGraduationYear(graduationYear)
    }

  }, [open]);

  const handleCancel = () => {
    onClose();
  };

  const onSubmit = async (data: FormData) => {
    console.log(data);
    try {
      if (data) {

        const objectUpdateClass: IUpdateClassInput = {
          id: classid,
          graduationYear: classGraduationYear,
          description: "",
          startYear: classStartYear,
          symbol: classSymbol
        };
        console.log("objectUpdateClass", objectUpdateClass)
        setLoading(true)
        await updateClass(objectUpdateClass)
        setTimeout(() => {
          setSuccessAlertOpen(true);
          setTimeout(() => {
            resetTable();
            onClose();
          }, 2000);
        }, 2000);
      } else {
        throw "chyba pri updatovani";
      }

    } catch (error) {
      console.error('Chyba při odesílání:', error);
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {successAlertOpen ? (
          <MessageComponent
            errorChange={errorAlertOpen}
            errorMessage={t("update_class_error")}
            successMessage={t("update_class_success")}
          />
        ) : (
          <Box>
            <Box
              sx={{
                maxHeight: "50px",
                alignText: "left",
                color: color,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "24px",
                    }}
                  >
                    {t("edit_class")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>

              <TextField sx={{ mb: 2, mt: 2 }}
                {...register('className')}
                required
                name="className"
                value={classSymbol}
                onChange={handleClassSymbolChange}
                type="text"
                label={t("symbol_class")}
                error={!!errors.className}
                helperText={errors.className?.message}
                fullWidth
              />
              <TextField sx={{ mb: 2 }}
                {...register('startYear')}
                required
                name="startYear"
                value={classStartYear}
                onChange={handleClassStartYearChange}
                type="number"
                label={t("year_start_studium")}
                error={!!errors.startYear}
                helperText={errors.startYear?.message}
                fullWidth

              />
              <TextField sx={{ mb: 2 }}
                {...register('graduationYear')}
                required
                name="graduationYear"
                value={classGraduationYear}
                onChange={handleClassGraduationYearChange}
                type="number"
                label={t("year_end_studium")}
                error={!!errors.graduationYear}
                helperText={errors.graduationYear?.message}
                fullWidth
              />
              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{
                  width: 140,
                  height: 40,
                  color: color,
                  textTransform: "none",
                  fontWeight: "bold",
                  borderColor: color,
                }}
              >
                {t("cancel")}
              </Button>

              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{
                  width: 140,
                  height: 40,
                  bgcolor: color,
                  "&:hover": {
                    backgroundColor: color,
                  },
                  fontWeight: "bold",
                  position: "absolute",
                  right: "40px",
                }}
              >
                {loading ? t("processing") : t("confirm")}

              </Button>
            </form>
          </Box>

        )}
      </Paper>
    </Modal>
  );
};

export default UpdateClassModal;
