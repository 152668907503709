import React from "react";
import {
  Card,
} from "@mui/material";
import useStyles from "../../configs/styles";
import DocumentsList from "../DocumentsList";

const DocumentsTable: React.FC<IDocumentsTable> = ({
  color,
  resource_id,
  resource_type,
  title,
  studentAssignment_id,
}) => {
  const classes = useStyles({});
  return (
    <Card
      className={classes.tableCard}
      elevation={3}
      style={{ borderTop: "8px solid" + color }}
    >
      <DocumentsList
        resource_id={resource_id}
        resource_type={resource_type}
        studentAssignment_id={studentAssignment_id}
        color={color}
        title={title}
      />
    </Card>
  );
};

export default DocumentsTable;
