import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#ffffff",
    color: "#1e1e1e",
    hoverBg: "#008cfb",
    activeBg: "#e0f1ff"
  },
  topbar: {
    bg: "#fff",
    color: "#000"
  },
  mainBg: colors.grey["100"]
};

export default colorConfigs;