import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  Alert,
  AlertTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "../../features/i18n";
import { object, string, TypeOf } from "zod";
import FormInput from "../navigation/FormInput";
import { gql, useMutation } from "@apollo/client";
import { RootState } from "../../features/redux/store";
import { useSelector } from "react-redux";
import MessageComponent from "../MessageComponent";
let color: string = "#302c2c";

const CHANGE_PASSWORD = gql`
  mutation ($userCode: String!, $password: String!, $oldPassword: String!) {
    changePassword(
      newPassword: $password
      oldPassword: $oldPassword
      userCode: $userCode
    ) {
      ... on RequestError {
        __typename
        message
        title
        code
      }
    }
  }
`;

const registerSchema = object({
  oldpassword: string()
    .min(1, t("password_is_required"))
    .min(8, t("password_min_8_letters"))
    .max(32, t("password_max_32_letters")),
  password: string()
    .min(1, t("password_is_required"))
    .min(8, t("password_min_8_letters"))
    .max(32, t("password_max_32_letters")),

  passwordConfirm: string().min(1, t("please_confirm_password")),
})
  .refine((data) => data.password === data.passwordConfirm, {
    path: ["passwordConfirm"],
    message: t("passwords_not_same"),
  })
  .refine((data) => data.oldpassword !== data.password, {
    path: ["password"],
    message: t("passwords_old_new_same"),
  });

type RegisterInput = TypeOf<typeof registerSchema>;

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));
type IChangePasswordModal = {
  open: boolean;
  onClose: () => void;
};

const ChangePasswordModal: React.FC<IChangePasswordModal> = ({ open, onClose }) => {
  const [changePassowrdMutation,] =
    useMutation(CHANGE_PASSWORD);
  const userCode = useSelector((state: RootState) => state.user.userCode);
  const [loading, setLoading] = useState<boolean>(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const classes = useStyles();
  const methods = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });

  const {
    handleSubmit,

  } = methods;

  const onSubmitHandler: SubmitHandler<RegisterInput> = async (values) => {

    try {
      if (userCode) {
        setLoading(true)
        await handleChangePassowrd(userCode, values.password, values.oldpassword);

        setTimeout(() => {
          setSuccessAlertOpen(true);
          setTimeout(() => {
            onClose();
          }, 2000);
        }, 2000);
      } else {
        throw "chyba pri updatovani";
      }

    } catch (error) {
      console.error('Chyba při odesílání:', error);
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }
  };

  const handleChangePassowrd = async (
    email: string,
    oldPassword: string,
    password: string
  ) => {
    try {
      const response = await changePassowrdMutation({
        variables: {
          userCode: email,
          password: password,
          oldPassword: oldPassword,
        },
      });
      console.log("response:", response);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
    }

  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {successAlertOpen ? (
          <MessageComponent
            errorChange={errorAlertOpen}
            errorMessage={t("password_change_error")}
            successMessage={t("password_changed_successfully")}
          />
        ) : (
          <Box>
            <Box
              sx={{
                maxHeight: "50px",
                alignText: "left",
                color: color,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item sx={{ mr: "20px" }}>
                  <PublishedWithChangesIcon />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "24px",
                    }}
                  >
                    {t("password_form_changer")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <FormProvider {...methods}>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ mt: "15px " }}
                onSubmit={handleSubmit(onSubmitHandler)}
              >
                <FormInput
                  name="oldpassword"
                  required
                  fullWidth
                  label={t("old_password")}
                  type="password"
                  sx={{ mb: 2 }}
                />
                <FormInput
                  name="password"
                  required
                  fullWidth
                  label={t("new_password")}
                  type="password"
                  sx={{ mb: 2 }}
                />
                <FormInput
                  name="passwordConfirm"
                  required
                  fullWidth
                  label={t("confirm_new_password")}
                  type="password"
                  sx={{ mb: 2 }}
                />

                <Button
                  variant="outlined"
                  sx={{
                    width: 140,
                    height: 40,
                    color: color,
                    textTransform: "none",
                    fontWeight: "bold",
                    borderColor: color,
                  }}
                >
                  {t("cancel")}
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  sx={{
                    width: 140,
                    height: 40,
                    bgcolor: color,
                    "&:hover": {
                      backgroundColor: color,
                    },
                    fontWeight: "bold",
                    position: "absolute",
                    right: "40px",
                  }}
                >
                  {loading ? `${t("processing")}` : `${t("confirm")}`}

                </Button>
              </Box>
            </FormProvider>
          </Box>
        )}
      </Paper>
    </Modal>
  );
};

export default ChangePasswordModal;
