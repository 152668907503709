import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  IconButton,
  TableHead,
  styled,
  tableCellClasses,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DocumentIcon } from "../icons/menu/DocumentIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import GetIcon from "../GetIcon";
import { t } from "../../features/i18n";
import { GetStudentAssignment } from "../../apiRequest/task";
import { DownloadDocuments, GetDocumentList, GetDocumentListExp, GetExpectedDocumentList } from "../../apiRequest/documents";
import DocumentsList from "../DocumentsList";
import getFormattedDate, { convertDocumentIcon } from "../../features/GlobalFunction/globalFunction";
import { DownloadIcon } from "../icons/menu/DownloadIcon";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
let color: string = "#999999";

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface ISolutionModal {
  open: boolean;
  onClose: () => void;
  studentAssignmentID: number;
  assignmentID: number;
  studentName: string;
};

const SolutionModal: React.FC<ISolutionModal> = ({ open, onClose, studentAssignmentID, assignmentID,studentName }) => {
  const classes = useStyles();
  const [expecetedDocumentsData, setExpecetedDocumentsData] = useState<IExpectedDocumentsTableData[]>();
  const [expecetedStudentAssignmentData, setExpecetedStudentAssignmentData] = useState<IAssignmentDetailResponseData>();
  const apiCalled = useRef(false);

  const handleCancel = () => {
    onClose();
  };

  function findExpeceted(expected: IDocumentsTableData, uploaded: IDocumentsTableExpData[]): IExpectedDocumentsTableData {
    const exist = uploaded.some(item => item.expected_document_id === expected.id);
    let file_id: number = expected.id
    let file_name: string = expected.name
    uploaded.map((item) => {
      if (item.expected_document_id === expected.id) {
        file_id = item.id
        file_name = item.name
      }
    })
    return {
      id: file_id,
      created_at: expected.created_at,
      name: file_name,
      icon: expected.icon,
      exist: exist
    };
  }

  async function ExpectedDocumentForStudentLogic() {
    try { 
      let studentAssignment:IAssignmentDetailResponseData = await GetStudentAssignment(studentAssignmentID);
      setExpecetedStudentAssignmentData(studentAssignment);
    } catch (error) {
      console.error("Error occurred while get list document:", error);
    }
    try {
      if (studentAssignmentID) {
        let expected: IDocumentsTableData[] = await GetExpectedDocumentList(assignmentID.toString());
        let uploaded: IDocumentsTableExpData[] = await GetDocumentListExp(studentAssignmentID.toString(), "student_assignment")
        let uploaded2: IExpectedDocumentsTableData[] = expected.map(item => findExpeceted(item, uploaded));
        console.log("findExpeceted:", uploaded2)
        setExpecetedDocumentsData(uploaded2)
      }
      console.log("get list document successfully");
    } catch (error) {
      console.error("Error occurred while get list document:", error);
    }

    
  }


  // useEffect(() => {

  //   if (studentAssignmentID) {
  //     GetStudentAssignment(studentAssignmentID)
  //     GetExpectedDocumentList(studentAssignmentID.toString())
  //     GetDocumentList(studentAssignmentID.toString(), "student_assignment")
  //   }

  // }, []);

  useEffect(() => {
    async function loadData(): Promise<void> {
      await ExpectedDocumentForStudentLogic();
    }
    if (!apiCalled.current) {
      loadData();
      apiCalled.current = true;
    }
  }, []);

  useEffect(() => {
    async function loadData(): Promise<void> {
      await ExpectedDocumentForStudentLogic();
      
    }
    loadData();
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        <Box>
          <Box
            sx={{
              maxHeight: "50px",
              alignText: "left",
              color: color,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item sx={{ mr: "20px" }}>
                <DocumentIcon />
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: "24px",
                  }}
                >
                  {t("solution")} - {studentName}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ background: "#D9D9D9", height: "auto", mt: "15px " }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >

              {expecetedStudentAssignmentData?.status === "submited" ?  (<Typography
                sx={{
                  fontSize: "16px",
                  padding: "20px"
                }}
              >
                Dobry den prikladam zde souboru k odevzdani preju pekny den
                Jan Novák
              </Typography>):(<Typography
                sx={{
                  fontSize: "16px",
                  padding: "20px"
                }}
              >
                {t("student_still_working")}
              </Typography>)}
              
            </Grid>
          </Box>

          <TableContainer
            sx={{
              paddingBottom: "20px",
            }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: "50px" }}>

                  </StyledTableCell>
                  <StyledTableCell style={{ width: "140px" }}>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#808080",
                      }}
                    >
                      {t("date")}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "auto" }} align="left">
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#808080",
                      }}
                    >
                      {t("name")}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "200px" }} align="left">
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#808080",
                      }}
                    >
                      {t("file_type")}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expecetedDocumentsData?.map((row, index) => (<>
                  {row.exist ? (
                    <>
                      <StyledTableRow key={index}>
                      {row.icon === "none" ? (
                      <StyledTableCell style={{ width: "50px" }}></StyledTableCell>
                    ) : (<StyledTableCell style={{ width: "50px" }}><CheckCircleIcon sx={{ color: "#72b855" }} /></StyledTableCell>)}
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{ width: "140px" }}
                        >
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            {getFormattedDate(row.created_at)}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "auto" }} align="left">
                          <Typography
                            sx={{
                              fontSize: "15px",
                              color: "#4D4D4D",
                              // fontWeight: "bold",
                            }}
                          >
                            {row.name}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "200px" }} align="left">
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid item sx={{ mr: "20px" }}>
                              <GetIcon iconName={convertDocumentIcon(row.icon)} />
                            </Grid>
                            <Grid item>
                              <Typography>
                                {row.icon}
                              </Typography>
                            </Grid>
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <IconButton
                            sx={{
                              height: "11px",
                              padding: "0px",
                            }}
                            onClick={() => DownloadDocuments(row)}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  ) : (
                    <>
                      <StyledTableRow key={index}>
                        <StyledTableCell style={{ width: "50px" }}><HighlightOffIcon sx={{ color: "red" }} /></StyledTableCell>

                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{ width: "140px" }}
                        >
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            {getFormattedDate(row.created_at)}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "auto" }} align="left">
                          <Typography
                            sx={{
                              fontSize: "15px",
                              color: "#4D4D4D",
                              // fontWeight: "bold",
                            }}
                          >
                            {row.name}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "200px" }} align="left">
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid item sx={{ mr: "20px" }}>
                              <GetIcon iconName={convertDocumentIcon(row.icon)} />
                            </Grid>
                            <Grid item>
                              <Typography>
                                {row.icon}
                              </Typography>
                            </Grid>
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </StyledTableRow>
                    </>
                  )}

                </>
                ))}

              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ mt: "25px " }}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{
                width: 140,
                height: 40,
                color: color,
                textTransform: "none",
                fontWeight: "bold",
                borderColor: color,
              }}
            >
              {t("cancel")}
            </Button>

            {/* <Button
              variant="contained"
              onClick={handleTest}
              sx={{
                width: 140,
                height: 40,
                bgcolor: color,
                "&:hover": {
                  backgroundColor: color,
                },
                fontWeight: "bold",
                position: "absolute",
                right: "40px",
              }}
            >
              {t("add")}
            </Button> */}
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default SolutionModal;
