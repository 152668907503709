import { gql, useApolloClient, useMutation } from "@apollo/client";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import { Box, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import { useSessionExpiration } from "../features/authentication/SessionExpiration.hook";
import { dispatchLogin, parseToken } from "../features/authentication/helpers/token.helper";
import { t } from "../features/i18n";

const LOGIN = gql`
  mutation login($userCode: String!, $password: String!) {
    login(userCode: $userCode, password: $password) {
      ... on LoginOutput {
        token
        role
        userCode
        userName
      }
      ... on RequestError {
        title
        message
      }
    }
  }
`;
export interface ILoginProps {}

const Login: React.FC<ILoginProps> = (props) => {
  const [userNonExist, setUserNonExist] = React.useState(false);
  const client = useApolloClient();
  const [loginMutation, { error, loading, data }] = useMutation(LOGIN);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { activateSessionExpiration } = useSessionExpiration();

  const handleLogin = async (userCode: string, password: string) => {
    try {
      const response = await loginMutation({
        variables: {
          userCode: userCode,
          password: password,
        },
        client,
      });

      const token = response.data.login.token;

      const { isValid, tokenContent } = parseToken(token);
      if (isValid && tokenContent) {
        dispatchLogin(dispatch, tokenContent);
        localStorage.setItem("token", token);
        activateSessionExpiration(true);

        setUserNonExist(false);
        navigate("/overview");
      } else {
        console.error("Invalid token");
      }
    } catch (error) {
      setUserNonExist(true);
    }
  };

  const paperStyle = {
    paddingTop: 45,
    paddingBottom: 75,
    paddingLeft: 100,
    paddingRight: 100,
    width: 644,
    borderTop: "8px solid #f18733",
  };
  return (
    <Paper elevation={10} style={paperStyle}>
      <Box
        sx={{
          zIndex: 1,
          position: "absolute",
          left: "50%",
          top: "20%",
          transform: " translateX(-50%)",
          color: "#FEE9D7",
        }}
      >
        <Person2OutlinedIcon
          sx={{
            fontSize: 100,
          }}
        />
      </Box>
      <Box
        sx={{
          zIndex: 2,
          position: "absolute",
          left: "50%",
          transform: " translateX(-50%)",
        }}
      >
        <Typography variant="h5" color="#f18733">
          {t("login_to_portal")}
        </Typography>
      </Box>
      <LoginForm handleLogin={handleLogin} userNonExist={userNonExist} />
    </Paper>
  );
};

export default Login;
