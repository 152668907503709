import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  Alert,
  TextField,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { t } from "../../features/i18n";
import { z } from 'zod';
import { CreateAssignment, UpdateAssignment } from "../../apiRequest/task";
import MessageComponent from "../MessageComponent";
import { GetAllClasses } from "../../apiRequest/class";
import { CreateUser, GetUserValidation } from "../../apiRequest/user";

let color: string = "#f18733";

const schema = z.object({
  userCode: z.string().min(1, t("name_min_one_symbol")),
  userName: z.string().min(1),
  userEmail: z.string().min(1),
  class: z.string().min(1),
});
type FormData = z.infer<typeof schema>;

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));

interface ICreateStudentUserModal {
  open: boolean;
  onClose: () => void;
  resetTable: () => void;
};

const CreateStudentUserModal: React.FC<ICreateStudentUserModal> = ({ open, onClose, resetTable }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [classesList, setClassesList] = useState<IUpdateClassResponse[]>([]);
  const [showPassword, setShowPassword] = useState<String>();
  const classes = useStyles();
  const [isUserCodeTaken, setIsUserCodeTaken] = useState<boolean>();

  const handleUserCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const userCode = event.target.value;

      GetUserValidation(userCode)
          .then((result) => {

              setIsUserCodeTaken(result);
          })
          .catch((error) => {

              console.error("Error while fetching user:", error);

              setIsUserCodeTaken(false);
          });
  };

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
      setShowPassword("")
    }
  }, [open]);

  useEffect(() => {
    async function loadData(): Promise<void> {
      setClassesList(await GetAllClasses())
    }
    loadData();
  }, []);

  const handleCancel = () => {
    onClose();
  };

  const onSubmit = async (data: FormData) => {
    console.log(data);
    try {
      if (data) {
        const objectCreate: ICreateUserInput = {
          code: data.userCode,
          name: data.userName,
          email: data.userEmail,
          role: "student",
          password: "Heslo12345",
          auto_generate_password: true,
          class_id: parseInt(data.class)
        };
        setLoading(true)
        const response = await CreateUser(objectCreate)
        setShowPassword(response.password);
        setTimeout(() => {
          setSuccessAlertOpen(true);
          // setTimeout(() => {
          // resetTable();
          // onClose();
          // }, 2000);
        }, 2000);
      } else {
        throw "chyba pri updatovani";
      }
    } catch (error) {
      console.error('Chyba při odesílání:', error);
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {successAlertOpen ? (<>
          <MessageComponent
            errorChange={errorAlertOpen}
            errorMessage={t("create_user_error")}
            successMessage={t("create_user_success")}
          />

          <div style={{ backgroundColor: '#f4f4f4', padding: '20px' }}>
            <Typography variant="body1" sx={{ color: 'green', fontWeight: 'bold', backgroundColor: '#e0e0e0', display: 'inline-block', padding: '5px 10px', borderRadius: '4px', textAlign: 'center' }}>
              {showPassword}
            </Typography>
          </div>
          <Button
            variant="outlined"
            onClick={handleCancel}
            sx={{
              width: 140,
              height: 40,
              color: color,
              textTransform: "none",
              fontWeight: "bold",
              borderColor: color,
              mt: 4
            }}
          >
            {t("cancel")}
          </Button></>
        ) : (

          <Box>
            <Box
              sx={{
                maxHeight: "50px",
                alignText: "left",
                color: color,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "24px",
                    }}
                  >
                    {t("create_student")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField sx={{ mb: 2, mt: 2 }}
                {...register('userCode')}
                onChange={handleUserCodeChange}
                required
                label="User Code"
                error={isUserCodeTaken === true}
                helperText={isUserCodeTaken === true ? t("try_diff_student_code") : ""}
                fullWidth
              />

              <TextField sx={{ mb: 2 }}
                {...register('userName')}
                required
                label={t("user_name")}
                error={!!errors.userName}
                helperText={errors.userName?.message}
                fullWidth />

              <TextField sx={{ mb: 2 }}
                {...register('userEmail')}
                required
                label={t("email")}
                error={!!errors.userEmail}
                helperText={errors.userEmail?.message}
                fullWidth />

              <TextField
                sx={{ mb: 2 }}
                {...register('class')}
                select
                label={t("class")}
                error={!!errors.class}
                helperText={errors.class?.message}
                fullWidth
              >

                {classesList.map((item =>
                  <MenuItem value={item.id}>{item.class_name}</MenuItem>
                ))}
              </TextField>

              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{
                  width: 140,
                  height: 40,
                  color: color,
                  textTransform: "none",
                  fontWeight: "bold",
                  borderColor: color,
                }}
              >
                {t("cancel")}
              </Button>

              <Button
                variant="contained"
                type="submit"
                disabled={loading || isUserCodeTaken}
                sx={{
                  width: 140,
                  height: 40,
                  bgcolor: color,
                  "&:hover": {
                    backgroundColor: color,
                  },
                  fontWeight: "bold",
                  position: "absolute",
                  right: "40px",
                }}
              >
                {loading ? t("processing") : t("confirm")}

              </Button>
            </form>
          </Box>
        )}

      </Paper>
    </Modal>
  );
};

export default CreateStudentUserModal;
