import React, { useEffect, useRef, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  Grid,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import ImageButton from "./ImageButton";
import ImageView from "./ImageView";
import { t } from "../../features/i18n";

const photoStyle = {
  maxWidth: "100%",
  maxHeight: "100%",
  width: "auto",
  height: "auto",
  display: "block",
};

interface TS360ViewerProps {
  images: IImageParameters[];
}

const TS360Viewer: React.FC<TS360ViewerProps> = ({ images }) => {
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(1);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [loadStatus, setLoadStatus] = useState(0);
  const [scale, setScale] = useState(1);
  const [selected360Image, setSelected360Image] = useState<IImageParameters>(
    images[0]
  );

  useEffect(() => {
    if (images.length > 1) {
      setSelected360Image(images[0]);
    }
  }, [images]);

  const imagesRef = useRef<HTMLImageElement[]>([]);

  let imageData: any[] = [];
  let a: number = 0;

  useEffect(() => {
    console.log(images);
    setLoadStatus(0);
    handleReset();
    setImagesLoaded(false);
    fetchData();
    renderImages();
  }, [selected360Image]);

  useEffect(() => {
    changePosition();
  }, [currentImageIndex]);

  const changePosition = async (): Promise<void> => {
    if (imagesLoaded === true) {
      const currentImage = imagesRef.current[currentImageIndex - 1];
      setCurrentImageUrl(currentImage.src);
    }
  };

  const renderImages = async (): Promise<void> => {
    await loadImages();
    const currentImage = imagesRef.current[currentImageIndex - 1];

    console.log(`currimg ${currentImage.src} `);
    setCurrentImageUrl(currentImage.src);
    setImagesLoaded(true);
  };

  const loadImage = async (path: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
        a++;
        setLoadStatus(Math.round((a * 100) / selected360Image.amount));
      };
      img.onerror = reject;
      img.src = path;
    });
  };

  const loadImages = async (): Promise<void> => {
    a = 0;
    const images = await Promise.all(imageData.map((path) => loadImage(path)));

    images.sort((a, b) => {
      const aIndex = imageData.indexOf(a.src);
      const bIndex = imageData.indexOf(b.src);
      return aIndex - bIndex;
    });
    imagesRef.current = images;
  };

  const fetchData = () => {
    let fileName2 = "";
    imageData = [];
    for (let i = 1; i <= selected360Image.amount; i++) {
      if (i >= 10) {
        fileName2 = selected360Image.fileName.replace("{index}", i.toString());
      } else {
        fileName2 = selected360Image.fileName.replace(
          "{index}",
          "0" + i.toString()
        );
      }
      const filePath = `${selected360Image.path}/${fileName2}`;
      imageData.push(filePath);
    }
  };

  const handlePreviousPhoto = () => {
    if (selected360Image.rotation === "right") {
      if (currentImageIndex - 1 < 1) {
        setCurrentImageIndex(selected360Image.amount);
      } else {
        setCurrentImageIndex(currentImageIndex - 1);
      }
    } else {
      if (currentImageIndex + 1 > selected360Image.amount) {
        setCurrentImageIndex(1);
      } else {
        setCurrentImageIndex(currentImageIndex + 1);
      }
    }
  };

  const handleNextPhoto = () => {
    if (selected360Image.rotation === "right") {
      if (currentImageIndex + 1 > selected360Image.amount) {
        setCurrentImageIndex(1);
      } else {
        setCurrentImageIndex(currentImageIndex + 1);
      }
    } else {
      if (currentImageIndex - 1 < 1) {
        setCurrentImageIndex(selected360Image.amount);
      } else {
        setCurrentImageIndex(currentImageIndex - 1);
      }
    }
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    if (scale > 0.1) {
      setScale(scale - 0.1);
    }
  };

  const handleReset = () => {
    setScale(1);
    setCurrentImageIndex(1);
  };

  const handleNewModel = (imgNum: number) => {
    setSelected360Image(images[imgNum]);
  };

  return (
    <div>
      <Box
        sx={{
          m: "10px",
          width: "800px",
        }}
      >
        <ImageView
          src={currentImageUrl}
          scale={scale}
          isLoad={imagesLoaded}
          percent={loadStatus}
        />
        <Grid
          container
          spacing={2}
          sx={{
            mt: "10px",
          }}
        >
          <Grid item xs={12 / 5}>
            <Button
              variant="text"
              sx={{
                width: 140,
                height: 40,
                color: "black",
                bgcolor: "white",
                fontWeight: "bold",
              }}
              startIcon={<KeyboardArrowLeftIcon />}
              onClick={() => handlePreviousPhoto()}
            >
              {t("spin_left")}
            </Button>
          </Grid>

          <Grid item xs={12 / 5}>
            <Button
              variant="text"
              sx={{
                width: 140,
                height: 40,
                color: "black",
                bgcolor: "white",
                fontWeight: "bold",
              }}
              startIcon={<KeyboardArrowRightIcon />}
              onClick={() => handleNextPhoto()}
            >
              {t("spin_right")}
            </Button>
          </Grid>

          <Grid item xs={12 / 5}>
            <Button
              variant="text"
              sx={{
                width: 140,
                height: 40,
                color: "black",
                bgcolor: "white",
                fontWeight: "bold",
              }}
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => handleZoomIn()}
            >
              {t("zoom_in")}
            </Button>
          </Grid>
          <Grid item xs={12 / 5}>
            <Button
              variant="text"
              sx={{
                width: 140,
                height: 40,
                color: "black",
                bgcolor: "white",
                fontWeight: "bold",
              }}
              startIcon={<RemoveCircleOutlineIcon />}
              onClick={() => handleZoomOut()}
            >
              {t("zoom_out")}
            </Button>
          </Grid>
          <Grid item xs={12 / 5}>
            <Button
              variant="text"
              sx={{
                width: 140,
                height: 40,
                color: "black",
                bgcolor: "white",
                fontWeight: "bold",
              }}
              startIcon={<RestartAltIcon />}
              onClick={() => handleReset()}
            >
              {t("reset")}
            </Button>
          </Grid>
        </Grid>

        <Typography variant="h5" sx={{ mt: 2 }}>
          {t("pick_model")}
        </Typography>

        <Grid
          container
          spacing={2}
          sx={{
            mt: "5px",
          }}
        >
          {images.map((item) => (
            <Grid key={item.id} item xs={3}>
              <ImageButton
                id={item.id}
                selectImage={handleNewModel}
                selectedNumber={selected360Image.id}
                src={item.firstImagePath}
                description={item.description}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default TS360Viewer;
