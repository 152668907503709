import { Box, Grid } from "@mui/material";
import React from "react";
import TaskTable from "../components/tables/TaskTable";
import { t } from "../features/i18n";

interface ITasks { }

const Tasks: React.FC<ITasks> = () => {
  return (
    <Box>
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="left" spacing={4}>
            <Grid item sm={12}>
              <TaskTable
                color="#f18733"
                title={t("tasks")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Tasks;
