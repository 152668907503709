import { Box, Grid, TextField, TextFieldProps, Theme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Controller, UseFormSetValue, useFormContext } from 'react-hook-form';
import { makeStyles } from "@mui/styles";
import GetIcon from "../GetIcon";

const useStyles = makeStyles<Theme>((theme) => ({
  iconTable: {
    flexDirection: "column",
    display: "flex",
    width: "20%",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#d4e9cc",
    },
    backgroundColor: "#D9D9D9",
    textDecoration: "none",
    color: "inherit",
  },
}));

const iconTable: IIconName[] = [
  { id: 1, name: "Matematika" },
  { id: 2, name: "Informatika" },
  { id: 3, name: "Prirodni vedy" },
  { id: 4, name: "Fyzika" },
  { id: 5, name: "Chemie" },
  { id: 6, name: "Cesky jazyk" },
  { id: 7, name: "Anglicky jazyk" },
  { id: 8, name: "Historie" },
  { id: 9, name: "Zdravoveda" },
];

interface IMyIconField {
  name: string;
  schema: any;
  type: string
  setValue: UseFormSetValue<any>;
  onChangeValue?: (value: string) => void;
  min?: number;
  max?: number;
  label: string;
  currentIcon?:string;
}

const MyIconField: FC<IMyIconField & TextFieldProps> = ({ name, schema, label, type, setValue,currentIcon,onChangeValue, min, max, ...otherProps }) => {
  const classes = useStyles();
  const [selectedIcons, setSelectedIcons] = useState<string>(currentIcon || "");
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const handleIconClick = (iconName: string) => {
    setSelectedIcons(iconName)
  };

  useEffect(() => {
    if(onChangeValue)
    onChangeValue(selectedIcons);
  }, [onChangeValue, selectedIcons]);

  useEffect(() => {
    if(currentIcon)
    setSelectedIcons(currentIcon)
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={undefined}
      render={({ field }) => (
        <Grid
          {...field}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"

        >
          {iconTable.map((icon) => (
            <Box
              key={icon.id}
              className={classes.iconTable}
              style={{
                background: selectedIcons?.includes(icon.name) ? "#d4e9cc" : "none",
              }}
            >
              <Grid
                item
                onClick={() => {
                  setValue(name, icon.name);
                  handleIconClick(icon.name);
                }}
              >
                <GetIcon iconName={icon.name} />

              </Grid>

            </Box>
          ))}
        </Grid>
      )}
    />

  );
};

export default MyIconField;
