import React from "react";
import { SvgIcon } from '@mui/material';
interface IconAttributes {
    className?: string,
}

export const CalendarIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
    <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.1539 21.2977C22.1539 22.0714 21.499 22.702 20.6956 22.702H3.30466C2.50123 22.702 1.84637 22.0714 1.84637 21.2977V9.81389H22.1539V21.2977ZM1.84637 5.44047C1.84637 4.66681 2.50123 4.03622 3.30466 4.03622H4.61552V4.92433C4.61552 5.41406 5.03038 5.81354 5.53781 5.81354C6.04638 5.81354 6.46124 5.41406 6.46124 4.92433V4.03622H17.5378V4.92433C17.5378 5.41406 17.9538 5.81354 18.4613 5.81354C18.9687 5.81354 19.3847 5.41406 19.3847 4.92433V4.03622H20.6956C21.4979 4.03622 22.1539 4.66681 22.1539 5.44047V8.03547H1.84637V5.44047ZM20.6956 2.25779H19.3847V1.36968C19.3847 0.879954 18.9687 0.480469 18.4613 0.480469C17.9538 0.480469 17.5378 0.879954 17.5378 1.36968V2.25779H6.46124V1.36968C6.46124 0.879954 6.04638 0.480469 5.53781 0.480469C5.03038 0.480469 4.61552 0.879954 4.61552 1.36968V2.25779H3.30466C1.4818 2.25779 -0.000488281 3.68515 -0.000488281 5.44047V21.2977C-0.000488281 23.053 1.4818 24.4804 3.30466 24.4804H20.6956C22.5184 24.4804 23.9996 23.053 23.9996 21.2977V5.44047C23.9996 3.68515 22.5184 2.25779 20.6956 2.25779Z"/>
    </SvgIcon>
    );
}