import api from "../api";
import { t } from "../features/i18n";

export async function CreateExpectedDocument(expectedDocumentData: IExpectedDocumentCreateData): Promise<void> {
    try {
        await api.post('/expected_document/create/', expectedDocumentData);
        console.log("Expected document created successfully");
    } catch (error) {
        console.error("Error occurred while creating Expected document:", error);
        throw error;
    }
}

export async function UpdateExpectedDocument(expectedDocumentData: IExpectedDocumentUpdateData): Promise<void> {
    try {
        await api.put('/expected_document/update/', expectedDocumentData);
        console.log("Expected document updated successfully");
    } catch (error) {
        console.error("Error occurred while updating Expected document:", error);
        throw error;
    }
}

export async function DeleteExpectedDocument(documentID: number): Promise<void> {
    try {
        await api.delete('/expected_document/delete/?id=' + documentID);
        console.log("Expected document deleted successfully");
    } catch (error) {
        console.error("Error occurred while delete Expected document:", error);
        throw error;
    }
}

export async function GetExpectedDocumentList(assignment_id: string): Promise<IDocumentsTableData[]> {
    try {
        const response = await api.get('/expected_document/list_by_assignment/?assignment_id=' + assignment_id);
        console.log("GetExpectedDocumentList", response.data);
        const data = response.data;
        const documentsTableData: IDocumentsTableData[] = [];

        if (data.length === 0) {
            documentsTableData.push({
                id: 0,
                created_at: "-.-.----",
                name: t("documents_is_not_there"),
                icon: "none"
            });
        } else {
            data.forEach((item: IDocumentListResponseData) => {
                documentsTableData.push({
                    id: item.id,
                    created_at: item.created_at,
                    name: item.name,
                    icon: item.extension
                });
            });
        }
        return documentsTableData;
    } catch (error) {
        console.error("Error occurred while fetching documets:", error);
        throw error;
    }
}

export async function GetDocumentList(resource_id: string, resource_type: string): Promise<IDocumentsTableData[]> {
    try {
        const response = await api.get('/documents/list/?resource_id=' + resource_id + '&resource_type=' + resource_type);
        console.log("GetDocumentList", response.data);
        const data = response.data;
        const documentsTableData: IDocumentsTableData[] = [];

        if (data.length === 0) {
            documentsTableData.push({
                id: 0,
                created_at: "-.-.----",
                name: t("documents_is_not_there"),
                icon: "none"
            });
        } else {
            data.forEach((item: IDocumentListResponseData) => {
                documentsTableData.push({
                    id: item.id,
                    created_at: item.created_at,
                    name: item.description,
                    icon: item.extension
                });
            });
        }
        return documentsTableData;
    } catch (error) {
        console.error("Error occurred while fetching documets:", error);
        throw error;
    }
}

export async function GetDocumentListExp(resource_id: string, resource_type: string): Promise<IDocumentsTableExpData[]> {
    try {
        const response = await api.get('/documents/list/?resource_id=' + resource_id + '&resource_type=' + resource_type);
        console.log("GetDocumentList", response.data);
        const data = response.data;
        const documentsTableData: IDocumentsTableExpData[] = [];

        if (data.length === 0) {
            documentsTableData.push({
                id: 0,
                created_at: "-.-.----",
                name: t("documents_is_not_there"),
                icon: "none",
                expected_document_id: 0
            });
        } else {
            data.forEach((item: IDocumentListResponseData) => {
                documentsTableData.push({
                    id: item.id,
                    created_at: item.created_at,
                    name: item.name,
                    icon: item.extension,
                    expected_document_id: item.expected_document_id
                });
            });
        }
        return documentsTableData;
    } catch (error) {
        console.error("Error occurred while fetching documets:", error);
        throw error;
    }
}

export async function DeleteDocument(documentID: number): Promise<void> {
    try {
        await api.delete('/documents/?document_id=' + documentID);
        console.log("document deleted successfully");
    } catch (error) {
        console.error("Error occurred while delete document:", error);
        throw error;
    }
}

export async function DownloadDocuments(documentData: IDocumentsTableData): Promise<void> {
    try {
        const response = await api.get('/documents/download/?document_id=' + documentData.id, {
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', documentData.name + "." + documentData.icon || 'document.pdf');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error occurred while delete document:", error);
        throw error;
    }
}