import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import colorConfigs from "../configs/colorConfigs";
import sizeConfigs from "../configs/sizeConfigs";
import Footer from "../components/Footer";
import NavigationBar from "../components/navigation/NavigationBar";
import Sidebar from "../components/sidebar/Sidebar";
import { styled } from "@mui/material/styles";
import { SessionExpirationChecker } from "../features/authentication/SessionExpirationChecker";
import { useSessionExpiration } from "../features/authentication/SessionExpiration.hook";

export interface IMainLayoutProps {}

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  zIndex: -3,
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
  backgroundColor: colorConfigs.mainBg,
});

const MainLayout: React.FC<IMainLayoutProps> = (props) => {
  const [open, setOpen] = React.useState(true);
  const { activateSessionExpiration } = useSessionExpiration();

  // FIXME this effect is rerendering childs
  useEffect(() => {
    console.log("MainLayout: useEffect");
    activateSessionExpiration(true);
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const Main = styled("div")(({ theme }) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100vh",
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: open ? `calc(100% - ${sizeConfigs.sidebar.width})` : `calc(100%)`,

    [theme.breakpoints.up("lg")]: {
      paddingTop: APP_BAR_DESKTOP + 24,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    <StyledRoot>
      <SessionExpirationChecker />
      <NavigationBar />
      <Sidebar handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} open={open} />
      <Main>
        <Outlet />
      </Main>

      <Footer />
    </StyledRoot>
  );
};

export default MainLayout;
