import React, { ReactElement, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Masonry from "react-masonry-css";
import TaskAssignment from "../components/TaskAssignment";
import DocumentsTable from "../components/tables/DocumentsTable";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { Alert, Button, Grid, Typography } from "@mui/material";
import GetIcon from "../components/GetIcon";
import { Link, useNavigate, useParams } from "react-router-dom";
import { t } from "../features/i18n";
import SubjectButton from "../components/SubjectButton";
import { gql, useQuery } from '@apollo/client';
import api from "../api";
import getFormattedDate from "../features/GlobalFunction/globalFunction";
import StudentsAssignmentTable from "../components/tables/StudentsAssignmentTable";
import UpdateTaskModal from "../components/modals/UpdateTaskModal";
import { DeleteAssignment } from "../apiRequest/task";
import ApproveModal from "../components/modals/ApproveModal";

const GET_SUBJECT_BY_ID = gql`
  query GetSubject($id: Int!) {
    subject(subjectId: $id) {
      icon
      id
      name
      description
    }
  }
`;

const buttonRows: IButton[] = [
  { id: 1, label: t("assignment"), name: "overview", color: "#f18733" },
  { id: 2, label: t("student"), name: "curriculum", color: "#999999" },
];

interface ITaskDetails { }

const TaskDetails: React.FC<ITaskDetails> = () => {
  const [responseData, setResponseData] = useState<IAssignmentDetailResponseData>();
  const { data: subjectData, refetch: GetSubjectIcon } = useQuery(GET_SUBJECT_BY_ID, {
    variables: { id: responseData?.subject_id }
  });
  const [openUpdateTaskModal, setOpenUpdateTaskModal] = useState(false);
  const [iconName, setIconName] = useState("");
  const [selectedButton, setSelectedButton] = useState(1);
  const [responseError, setResponseError] = useState(false);
  const { tasksId } = useParams();
  const [targetRow, setTargetRow] = useState(0);
  const [approveDeleteTaskModal, setApproveDeleteTaskModal] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);

  const handleButtonClick = (buttonNumber: number) => {
    setSelectedButton(buttonNumber);
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleDeleteTask = (tasksId?: string) => {
    if (tasksId) {
      setTargetRow(parseInt(tasksId));
      setApproveDeleteTaskModal(true);
    }
  };

  const handleDeleteTaskApproved = async () => {
    try {
      await DeleteAssignment(targetRow.toString());
      setApproveDeleteTaskModal(false);
      handleGoBack()
    } catch (error) {
      setApproveDeleteTaskModal(false);
      setErrorAlertOpen(true);
      setTimeout(() => {
        setErrorAlertOpen(false);
      }, 2000);
    }
  };

  async function GetAssignmentDetail() {
    try {
      api.get('/assignment/?id=' + tasksId)
        .then((response) => {
          console.log("GetAssignmentDetail");
          console.log(response.data);
          const data = response.data;
          let assignmentDetailData: IAssignmentDetailResponseData
          if (data.length === 0) {
            console.log("GetAssignmentDetail response data are empty");
            assignmentDetailData = ({
              id: 0,
              created_at: "-.-.----",
              updated_at: "-.-.----",
              created_by: "-.-.----",
              updated_by: "-.-.----",
              subject_id: 0,
              assignment_type: "",
              status: "",
              name: "",
              description: "",
              start: "-.-.----",
              end: "-.-.----",
            })
            console.log("data.id:", data.id);
            setResponseData(assignmentDetailData);
          } else {
            setResponseData(data);
            const testdata = GetSubjectIcon()
            console.log("testdata:", testdata);
          }
        })
        .catch((error) => {
          console.error(error);
          setResponseError(true)
        });
    } catch (error) {
      console.error(error);
      setResponseError(true)
    }
  }

  const selectedPageStatus = (status?: string): ReactElement<any, any> => {
    let color: string;
    let message: string;

    switch (status) {
      case "new":
        color = "#FCBB41";
        message = t("new");
        break;
      case "in_progress":
        color = "#71BD55";
        message = t("in_progress");
        break;
      case "closed":
        color = "#008CFB";
        message = t("closed");
        break;
      default:
        color = "black";
        message = t("unknown");
    }

    return (
      <>
        <Brightness1Icon
          sx={{
            color: { color },
          }}
        />
        <Typography
          sx={{
            fontSize: "20px",
            color: "#333333",
            ml: "15px",
          }}
        >
          {message}
        </Typography>
      </>
    );
  };

  const breakpoints = {
    default: 2,
    1000: 1,
  };

  useEffect(() => {
    async function loadData(): Promise<void> {
      await GetAssignmentDetail();
    }
    loadData();
  }, []);

  useEffect(() => {
    if (subjectData?.subject.icon) {
      setIconName(subjectData?.subject.icon)
    }
  }, [subjectData]);

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', position: 'absolute', right: 16 }}>
        <Button
          variant="outlined"
          onClick={handleGoBack}
          sx={{
            width: 184,
            height: 40,
            color: "#72b855",
            textTransform: 'none',
            fontWeight: 'bold',
            borderColor: "#72b855",
            marginBottom: 2,
          }}
        >
          {t("back")}
        </Button>

        <Button
          variant="outlined"
          onClick={() => setOpenUpdateTaskModal(true)}
          sx={{
            width: 184,
            height: 40,
            color: "#F88931",
            textTransform: 'none',
            fontWeight: 'bold',
            borderColor: "#F88931",
            marginBottom: 2,
          }}
        >
          {t("edit_task")}
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleDeleteTask(tasksId)}
          sx={{
            width: 184,
            height: 40,
            color: "red",
            textTransform: 'none',
            fontWeight: 'bold',
            borderColor: "red",
          }}
        >
          {t("delete_task")}
        </Button>

      </Box>
      <Typography variant="h4" sx={{ mb: 5 }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item sx={{ mr: "36px" }}>
            <GetIcon iconName={iconName} />
          </Grid>
          <Grid item>{responseData?.name}</Grid>
        </Grid>
      </Typography>


      <Box display="flex" alignItems="center" sx={{ mb: "45px " }}>
        {selectedPageStatus(responseData?.status)}
        <Typography
          sx={{
            fontSize: "16px",
            color: "#333333",
            ml: "50px",
          }}
        >
          {t("beginning")}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            color: "#333333",
            ml: "11px",
          }}
        >
          {responseData ? getFormattedDate(responseData.start.toString()) : ""}
        </Typography>

        <Typography
          sx={{
            fontSize: "16px",
            color: "#333333",
            ml: "50px",
          }}
        >
          {t("submission")}
        </Typography>

        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            color: "#333333",
            ml: "11px",
          }}
        >
          {responseData ? getFormattedDate(responseData.end.toString()) : ""}
        </Typography>
      </Box>
      <Box sx={{ mb: "45px " }}>
        {buttonRows.map((buttonRow) => (
          <SubjectButton
            id={buttonRow.id}
            key={buttonRow.id}
            label={buttonRow.label}
            selectedButton={selectedButton}
            name={buttonRow.name}
            color={buttonRow.color}
            submit={handleButtonClick}
          />
        ))}
        {/* <Button
          component={Link}
          to={"/subjects"}
          variant="outlined"
          sx={{
            width: 184,
            height: 40,
            color: "#71BD55",
            textTransform: "none",
            fontWeight: "bold",
            borderColor: "#71BD55",
            position: "absolute",
            right: "220px",
          }}
        >
          {t("save")}
        </Button>
        <Button
          variant="contained"
          sx={{
            width: 184,
            height: 40,
            bgcolor: "#71BD55",
            "&:hover": {
              backgroundColor: "#71BD55",
            },
            fontWeight: "bold",
            position: "absolute",
            right: "16px",
          }}
        >
          {t("post")}
        </Button> */}
      </Box>
      {selectedButton === 1 ? (
        <Masonry
          breakpointCols={breakpoints}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          <TaskAssignment
            color="#f18733"
            title={t("task_assignment")}
            content={responseData?.description}
            assignmentID={Number(tasksId)}
          />
          <DocumentsTable
            color="#999999"
            title={t("required_documents")}
            resource_id={Number(tasksId)}
            resource_type="expected_document"

          />
          <DocumentsTable
            color="#41BFAF"
            title={t("name_documents")}
            resource_id={Number(tasksId)}
            resource_type="assignment"

          />
        </Masonry>
      ) : (
        <div></div>
      )}
      {selectedButton === 2 ? (
        <StudentsAssignmentTable
          color="#999999"
          title={t("students")}
          assignmentID={Number(tasksId)}
        />
      ) : (
        <div></div>
      )}

      <UpdateTaskModal
        open={openUpdateTaskModal}
        resetTable={GetAssignmentDetail}
        onClose={() => {
          setOpenUpdateTaskModal(false);
        }}
        AssignmentData={responseData}
      />
      <ApproveModal
        message={t("really_want_delete_task")}
        open={approveDeleteTaskModal}
        Approve={() => {
          handleDeleteTaskApproved()
        }}
        Denied={() => setApproveDeleteTaskModal(false)}
      />
      <div style={{ position: 'relative' }}>
        {/* Div pro ztmavení pozadí */}
        {errorAlertOpen && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Částečně průhledné černé pozadí
              zIndex: 9998, // Pod Alert, ale nad všemi ostatními prvky
            }}
          />
        )}

        {/* Alert komponenta */}
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999, // Zajišťuje, že bude Alert na popředí
        }}>
          <Alert severity="error" sx={{ display: errorAlertOpen ? 'inline-block' : 'none' }}>
          {t("delete_task_error")}
          </Alert>
        </div>
      </div>
    </Box>
  );
};

export default TaskDetails;
