import { Avatar, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../features/redux/store";
import React from "react";
import ChangePasswordModal from "../modals/ChangePasswordModal";
import { t } from "../../features/i18n";

export interface IAvatarMenu {}

const AvatarMenu: React.FC<IAvatarMenu> = (props) => {
  const userName = useSelector((state: RootState) => state.user.userName);
  const role = useSelector((state: RootState) => state.user.role);
  const userCode = useSelector((state: RootState) => state.user.userCode) || "";
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePassowrd = () => {
    setOpenModal(true);
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Typography
          sx={{
            color: "inherit",
          }}
        >
          {userName}
        </Typography>
        <Typography
          sx={{
            color: "inherit",
          }}
        >
          ({role})
        </Typography>
        <Avatar sx={{ bgcolor: "purple" }}>{userCode[0]}</Avatar>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={handleClose}>{t("profile")}</MenuItem> */}
        <MenuItem onClick={handleChangePassowrd}>{t("change_password")}</MenuItem>
        {/* <MenuItem onClick={handleClose}>{t("logout")}</MenuItem> */}
      </Menu>
      <ChangePasswordModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      />
    </>
  );
};

export default AvatarMenu;
