import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import ClassItem from "../components/ClassItem";
import { useQuery } from "@apollo/client";
import { queries } from "../features/graphql/queries";
import AddClass from "../components/AddClass";
import CreateNewClassModal from "../components/modals/CreateNewClassModal";

interface IClasses { }

const Class: React.FC<IClasses> = () => {
  const { data, refetch } = useQuery<IClassesResponse>(queries.GET_CLASSES);
  const [openAddClassModal, setOpenAddClassModal] = React.useState(false);
  useEffect(() => {
    refetch();
  }, [refetch,]);

  const handleAddClass = () => {
    setOpenAddClassModal(true);
  };

  return (
    <Box>
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="left" spacing={4}>
            {data?.classes.map((classItem, index) => (
              <Grid key={classItem.id} item>
                <ClassItem id={index} className={classItem.className} classID={classItem.id} />
              </Grid>
            ))}

            <Grid item>
              <AddClass onClick={handleAddClass} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CreateNewClassModal
        open={openAddClassModal}
        onClose={() => {
          setOpenAddClassModal(false);
        }}
        resetTable={refetch}
      />
    </Box>
  );
};

export default Class;
