import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import { TaskIcon } from "../icons/menu/TaskIcon";
import { t } from "../../features/i18n";
import useStyles from "../../configs/styles";
import { RootState } from "../../features/redux/store";
import { useSelector } from "react-redux";
import api from "../../api";
import { getFormattedDayDate } from "../../features/GlobalFunction/globalFunction";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';

import TaskTableLegend from "./TaskTableLegend";
import { GetAssignmentForStudent, GetAssignmentListBySubject, GetAssignmentListForTeacher } from "../../apiRequest/task";
import CreateTaskModal from "../modals/CreateTaskModal";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TaskTable: React.FC<IAssignmentTable> = ({
  color,
  title,
  buttonName,
  addButton,
  navigationLink,
  select,
  limit,
  subjectID,
}) => {
  const classes = useStyles();
  const role = useSelector((state: RootState) => state.user.role);
  const userCode = useSelector((state: RootState) => state.user.userCode);
  const [openCreateTaskModal, setOpenCreateTaskModal] = useState(false);
  const [tableData, setTableData] = useState<IAssignmentTableData[]>([{
    id: 0,
    assignment_type: "",
    status: "",
    name: t("loading"),
    description: "",
    end: "",
    subjectName: ""
  }]);

  const [responseError, setResponseError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  function getCircleColor(status: string | undefined): string {
    if (status)
      switch (status.toLowerCase()) {
        case "new": {
          return "#FCBB41";
        }
        case "in_progress": {
          return "#008CFB";
        }
        case "submitted": {
          return "#008CFB";
        }
        case "returned": {
          return "#008CFB";
        }
        case "closed": {
          return "#71BD55";
        }
        default: {
          return "gray";
        }
      }
    return "gray";
  }

  function getAssignmentTypeIcon(status: string | undefined): JSX.Element {
    if (status) {
      switch (status.toLowerCase()) {
        case "homework": {
          return <CottageOutlinedIcon sx={{ fontSize: "16px", color: "#71BD55" }} />;
        }
        case "test": {
          return <ErrorOutlineOutlinedIcon sx={{ fontSize: "16px", color: "red" }} />;
        }
        default: {
          return <BlockOutlinedIcon sx={{ fontSize: "16px", color: "gray" }} />;
        }
      }
    }
    return <BlockOutlinedIcon sx={{ fontSize: "16px", color: "gray" }} />;
  }
  async function loadData(): Promise<void> {
    if (userCode) {
      if (role === "TEACHER" || role === "ADMIN") {
        try {
          if (subjectID) {

            const assignmentData = await GetAssignmentListBySubject(subjectID);
            setTableData(assignmentData);
          } else {
            const assignmentData = await GetAssignmentListForTeacher(userCode);
            setTableData(assignmentData);
          }

        } catch (error) {
          console.error("Chyba při získávání úkolů pro studenta:", error);
        }
      }

      if (role === "STUDENT") {
        try {
          const assignmentData = await GetAssignmentForStudent(userCode, subjectID?.toString());
          setTableData(assignmentData);
        } catch (error) {
          console.error("Chyba při získávání úkolů pro studenta:", error);
        }
      }
    }
  }
  useEffect(() => {

    loadData();
  }, []);
  return (
    <Card
      className={classes.tableCard}
      elevation={3}
      style={{ borderTop: "8px solid" + color }}
    >
      <Box className={classes.tableCardContent}>
        <CardContent
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "0px",
          }}
        >
          <Typography
            className={classes.tableTitle}
            sx={{
              color: color,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item sx={{ mr: "20px" }}>
                <TaskIcon />
              </Grid>
              <Grid item>{title}</Grid>
            </Grid>
          </Typography>
        </CardContent>
        {role === "TEACHER" && subjectID ? (
          <Button
            variant="outlined"
            className={classes.tableButton}
            sx={{
              color: color,
              borderColor: color,
              "&:hover": {
                color: "white",
                backgroundColor: color,
              },
            }}
            onClick={() => setOpenCreateTaskModal(true)}
          >
            {t("add_task")}
          </Button>
        ) : (
          <div></div>
        )}


      </Box>
      <Box>
        <TableContainer
          sx={{
            paddingBottom: "20px",
          }}
        >
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {subjectID ? (<></>) : (
                  <StyledTableCell style={{ width: "200px" }}>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#808080",
                      }}
                    >
                      {t("subject")}
                    </Typography>
                  </StyledTableCell>)}

                <StyledTableCell style={{ width: "auto" }} align="left">
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#808080",
                    }}
                  >
                    {t("task")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell style={{ width: "50px" }} align="center">
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#808080",
                    }}
                  >
                    {t("type")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: "50px" }}
                  align="right"
                ></StyledTableCell>
                <StyledTableCell style={{ width: "100px" }} align="right">
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#808080",
                    }}
                  >
                    {t("submiting")}
                  </Typography>
                </StyledTableCell>


                <StyledTableCell
                  style={{ width: "50px" }}
                  align="right"
                ></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(limit ? tableData.slice(0, Math.min(limit, tableData.length)) : tableData).map((row) => (
                <StyledTableRow key={row.id}>

                  {subjectID ? (<></>) : (
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{ width: "200px" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#4D4D4D",
                        }}
                      >
                        {row.subjectName}
                      </Typography>

                    </StyledTableCell>)}


                  <StyledTableCell style={{ width: "auto" }} align="left">
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "#4D4D4D",
                      }}
                    >
                      {row.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "50px" }} align="center">
                    {getAssignmentTypeIcon(row.assignment_type)}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "50px" }} align="center">

                    <CircleOutlinedIcon
                      sx={{
                        fontSize: "16px",
                        color: getCircleColor(row.status),
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "100px" }} align="right">
                    <Typography
                      sx={{
                        fontSize: "15px",
                      }}
                    >
                      {getFormattedDayDate(row.end)}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell style={{ width: "50px" }} align="right">
                    {role === "STUDENT" ? (
                      <IconButton
                        component={Link}
                        to={"/taskDetailStudent/" + row.id}
                        sx={{
                          height: "11px",
                          padding: "0px",
                          color: color,
                        }}
                      >
                        <EastIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        component={Link}
                        to={"/taskDetail/" + row.id}
                        sx={{
                          height: "11px",
                          padding: "0px",
                          color: color,
                        }}
                      >
                        <EastIcon />
                      </IconButton>
                    )}

                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ mt: "30px ", mb: "20px" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {navigationLink && !select ? (
              <Button
                component={Link}
                to={"/" + navigationLink}
                onClick={select}
                variant="outlined"
                sx={{
                  width: 216,
                  height: 40,
                  color: color,
                  textTransform: "none",
                  fontWeight: "bold",
                  borderColor: color,
                  "&:hover": {
                    color: "white",
                    backgroundColor: color,
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {t("all_task")}
                </Typography>
              </Button>
            ) : null}
            {!navigationLink && select ? (
              <Button

                onClick={select}
                variant="outlined"
                sx={{
                  width: 216,
                  height: 40,
                  color: color,
                  textTransform: "none",
                  fontWeight: "bold",
                  borderColor: color,
                  "&:hover": {
                    color: "white",
                    backgroundColor: color,
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {t("all_task")}
                </Typography>
              </Button>
            ) : null}
            <Box
              sx={{
                width: "60%",
              }}
            >
              <TaskTableLegend />
            </Box>
          </Box>
        </Box>
      </Box>
      <CreateTaskModal
        open={openCreateTaskModal}
        onClose={() => setOpenCreateTaskModal(false)}
        resetTable={loadData}
        subject_id={subjectID}
      />
    </Card>

  );
}

export default TaskTable;
