import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CurriculumIcon } from "../icons/menu/CurriculumIcon";
import { Link } from "react-router-dom";
import useStyles from "../../configs/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../features/redux/store";
import EastIcon from "@mui/icons-material/East";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ApproveModal from "../modals/ApproveModal";
import api from "../../api";
import CreateCurriculumModal from "../modals/CreateCurriculumModal";
import { getFormattedDayDate } from "../../features/GlobalFunction/globalFunction";
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import AssignCurriculumModal from "../modals/AssignCurriculumModal";
import { t } from '../../features/i18n';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface ICurriculumTable {
  color: string;
  title: string;
  addButton?: () => void;
  subjectId?: string;
}

const CurriculumTable: React.FC<ICurriculumTable> = ({
  color,
  title,
  addButton,
  subjectId,
}) => {
  const classes = useStyles({});
  const role = useSelector((state: RootState) => state.user.role);
  const [approveModal, setApproveModal] = useState(false);
  const [approveUnassignModal, setApproveUnassignModal] = useState(false);
  const [targetRow, setTargetRow] = useState<CurriculumsResponseBody>();
  const [responseError, setResponseError] = useState(false);
  const [responseData, setResponseData] = useState<CurriculumsResponseBody[]>([]);
  const [openCreateCurriculumModalModal, setOpenCreateCurriculumModal] = React.useState(false);
  const [openAssignCurriculumModal, setOpenAssignCurriculumModal] = React.useState(false);

  const [isLoaded, setIsLoaded] = useState(false);

  const handleDeleteCurriculum = (doc: CurriculumsResponseBody) => {
    if (doc) {
      setTargetRow(doc);
      setApproveModal(true);
    }
  };

  const handleUnassignCurriculum = (doc: CurriculumsResponseBody) => {
    if (doc) {
      setTargetRow(doc);
      setApproveUnassignModal(true);
    }
  };

  const reload = async () => {
    if (subjectId) {
      await GetCurriculumsForSubject(subjectId);
    } else {
      await GetAllCurriculums();
    }
  };

  async function GetCurriculumsForTeacher() {
    try {
      api.get('/curriculums/list_for_teacher/')
        .then((response) => {
          console.log("response.data");
          console.log(response.data);
          const data = response.data;
          if (data.length === 0) {
            console.log("response.data are empty");
          } else {
            console.log("are not empty");
          }
          setIsLoaded(true)
        })
        .catch((error) => {
          console.error(error);
          setResponseError(true)
        });
    } catch (error) {
      console.error(error);
      setResponseError(true)
    }
  }

  async function GetAllCurriculums() {
    try {
      api.get('/curriculums/list/')
        .then((response) => {
          console.log("response.data");
          console.log(response.data);
          const data = response.data;
          const curriculumTableRowsData: CurriculumsResponseBody[] = []
          if (data.length === 0) {
            console.log("response.data are empty");
            curriculumTableRowsData.push({
              id: 0,
              created_at: "-.-.----",
              updated_at: "-.-.----",
              created_by: "",
              updated_by: "",
              name: "",
              content: t("curriculum_is_not_there"),
              icon: ""
            });
            console.log("data.id:", data.id);
            setResponseData(curriculumTableRowsData);

          } else {
            console.log("are not empty");
            response.data.map((data: CurriculumsResponseBody) => {
              curriculumTableRowsData.push(data);
            });
            console.log("curriculumTableRowsData:", curriculumTableRowsData);
            setResponseData(curriculumTableRowsData);
            setIsLoaded(true)
          }
        })
        .catch((error) => {
          console.error(error);
          setResponseError(true)
        });
    } catch (error) {
      console.error(error);
      setResponseError(true)
    }
  }


  async function GetCurriculumsForSubject(id: string) {
    try {
      api.get('/curriculums/list/?subject_id=' + id)
        .then((response) => {
          console.log("response.data");
          console.log(response.data);
          const data = response.data;
          const curriculumTableRowsData: CurriculumsResponseBody[] = []
          if (data.length === 0) {
            console.log("response.data are empty");
            curriculumTableRowsData.push({
              id: 0,
              created_at: "-.-.----",
              updated_at: "-.-.----",
              created_by: "",
              updated_by: "",
              name: "",
              content: t("name_subject_too_long"),
              icon: ""
            });
            console.log("data.id:", data.id);
            setResponseData(curriculumTableRowsData);

          } else {
            console.log("are not empty");
            response.data.map((data: CurriculumsResponseBody) => {
              curriculumTableRowsData.push(data);
            });
            console.log("GetCurriculumsForSubject:", curriculumTableRowsData);
            setResponseData(curriculumTableRowsData);
            setIsLoaded(true)
          }
        })
        .catch((error) => {
          console.error(error);
          setResponseError(true)
        });
    } catch (error) {
      console.error(error);
      setResponseError(true)
    }
  }

  async function deleteCurriculum() {
    try {
      api.delete('/curriculums/delete/?curriculum_id=' + targetRow?.id)
        .then(async (response) => {
          console.log("DeleteDocuments succesful");
          console.log(response.data);
          await reload();

        })
        .catch((error) => {
          console.error(error);
          setResponseError(true)
        });
    } catch (error) {
      console.error(error);
      setResponseError(true)
    }
  }

  async function UnassignCurriculum() {
    if (targetRow?.id && subjectId) {
      const objectUnassignCurriculum: IUnassignCurriculumData = {
        curriculum_id: targetRow?.id,
        subject_id: parseInt(subjectId),
      }
      console.log("handleAssignCurriculum:", objectUnassignCurriculum);
      try {
        api.post('/curriculum_subject/unassign_curriculum_from_subject/', objectUnassignCurriculum)
          .then(async (response) => {
            console.log("UnassignCurriculum succesful");
            await reload();
          })
          .catch((error) => {
            console.error(error);
            setResponseError(true)
          });
      } catch (error) {
        console.error(error);
        setResponseError(true)
      }
    }
  }
  useEffect(() => {
    async function loadData(): Promise<void> {
      if (subjectId) {
        await GetCurriculumsForSubject(subjectId);
      } else {
        await GetAllCurriculums();
      }
    }
    loadData();
  }, []);

  return (
    <Card
      className={classes.tableCard}
      elevation={3}
      style={{ borderTop: "8px solid" + color }}
    >
      <Box className={classes.tableCardContent}>
        <CardContent
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "0px",
          }}
        >
          <Typography
            className={classes.tableTitle}
            sx={{
              color: color,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item sx={{ mr: "20px" }}>
                <CurriculumIcon />
              </Grid>
              <Grid item>{title}</Grid>
            </Grid>
          </Typography>
        </CardContent>
        {role === "TEACHER" ? (<>
          {subjectId ? (
            <Button
              variant="outlined"
              className={classes.tableButton}
              sx={{
                color: color,
                borderColor: color,
                "&:hover": {
                  color: "white",
                  backgroundColor: color,
                },
              }}
              onClick={() => setOpenAssignCurriculumModal(true)}
            >
              {t("assign_curriculum")}
            </Button>
          ) : (<Button
            variant="outlined"
            className={classes.tableButton}
            sx={{
              color: color,
              borderColor: color,
              "&:hover": {
                color: "white",
                backgroundColor: color,
              },
            }}
            onClick={() => setOpenCreateCurriculumModal(true)}
          >
            {t("add_curriculum")}
          </Button>)}
        </>
        ) : (
          <div></div>
        )}
      </Box>

      <TableContainer
        sx={{
          paddingBottom: "20px",
        }}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: "100px" }}>
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#808080",
                  }}
                >
                  {t("date")}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ width: "auto" }} align="left">
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#808080",
                  }}
                >
                  {t("name")}
                </Typography>
              </StyledTableCell>
              {role === "TEACHER" ? (<>
                {subjectId ? (
                  <StyledTableCell style={{ width: "30px" }} align="right">

                  </StyledTableCell>
                ) : (<></>)}
                <StyledTableCell
                  style={{ width: "30px" }}
                  align="right"
                ></StyledTableCell>
              </>
              ) : (
                <div></div>
              )}
              <StyledTableCell
                style={{ width: "30px" }}
                align="right"
              ></StyledTableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {responseData?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell style={{ width: "100px" }}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "#4D4D4D",
                    }}
                  >
                    {getFormattedDayDate(row.created_at)}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    sx={{
                      fontSize: "15px",
                    }}
                  >
                    {row.content}
                  </Typography>
                </StyledTableCell>
                {row.name === "" ? (
                  <>
                    <StyledTableCell style={{ width: "30px" }} align="right" />
                    <StyledTableCell style={{ width: "30px" }} align="right" />
                    <StyledTableCell style={{ width: "30px" }} align="right" />
                  </>
                ) : (
                  <>
                    {role === "TEACHER" ? (<>
                      {subjectId ? (
                        <StyledTableCell style={{ width: "30px" }} align="right">
                          <IconButton
                            onClick={() => handleUnassignCurriculum(row)}
                            sx={{
                              height: "11px",
                              padding: "0px",
                              color: color,
                            }}
                          >
                            <UnpublishedIcon />
                          </IconButton>
                        </StyledTableCell>
                      ) : (<></>)}

                      <StyledTableCell style={{ width: "30px" }} align="right">
                        <IconButton
                          onClick={() => handleDeleteCurriculum(row)}
                          sx={{
                            height: "11px",
                            padding: "0px",
                            color: color,
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </StyledTableCell></>
                    ) : (
                      <div></div>
                    )}
                    <StyledTableCell style={{ width: "30px" }} align="right">
                      <IconButton
                        component={Link}
                        to={"/CurriculumDetail/" + row.id}
                        sx={{
                          height: "11px",
                          padding: "0px",
                          color: color,
                        }}
                      >
                        <EastIcon />
                      </IconButton>
                    </StyledTableCell>
                  </>
                )}

              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ApproveModal
        message={t("curriculum_really_want_delete")}
        open={approveModal}
        Approve={() => {
          deleteCurriculum();
          setApproveModal(false);
        }}
        Denied={() => setApproveModal(false)}
      />
      <ApproveModal
        message={t("curriculum_delete_assign")}
        open={approveUnassignModal}
        Approve={() => {
          UnassignCurriculum();
          setApproveUnassignModal(false);
        }}
        Denied={() => setApproveUnassignModal(false)}
      />

      <CreateCurriculumModal
        open={openCreateCurriculumModalModal}
        resetTable={reload}
        onClose={() => {
          setOpenCreateCurriculumModal(false);
        }} />
      {subjectId ? (
        <AssignCurriculumModal
          subjectID={parseInt(subjectId)}
          open={openAssignCurriculumModal}
          resetTable={reload}
          onClose={() => {
            setOpenAssignCurriculumModal(false);
          }} />
      ) : (<></>)}
    </Card>
  );
};

export default CurriculumTable;
