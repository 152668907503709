import React, { ReactElement, createContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { dispatchLogin, parseToken } from "./helpers/token.helper";
import { secondsToMinutesAndSeconds } from "../../helpers/timeFormat.helper";

const EXPIRATION_COUNTDOWN = 180; // countdown in seconds

export const SessionExpirationContext = createContext({
  isExpired: false,
  isActive: false,
  activateSessionExpiration: (active: boolean, duration: number = 60): void => {
    console.error("Not implemented");
    throw Error("Not implemented" + active);
  },
});

export const SessionExpirationProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [intervalRef, setIntervalRef] = useState<NodeJS.Timer | null>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const dispatch = useDispatch();

  const storedToken = useSelector((state: RootState) => state.user.token);

  /**
   * Activates or deactivates the session expiration timer.
   *
   * @param active - A boolean value indicating whether to activate or deactivate the timer.
   * @param duration - The duration in seconds after which the session will expire. Defaults to 60 seconds.
   */
  const activate = (active: boolean, duration: number = 60) => {
    console.debug("Session expiration activated", active);
    console.debug("Session expiration timer", intervalRef);
    setIsActive(active);
    if (intervalRef) {
      clearInterval(intervalRef);
    }
    if (active) {
      const interval = setInterval(() => {
        const currentToken = localStorage.getItem("token");

        const { isValid, tokenContent } = parseToken(currentToken);
        // Check if the token is valid and if it has an expiration date
        if (tokenContent && isValid && tokenContent.tokenExpiresAt) {
          const expirationThreshold = tokenContent.tokenExpiresAt - (EXPIRATION_COUNTDOWN + duration) * 1000;
          console.debug("Expiration threshold", new Date(expirationThreshold).toLocaleString(), secondsToMinutesAndSeconds((expirationThreshold - Date.now()) / 1000));
          setIsExpired(Boolean(Date.now() >= expirationThreshold));
        } else {
          setIsExpired(true);
        }

        if (currentToken !== storedToken && tokenContent && isValid) {
          dispatchLogin(dispatch, tokenContent);
        }
      }, duration * 1000);

      setIntervalRef(interval);
    }
  };

  return <SessionExpirationContext.Provider value={{ isExpired, isActive, activateSessionExpiration: activate }}>{children}</SessionExpirationContext.Provider>;
};
