import { gql } from "@apollo/client";
import api from "../api";
import { client } from "..";
import { queries } from "../features/graphql/queries";
import { t } from "../features/i18n";

export async function GetAssignmentForStudent(userCode: string, subjectID?: string): Promise<IAssignmentTableData[]> {
    try {
        const response = await api.get(`/student_assignment/list_by_student/?user_code=${userCode}${subjectID ? `&subject_id=${subjectID}` : ''}`);

        console.log("GetAssignmentForStudent", response.data);
        const data = response.data;
        const assignmentTableRowsData: IAssignmentTableData[] = [];

        if (data.length === 0) {
            assignmentTableRowsData.push({
                id: 0,
                assignment_type: "",
                status: "",
                name: t("curriculum_is_not_there"),
                description: "",
                end: "",
                subjectName: ""
            });
        } else {
            data.forEach((item: IAssignmentResponseData) => {
                assignmentTableRowsData.push({
                    id: item.id,
                    assignment_type: item.assignment.assignment_type,
                    status: item.assignment.status,
                    name: item.assignment.name,
                    description: item.assignment.description,
                    end: item.assignment.end,
                    subjectName: item.subject.name
                });
            });
        }
        return assignmentTableRowsData;
    } catch (error) {
        console.error("Error occurred while fetching assignments:", error);
        throw error;
    }
}

export async function GetAssignmentListForTeacher(userCode: string, subjectID?: string): Promise<IAssignmentTableData[]> {
    try {
        const response = await api.get('/assignment/list_by_teacher/?teacher_code=' + userCode + '&include_closed=false')
        console.log("GetAssignmentListForTeacher", response.data);
        const data = response.data;
        const assignmentTableRowsData: IAssignmentTableData[] = [];

        if (data.length === 0) {
            assignmentTableRowsData.push({
                id: 0,
                assignment_type: "",
                status: "",
                name: t("curriculum_is_not_there"),
                description: "",
                end: "",
                subjectName: ""
            });
        } else {
            data.forEach((item: IAssignmentForTeacher) => {
                assignmentTableRowsData.push({
                    id: item.id,
                    assignment_type: item.assignment_type,
                    status: item.status,
                    name: item.name,
                    description: item.description,
                    end: item.end,
                    subjectName: item.subject.name
                });
            });
        }
        return assignmentTableRowsData;
    } catch (error) {
        console.error("Error occurred while fetching assignments:", error);
        throw error;
    }
}

export async function GetAssignmentListBySubject(subject_id: number): Promise<IAssignmentTableData[]> {
    try {
        const response = await api.get('/assignment/list_by_subject/?subject_id=' + subject_id)
        console.log("GetAssignmentListBySubject", response.data);
        const data = response.data;
        const assignmentTableRowsData: IAssignmentTableData[] = [];

        if (data.length === 0) {
            assignmentTableRowsData.push({
                id: 0,
                assignment_type: "",
                status: "",
                name: t("task_is_not_there"),
                description: "",
                end: "",
                subjectName: ""
            });
        } else {
            data.forEach((item: IAssignmentForTeacher) => {
                assignmentTableRowsData.push({
                    id: item.id,
                    assignment_type: item.assignment_type,
                    status: item.status,
                    name: item.name,
                    description: item.description,
                    end: item.end,
                    subjectName: ""
                });
            });
        }
        return assignmentTableRowsData;
    } catch (error) {
        console.error("Error occurred while fetching assignments:", error);
        throw error;
    }
}

export async function GetStudentAssignment(studentTaskId: number): Promise<IAssignmentDetailResponseData> {
    try {
        const response = await api.get('/student_assignment/?id=' + studentTaskId)
        console.log("GetStudentAssignment", response.data);
        const data = response.data;
        let assignmentDetailData: IAssignmentDetailResponseData;
        if (data === null) {
            assignmentDetailData = ({
                id: 0,
                created_at: "-.-.----",
                updated_at: "-.-.----",
                created_by: "-.-.----",
                updated_by: "-.-.----",
                subject_id: 0,
                assignment_type: "",
                status: "",
                name: "",
                description: "",
                start: "-.-.----",
                end: "-.-.----",
            })
        } else {
            assignmentDetailData = ({
                id: data.assignment.id,
                created_at: data.assignment.created_at,
                updated_at: data.assignment.updated_at,
                created_by: data.assignment.created_by,
                updated_by: data.assignment.updated_by,
                subject_id: data.subject.id,
                assignment_type: data.assignment.assignment_type,
                status: data.assignment.status,
                name: data.assignment.name,
                description: data.assignment.description,
                start: data.assignment.start,
                end: data.assignment.end,
            })
        }
        return assignmentDetailData

    } catch (error) {
        console.error("Error occurred while fetching assignments:", error);
        throw error;
    }
}

export async function GetListAvailableAssignees(assignmentId: number): Promise<IAvailableAssigneesResponseData[]> {
    try {
        const response = await api.get('/student_assignment/list_available_assignees/?assignment_id=' + assignmentId)
        console.log("GetListAvailableAssignees", response.data);
        const data = response.data;
        const availableAssigneesData: IAvailableAssigneesResponseData[] = [];

        if (data.length === 0) {
            availableAssigneesData.push({
                student_assignment_id: 0,
                user_code: "",
                email: "",
                name: "",
                class_id: 0,
                class_name: ""
            });
        } else {
            data.forEach((item: IAvailableAssigneesResponseData) => {
                availableAssigneesData.push(item);
            });
        }
        return availableAssigneesData;
    } catch (error) {
        console.error("Error occurred while fetching assignments:", error);
        throw error;
    }
}

export async function GetListAvailableAssigneesTable(assignmentId: number): Promise<IStudentAssignmentTableData[]> {
    try {
        const response = await api.get('/student_assignment/list_available_assignees/?assignment_id=' + assignmentId)
        console.log("GetListAvailableAssignees", response.data);
        const data = response.data;
        const availableAssigneesData: IStudentAssignmentTableData[] = [];

        if (data.length === 0) {
            availableAssigneesData.push({
                user_code: "",
                email: "",
                name: t("student_is_not_there"),
                class_id: 0,
                class_name: "",
                student_assignment: {
                    id: 0,
                    created_at: "",
                    updated_at: "",
                    created_by: "",
                    updated_by: null,
                    student_id: 0,
                    assignment_id: 0,
                    grade: null,
                    comment: null,
                    content: null,
                    status: "",
                    submitted_at: null,
                    assessed_at: null,
                    assessed_by: null
                }
            });
        } else {
            data.forEach((item: IStudentAssignmentTableData) => {
                availableAssigneesData.push(item);
            });
        }
        return availableAssigneesData;
    } catch (error) {
        console.error("Error occurred while fetching assignments:", error);
        throw error;
    }
}

const GET_SUBJECT_BY_ID = gql`
query GetStudents($id: Int!) {
  classStudents(id: $id) {
    classId
    id
    user {
      code
      email
      name
      role
    }
  }
}
`;

export async function GetListOfStudents(classID: number): Promise<IStudentTableData[]> {
    try {
        const response = await client.query({
            query: GET_SUBJECT_BY_ID,
            variables: { id: classID },
        });
        console.log("GetListOfStudents", response);
        const classStudents = response.data?.classStudents || [];

        const dataResponse: IStudentTableData[] = classStudents.map((student: any) => {
            return {
                id: student.id,
                classId: student.classId,
                userCode: student.user.code,
                userEmail: student.user.email,
                userName: student.user.name,
                userRole: student.user.role,
            };
        });

        return dataResponse;
    } catch (error) {
        console.error('Chyba při načítání dat:', error);
        throw error;
    }
}

export async function GetClassForTeacher(classID: number): Promise<IClassPageData> {
    try {
        const response = await client.query({ query: queries.GET_CLASSES });
        console.log("GetClassForTeacher", response);
        const data = response.data?.classes || [];

        const selectedClass = data.find((classItem: IClass) => classItem.id === classID);

        if (!selectedClass) {
            throw new Error(`Class with ID ${classID} not found`);
        }

        const dataResponse: IClassPageData = {
            id: selectedClass.id,
            className: selectedClass.className,
            startYear: selectedClass.startYear,
            graduationYear: selectedClass.graduationYear,
            symbol: selectedClass.symbol,
            teacherName: selectedClass.teacher.name || ""
        };

        return dataResponse;
    } catch (error) {
        console.error('Chyba při načítání dat:', error);
        throw error;
    }
}

export async function GetClassForStudent(): Promise<IClassPageData> {
    try {
        const response = await client.query({ query: queries.GET_STUDENT_CLASS });
        console.log("GetClassForStudent", response);
        const data = response.data?.studentClass || {};

        return {
            id: data.id,
            className: data.className,
            startYear: data.startYear,
            graduationYear: data.graduationYear,
            symbol: data.symbol,
            teacherName: data.teacher.name || ""
        };

    } catch (error) {
        console.error('Chyba při načítání dat:', error);
        throw error;
    }
}

export async function CreateAssignment(assignmentData: IAssignmentCreateData): Promise<void> {
    try {
        await api.post('/assignment/create/', assignmentData);
        console.log("Assignment created successfully");
    } catch (error) {
        console.error("Error occurred while creating assignment:", error);
        throw error;
    }
}

export async function UpdateAssignment(assignmentData: IAssignmentUpdateData): Promise<void> {
    try {
        await api.put('/assignment/update/', assignmentData);
        console.log("Assignment created successfully");
    } catch (error) {
        console.error("Error occurred while creating assignment:", error);
        throw error;
    }
}


export async function DeleteAssignment(assignmentID: string): Promise<void> {
    try {
        await api.delete('/assignment/delete/?assignment_id=' + assignmentID);
        console.log("Assignment deleted successfully");
    } catch (error) {
        console.error("Error occurred while deleting assignment:", error);
        throw error;
    }
}

export async function assignStudentAssignment(assignData: AssignStudentAssignmentInput): Promise<void> {
    try {
        await api.post('/student_assignment/assign/', assignData);
        console.log("Student assignment assigned successfully");
    } catch (error) {
        console.error("Error occurred while assigning student assignment:", error);
        throw error;
    }
}

export async function unassignStudentAssignment(unassignData: UnassignStudentAssignmentInput): Promise<void> {
    try {
        await api.post('/student_assignment/unassign/', unassignData);
        console.log("Student assignment unassigned successfully");
    } catch (error) {
        console.error("Error occurred while unassigning student assignment:", error);
        throw error;
    }
}

export async function submitStudentAssignment(submitData: SubmitStudentAssignmentInput): Promise<void> {
    try {
        await api.post('/student_assignment/submit/', submitData);
        console.log("Student assignment submitted successfully");
    } catch (error) {
        console.error("Error occurred while submitting student assignment:", error);
        throw error;
    }
}

export async function assessStudentAssignment(assessData: AssessStudentAssignmentInput): Promise<void> {
    try {
        await api.post('/student_assignment/assess/', assessData);
        console.log("Student assignment assessed successfully");
    } catch (error) {
        console.error("Error occurred while assessing student assignment:", error);
        throw error;
    }
}

