import React from "react";
import { Card, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import useStyles from "../configs/styles";

const ClassItem: React.FC<IClassData> = ({ id, className, classID }) => {
  const classes = useStyles({});
  const location = useLocation();
  return (
    <Card
      className={classes.classCard}
      elevation={3}
      component={Link}
      to={"/myClass/" + classID}
    >
      <Typography
        sx={{
          fontSize: "50px",
          fontWeight: "bold",
        }}
      >
        {className}
      </Typography>
    </Card>
  );
};

export default ClassItem;
