import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  Alert,
  TextField,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { t } from "../../features/i18n";
import { z } from 'zod';
import { CreateAssignment, UpdateAssignment } from "../../apiRequest/task";
import MessageComponent from "../MessageComponent";
import { GetAllClasses, assignTeacherToClass } from "../../apiRequest/class";
import { CreateUser } from "../../apiRequest/user";

let color: string = "#f18733";

const schema = z.object({
  class: z.string().min(1),
});
type FormData = z.infer<typeof schema>;

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));

interface IAssignClassToTeacherModal {
  open: boolean;
  teacherCode: string;
  onClose: () => void;
  resetTable: () => void;
};

const AssignClassToTeacherModal: React.FC<IAssignClassToTeacherModal> = ({ open, onClose, resetTable, teacherCode }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [classesList, setClassesList] = useState<IUpdateClassResponse[]>([]);
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
      loadData();
    }
  }, [open]);
  async function loadData(): Promise<void> {
    const response = await GetAllClasses()
    const filteredClassesList = response.filter(item => item.teacher_code == null);
    setClassesList(filteredClassesList)
  }
  useEffect(() => {

    loadData();
  }, []);

  const handleCancel = () => {
    onClose();
  };

  const onSubmit = async (data: FormData) => {
    console.log(data);
    try {
      if (data) {
        setLoading(true)
        assignTeacherToClass({ class_id: parseInt(data.class), teacher_code: teacherCode })
        setTimeout(() => {
          setSuccessAlertOpen(true);
          setTimeout(() => {
            resetTable();
            onClose();
          }, 2000);
        }, 2000);
      } else {
        throw "chyba pri updatovani";
      }
    } catch (error) {
      console.error('Chyba při odesílání:', error);
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {successAlertOpen ? (
          <MessageComponent
            errorChange={errorAlertOpen}
            errorMessage={t("assign_class_error")}
            successMessage={t("assign_class_success")}
          />
        ) : (
          <Box>
            <Box
              sx={{
                maxHeight: "50px",
                alignText: "left",
                color: color,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "24px",
                    }}
                  >
                    {t("add_class_to_teacher")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {classesList.length !== 0 ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  sx={{ mb: 2 }}
                  {...register('class')}
                  select
                  label={t("class")}
                  error={!!errors.class}
                  helperText={errors.class?.message}
                  fullWidth
                >

                  {classesList.map((item =>
                    <MenuItem value={item.id}>{item.class_name}</MenuItem>
                  ))}

                </TextField>

                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  sx={{
                    width: 140,
                    height: 40,
                    color: color,
                    textTransform: "none",
                    fontWeight: "bold",
                    borderColor: color,
                  }}
                >
                  {t("cancel")}
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  sx={{
                    width: 140,
                    height: 40,
                    bgcolor: color,
                    "&:hover": {
                      backgroundColor: color,
                    },
                    fontWeight: "bold",
                    position: "absolute",
                    right: "40px",
                  }}
                >
                  {loading ? t("processing") : t("confirm")}

                </Button>
              </form>
            ) : (<>
              <Typography
                sx={{
                  fontSize: "24px",
                }}
              >
                {t("no_assign_class")}
              </Typography>
              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{
                  width: 140,
                  height: 40,
                  color: color,
                  textTransform: "none",
                  fontWeight: "bold",
                  borderColor: color,
                }}
              >
                {t("cancel")}
              </Button>
            </>
            )}
          </Box>
        )}
      </Paper>
    </Modal>
  );
};

export default AssignClassToTeacherModal;
