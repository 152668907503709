import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import colorConfigs from "../configs/colorConfigs";
import Footer from "../components/Footer";
import { AppBar, Box, Toolbar } from "@mui/material";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import { LogoIcon } from "../components/icons/LogoIcon";
import { useSessionExpiration } from "../features/authentication/SessionExpiration.hook";

export interface ILoginProps {}

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const LoginLayout: React.FC<ILoginProps> = (props) => {
  const { activateSessionExpiration } = useSessionExpiration();

  // FIXME this effect is rerendering childs and not turning off the session expiration
  useEffect(() => {
    activateSessionExpiration(false);
  }, []);

  const Main = styled("div")(({ theme }) => ({
    flexGrow: 1,
    overflow: "auto",
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: `calc(100%)`,

    [theme.breakpoints.up("lg")]: {
      paddingTop: APP_BAR_DESKTOP + 24,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: colorConfigs.mainBg,
          whiteSpace: "nowrap",
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          top: "45%",
          left: "28%",
          right: 0,
          bottom: 0,
          color: "#FEE9D7",
          whiteSpace: "nowrap",
          // overflow: "hidden",
        }}
      >
        <Person2OutlinedIcon
          sx={{
            fontSize: "calc(15vw + 15vh)",
            transform: "rotate(-30deg) translate(-50%, -50%)",
            overflow: "hidden",
          }}
        />
        <Person2OutlinedIcon
          sx={{
            ml: "300px",
            fontSize: "calc(15vw + 15vh)",
            transform: "rotate(30deg) translate(-50%, -50%)",
            overflow: "hidden",
          }}
        />
      </div>

      <div style={{ position: "relative", zIndex: 1 }}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              bgcolor: "#302c2c",
              padding: "20px",
            }}
          >
            <Toolbar>
              <Box
                sx={{
                  ml: "calc(100vw/2 - 100px)",
                  left: "50%",
                }}
              >
                <LogoIcon />
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Main>
            <Outlet />
          </Main>
        </Box>
        <Footer />
      </div>
    </div>
  );
};

export default LoginLayout;
