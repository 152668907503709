import { Typography } from "@mui/material";
import React from "react";
import { t } from "../features/i18n";

type Props = {};

const Hint = (props: Props) => {
  return (
    <>
      <Typography sx={{ 
        mb: 5,
        fontSize: "2rem",
       }}>
        {t("name_hint")}
      </Typography>
    </>
  );
};

export default Hint;
