import React from "react";
import { SvgIcon } from '@mui/material';
interface IconAttributes {
    className?: string,
}

export const DownloadIcon: React.FC<IconAttributes> = ({ className = '' }: IconAttributes) => {
    return (
        <SvgIcon width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.9202 15.4869C10.2172 15.7219 10.6372 15.7219 10.9352 15.4869L15.0252 12.2139C15.0642 12.1889 15.1002 12.1599 15.1342 12.1279C15.4632 11.8179 15.4782 11.2999 15.1682 10.9709C14.8572 10.6429 14.3392 10.6279 14.0112 10.9379L11.2452 13.1469V1.75794C11.2452 1.30594 10.8792 0.939941 10.4272 0.939941C9.9752 0.939941 9.6092 1.30594 9.6092 1.75794V13.1469L6.8442 10.9379C6.5122 10.7169 6.0692 10.7679 5.7962 11.0579C5.4862 11.3859 5.5002 11.9039 5.8292 12.2139L9.9202 15.4869Z"/>
            <path d="M19.4303 12.395C18.9783 12.395 18.6123 12.761 18.6123 13.213V16.486C18.6123 16.938 18.2463 17.304 17.7943 17.304H3.06631C2.61531 17.304 2.24831 16.938 2.24831 16.486V13.213C2.24831 12.761 1.88231 12.395 1.43031 12.395C0.978305 12.395 0.612305 12.761 0.612305 13.213V16.486C0.612305 17.842 1.71131 18.94 3.06631 18.94H17.7943C19.1493 18.94 20.2483 17.842 20.2483 16.486V13.213C20.2483 12.761 19.8823 12.395 19.4303 12.395Z"/>
        </SvgIcon>
    );
}