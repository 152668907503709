import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import { CalendarIcon } from "../icons/menu/CalendarIcon";
import { useSelector } from "react-redux";
import { RootState } from "../../features/redux/store";
import { t } from "../../features/i18n";
import useStyles from "../../configs/styles";
import EastIcon from "@mui/icons-material/East";
import { Link } from "react-router-dom";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ApproveModal from "../modals/ApproveModal";
import api from "../../api";
import GetformattedDate from "../../features/GlobalFunction/globalFunction"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import TableLegend from "./TableLegend";
import CreateEventModal from "../modals/CreateEventModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const EventsTableDetail: React.FC<IEventTable> = ({
  resource_id,
  resource_type,
  limit,
  color,
  title,
  buttonName,
  navigationLink,
  select,
  addButton,
}) => {
  const classes = useStyles();
  const role = useSelector((state: RootState) => state.user.role);
  const [targetRow, setTargetRow] = useState<IEventResponseData>();
  const [approveModal, setApproveModal] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [responseData, setResponseData] = useState<IEventResponseData[]>([]);
  const [openCreateEventModal, setOpenCreateEventModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const apiCalled = useRef(false);

  const handleDeleteEvent = (doc: IEventResponseData) => {
    if (doc) {
      setTargetRow(doc);
      setApproveModal(true);
    }
  };

  async function deleteEvent() {
    try {
      api.delete('/events/?event_id=' + targetRow?.id)
        .then((response) => {
          console.log("deleteEvent succesful");
          console.log(response.data);
          GetAllEvents(resource_type ? [resource_type] : ["class", "subject"]);
        })
        .catch((error) => {
          console.error(error);
          setResponseError(true)
        });
    } catch (error) {
      console.error(error);
      setResponseError(true)
    }
  }

  async function reload() {
    await GetAllEvents(resource_type ? [resource_type] : ["class", "subject"])
  }

  async function GetAllEvents(resourceTypes: string[]) {
    try {
      const resourceTypesString = resourceTypes.join('&resource_types=');
      api.get(`/events/list/?resource_types=${resourceTypesString}`)
      // api.get('/events/list/?resource_types=class&resource_types=subject')
        .then((response) => {
          const data = response.data;
          const eventsTableRowsData: IEventResponseData[] = []
          if (data.length === 0) {
            console.log("response.data are empty");
            eventsTableRowsData.push({
              id: 3,
              created_at: "-.-.----",
              updated_at: "-.-.----",
              created_by: "",
              updated_by: null,
              resource_id: 0,
              resource_type: "",
              title: t("event_is_not_there"),
              content: "----",
              icon: "",
              start: "-.-.----",
              end: "-.-.----"
            });
            console.log("data.id:", data.id);
            setResponseData(eventsTableRowsData);

          } else {
            response.data.map((data: IEventResponseData) => {
              eventsTableRowsData.push(data);
            });
            console.log("eventTableRowsData:", eventsTableRowsData);
            setResponseData(eventsTableRowsData);
            setIsLoaded(true)
          }
        })
        .catch((error) => {
          console.error(error);
          setResponseError(true)
        });
    } catch (error) {
      console.error(error);
      setResponseError(true)
    }
  }

  useEffect(() => {
    async function loadData(): Promise<void> {
      await GetAllEvents(resource_type ? [resource_type] : ["class", "subject"]);
    }

    if (!apiCalled.current) {
      loadData();
      apiCalled.current = true;
    }

  }, []);

  function getCircleColor(type: string | undefined): string {
    switch (type) {
      case "class": {
        return "#FCBB41";
      }
      case "global": {
        return "#008CFB";
      }
      case "subject": {
        return "#71BD55";
      }
      default: {
        return "gray";
      }
    }
  }


  return (
    <Card
      className={classes.tableCard}
      elevation={3}
      style={{ borderTop: "8px solid" + color }}
    >
      <Box className={classes.tableCardContent}>
        <CardContent
          sx={{
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "0px",
          }}
        >
          <Typography
            className={classes.tableTitle}
            sx={{
              color: color,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item sx={{ mr: "20px" }}>
                <CalendarIcon />
              </Grid>
              <Grid item>{title}</Grid>
            </Grid>
          </Typography>
        </CardContent>
        {role === "TEACHER" &&
          buttonName !== null &&
          buttonName !== undefined &&
          buttonName !== "" && resource_id ? (
          <Button
            variant="outlined"
            className={classes.tableButton}
            sx={{
              color: color,
              borderColor: color,
              "&:hover": {
                color: "white",
                backgroundColor: color,
              },
            }}
            onClick={() => setOpenCreateEventModal(true)}
          >
            {buttonName}
          </Button>
        ) : (
          <div></div>
        )}
      </Box>
      <Box>
        <TableContainer
          sx={{
            paddingBottom: "20px",
          }}
        >
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ width: "250px" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#808080",
                    }}
                  >
                    {t("start")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell style={{ width: "250px" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#808080",
                    }}
                  >
                    {t("end")}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell style={{ width: "10%" }} align="center">
                  <Typography
                    sx={{
                      fontSize: "13",
                      color: "#808080",
                    }}
                  >
                    {t("type")}
                  </Typography>
                </StyledTableCell>

                <StyledTableCell style={{ width: "70%" }} align="left">
                  <Typography
                    sx={{
                      fontSize: "13",
                      color: "#808080",
                    }}
                  >
                    {t("name")}
                  </Typography>
                </StyledTableCell>
                {role === "TEACHER" &&
                  buttonName !== null &&
                  buttonName !== undefined &&
                  buttonName !== "" ? (
                  <StyledTableCell
                    style={{ width: "30px" }}
                    align="right"
                  ></StyledTableCell>
                ) : (
                  <div></div>
                )}
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(limit ? responseData.slice(0, Math.min(limit, responseData.length)) : responseData).map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ width: "350px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {GetformattedDate(row.start)}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ width: "350px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {GetformattedDate(row.end)}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "10%" }} align="center">
                    <CircleOutlinedIcon
                      sx={{
                        fontSize: "16px",
                        color: getCircleColor(row.resource_type),
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "70%" }} align="left">
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "#4D4D4D",
                        // fontWeight: "bold",
                      }}
                    >
                      {row.title}
                    </Typography>
                  </StyledTableCell>
                  {row.content === "----" ? (
                    <>
                      <StyledTableCell style={{ width: "30px" }} align="right" />
                      <StyledTableCell style={{ width: "30px" }} align="right" />

                    </>
                  ) : (
                    <>
                      {role === "TEACHER" ? (
                        <StyledTableCell style={{ width: "30px" }} align="right">
                          <IconButton
                            onClick={() => handleDeleteEvent(row)}
                            sx={{
                              height: "11px",
                              padding: "0px",
                              color: color,
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </StyledTableCell>
                      ) : (
                        <div></div>
                      )}
                      <StyledTableCell style={{ width: "30px" }} align="right">
                        <IconButton
                          component={Link}
                          to={"/calendar/" + row.id}
                          sx={{
                            height: "11px",
                            padding: "0px",
                            color: color,
                          }}
                        >
                          <EastIcon />
                        </IconButton>
                      </StyledTableCell>
                    </>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ mt: "30px ", mb: "20px" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {navigationLink && !select ? (
              <Button
                component={Link}
                to={"/" + navigationLink}
                variant="outlined"
                sx={{
                  width: 216,
                  height: 40,
                  color: color,
                  textTransform: "none",
                  fontWeight: "bold",
                  borderColor: color,
                  "&:hover": {
                    color: "white",
                    backgroundColor: color,
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {t("all_calendar")}
                </Typography>
              </Button>
            ) : null}
            {!navigationLink && select ? (
              <Button
                onClick={select}
                variant="outlined"
                sx={{
                  width: 216,
                  height: 40,
                  color: color,
                  textTransform: "none",
                  fontWeight: "bold",
                  borderColor: color,
                  "&:hover": {
                    color: "white",
                    backgroundColor: color,
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {t("all_calendar")}
                </Typography>
              </Button>
            ) : null}
            <Box
              sx={{
                width: "60%",
              }}
            >
              <TableLegend />
            </Box>
          </Box>
        </Box>
      </Box>
      <ApproveModal
        message={t("event_really_want_delete")}
        open={approveModal}
        Approve={() => {
          deleteEvent();
          setApproveModal(false);
        }}
        Denied={() => setApproveModal(false)}
      />
      {resource_id && resource_type ? (
        <CreateEventModal
          open={openCreateEventModal}
          resetTable={reload}

          onClose={() => {
            setOpenCreateEventModal(false);
          }} resource_id={resource_id} resource_type={resource_type} />
      ) : (<></>)}
    </Card>

  );
}

export default EventsTableDetail;
