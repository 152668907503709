import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  Alert,
  AlertTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "../../features/i18n";
import { z, ZodError } from 'zod';
import MyTextField from "./MyTextField";
import { RootState } from "../../features/redux/store";
import { useSelector } from "react-redux";
import { CreateCurriculum } from "../../apiRequest/curruculum";
import MessageComponent from "../MessageComponent";
let color: string = "#f55ea4";

const schema = z.object({
  name: z
    .string()
    .min(1, { message: t("name_subject_min_one_letter") })
    .max(20, { message: t("name_subject_max_letters") }),
  content: z
    .string()
    .min(1, { message: t("curriculum_desc_min_one_symbol") })
    .max(100, { message: t("curriculum_desc_max_100_symbols") }),
});

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));

interface ICreateCurriculumModal {
  open: boolean;
  onClose: () => void;
  resetTable: () => void;
};
const CreateCurriculumModal: React.FC<ICreateCurriculumModal> = ({ open, onClose, resetTable }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const classes = useStyles();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const {
    reset,
    setValue,
    handleSubmit,
  } = methods;

  const onSubmit: SubmitHandler<FormData> = async (data) => {

    try {
      const validatedData = schema.parse(data);
      console.log(`validation done ${data}`)
      setLoading(true)
      await CreateCurriculum({
        name: validatedData.name,
        content: validatedData.content,
        icon: ""
      })
      setTimeout(() => {

        setSuccessAlertOpen(true);
        setTimeout(() => {
          resetTable();
          onClose();
        }, 2000);
      }, 2000);

    } catch (error) {
      console.error('Chyba při odesílání:', error);
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }
  };

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);

    }
  }, [open]);

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {
          successAlertOpen ? (
            <MessageComponent
              errorChange={errorAlertOpen}
              errorMessage={t("curriculum_create_error")}
              successMessage={t("curriculum_was_created")}
            />
          ) : (
            <Box>
              <Box
                sx={{
                  maxHeight: "50px",
                  alignText: "left",
                  color: color,
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: "24px",
                      }}
                    >
                      {t("enter_paramaters_for_create_new_curriculum")}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <FormProvider {...methods}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ mt: "30px " }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <MyTextField
                    name="name"
                    schema={schema}
                    setValue={setValue}
                    type="text"
                    required
                    fullWidth
                    label={t("subject")}
                    sx={{ mb: 2 }}
                  />
                  <MyTextField
                    name="content"
                    schema={schema}
                    setValue={setValue}
                    type="text"
                    required
                    fullWidth
                    label={t("name")}
                    sx={{ mb: 2 }}
                  />
                  <Button
                    variant="outlined"
                    onClick={handleCancel}
                    sx={{
                      width: 140,
                      height: 40,
                      color: color,
                      textTransform: "none",
                      fontWeight: "bold",
                      borderColor: color,
                    }}
                  >
                    {t("cancel")}
                  </Button>

                  <Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    sx={{
                      width: 140,
                      height: 40,
                      bgcolor: color,
                      "&:hover": {
                        backgroundColor: color,
                      },
                      fontWeight: "bold",
                      position: "absolute",
                      right: "40px",
                    }}
                  >
                    {loading ? t("assigning") : t("confirm")}

                  </Button >
                </Box >
              </FormProvider >
            </Box >
          )}
      </Paper >
    </Modal >
  );
};

export default CreateCurriculumModal;
