import React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import { t } from "../features/i18n";

export interface IRecoveryProps {}

const Recovery: React.FC<IRecoveryProps> = (props) => {
  const paperStyle = {
    paddingTop: 45,
    paddingBottom: 75,
    paddingLeft: 100,
    paddingRight: 100,
    width: 644,
    borderTop: "8px solid #f18733",
  };
  const btnstyle = { margin: "8px 0" };
  return (
    <Paper elevation={10} style={paperStyle}>
      <Box
        sx={{
          zIndex: 1,
          position: "absolute",
          left: "50%",
          top: "23%",
          transform: " translateX(-50%)",
          color: "#FEE9D7",
        }}
      >
        <Person2OutlinedIcon
          sx={{
            fontSize: 100,
          }}
        />
      </Box>
      <Box
        sx={{
          zIndex: 2,
          position: "absolute",
          left: "50%",
          transform: " translateX(-50%)",
        }}
      >
        <Typography variant="h5" color="#f18733">
          {t("message_send_new_password")}
        </Typography>
      </Box>

      <TextField
        label={t("email")}
        placeholder={t("enter_email")}
        variant="filled"
        fullWidth
        required
        sx={{
          mt: "80px",
          mb: "30px",
        }}
      />
      <Button
        // component={Link}
        // to={"/recovery"}
        type="submit"
        color="primary"
        variant="contained"
        style={btnstyle}
        fullWidth
        sx={{
          bgcolor: "#f18733",
          fontWeight: "bold",
          mt: "30px",
          "&:hover": {
            backgroundColor: "#f18733",
          },
        }}
      >
        {t("confirm")}
      </Button>
      <Button
        component={Link}
        to={"/login"}
        type="submit"
        color="primary"
        variant="contained"
        style={btnstyle}
        fullWidth
        sx={{
          bgcolor: "#f18733",
          fontWeight: "bold",
          mt: "30px",
          "&:hover": {
            backgroundColor: "#f18733",
          },
        }}
      >
        {t("back")}
      </Button>
    </Paper>
  );
};

export default Recovery;
