import cs from './cs.json';


// export const translate = (key: string, language: string): string => {
export const t = (key: string): string => {
  let langData: { [key: string]: string } = {};
  langData = cs;
//   if(language === 'EN') {
//     langData = en;
//   }else if(language === 'DE') {
//     langData = de;
//   }else if(language === 'FR') {
//     langData = fr;
//   }

  return langData[key];
}