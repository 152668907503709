import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Paper,
  Typography,
  Theme,
  Box,
  Grid,
  Alert,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { t } from "../../features/i18n";
import { z } from 'zod';
import api from "../../api";
import { CreateExpectedDocument } from "../../apiRequest/documents";
import { submitStudentAssignment } from "../../apiRequest/task";
import MessageComponent from "../MessageComponent";
let color: string = "#999999";

const schema = z.object({
  content: z.string().min(1, t("name_min_one_symbol"))
});

type FormData = z.infer<typeof schema>;

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    borderTop: `8px solid ${color}`,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "40px",
  },
  tableRow: {
    "&:not(:last-child)": {
      borderBottom: "none",
    },
  },
}));

interface ISubmitSolutionModalModal {
  open: boolean;
  onClose: () => void;
  resetTable: () => void;
  assignment_id: number;
};

const SubmitSolutionModal: React.FC<ISubmitSolutionModalModal> = ({ open, onClose, resetTable, assignment_id }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);

    }
  }, [open]);

  const handleCancel = () => {
    onClose();
  };

  const onSubmit = async (data: FormData) => {
    console.log(data);
    try {
      if (assignment_id) {
        setLoading(true)
        await submitStudentAssignment({
          student_assignment_id: assignment_id,
          content: data.content
        })
        setTimeout(() => {
          setSuccessAlertOpen(true);
          setTimeout(() => {
            resetTable();
            onClose();
          }, 2000);
        }, 2000);
      } else {
        throw "chyba pri vytvareni";
      }
    } catch (error) {
      console.error('Chyba při odesílání:', error);
      setErrorAlertOpen(true);
      setLoading(false);
      setTimeout(() => {
        setErrorAlertOpen(false);
        onClose();
      }, 2000);
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        {successAlertOpen ? (
          <MessageComponent
            errorChange={errorAlertOpen}
            errorMessage={t("submit_solution_error")}
            successMessage={t("submit_solution_success")}
          />
        ) : (

        <Box>
          <Box
            sx={{
              maxHeight: "50px",
              alignText: "left",
              color: color,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  sx={{
                    fontSize: "24px",
                  }}
                >
                  {t("really_want_submit")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField sx={{ mb: 2, mt: 2 }}
              {...register('content')}
              required
              label={t("desc_send_task")}
              multiline
              rows={3}
              error={!!errors.content}
              helperText={errors.content?.message}
              fullWidth
            />
            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{
                width: 140,
                height: 40,
                color: color,
                textTransform: "none",
                fontWeight: "bold",
                borderColor: color,
              }}
            >
              {t("cancel")}
            </Button>

            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{
                width: 140,
                height: 40,
                bgcolor: color,
                "&:hover": {
                  backgroundColor: color,
                },
                fontWeight: "bold",
                position: "absolute",
                right: "40px",
              }}
            >
              {loading ? t("processing") : t("confirm")}

            </Button>
          </form>
        </Box>
)}
      </Paper>
    </Modal>
  );
};

export default SubmitSolutionModal;
